import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import allActions from "actions";
import NavBar from "components/NavBar/NavBar";
import Search from "components/Search/Search";
import DarkModeToggle from "components/DarkModeToggle/DarkModeToggle";
import RoomsAtSideBar from "./components/RoomsAtSideBar";
import RoomsAtCollapsedSideBar from "./components/RoomsAtCollapsedSideBar";
import SharedRoomsAtSideBar from "./components/SharedRoomsAtSideBar";
import UserMenuAtSideBar from "./components/UserMenuAtSideBar";
import LabelsAtSideBar from "./components/LabelsAtSideBar";
import { AllPostsIcon } from "assets/roomAssets";
import ReactTooltip from "react-tooltip";

import FixedNavbarWithSelectedPosts from "components/NavBar/components/FixedNavbarWithSelectedPosts";

import { useDropForDnD } from "hooks/useDropForDnD";
import { useMediaQuery } from 'react-responsive';
import './sidebar.css';

/*
Sidebar affects styling for
-- masonrylisting, warroomsectionnew

To accommodate the change, when sidebar opens, we make posts smaller
This introduces new custom style for usePostLogic, AddPostCard, and PostBottom
*/


/*
DONE:
updated warroomSection grid media query
handled selected mode
Whats New / Pinned
masonry styling DONE - posts become smaller if sidebar open
shared with me show icon and who shares
icons
font color
split menu into different small partition / sections>
COLLAPSED ICONS AND POPUP
  // public route has NavBar and FooterLanding
  // private route has SideBar
solve public room issue (posts are small since sideBarCollapse is false by default). SO we make the state true, then use useEffect to make it false SideBar component
finally fixed MASONRY
custom UI for twitter post
fix Navbar mobile UI minor issue eg shared with me
!! why first time we click soemthing whole page refresh...[NOTE: if we put SideBar outside of PrivateRoute, it solves the issue...]
handle hoverUI on menu items with dropdown

LATER:
archive SharedRoomsSection ??
twitterpost open and close again issue...
super minor:darkmode thing why keep triggering
*/

const SideBar = () => {
	const dispatch = useDispatch();

	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null

	const isSignupLoading = useSelector(state => state.userReducer.isSignupLoading);	

	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const userWantsArchive = useSelector(state => state.userReducer.userWantsArchive);
	const labels = useSelector(state => state.contentReducer.labels);
	const posts = useSelector(state => state.contentReducer.posts);	
	const rooms = useSelector(state => state.contentReducer.rooms);
	const searchTerm = useSelector(state => state.contentReducer.searchTerm);	
	const sharedRooms = useSelector(state => state.contentReducer.sharedRooms);
	const selectedPosts = useSelector(state => state.contentReducer.selectedPosts);
	// this is for displaying the red-dot
	const localNewsVersionfromState = useSelector(state => state.userReducer.localNewsVersion);
	const globalNewsVersion = useSelector(state => state.userReducer.globalNewsVersion);
	const localNewsVersion = JSON.parse(localStorage.getItem('localNewsVersion'))?.version  || localNewsVersionfromState;

	// DND
	const dropForDnD = useDropForDnD(); 
	const {
		draggedPost, isRoomReceiving, 
		onDragOver, onDragLeave, onDrop
	} = dropForDnD;


	const sideBarCollapse = useSelector(state => state.userReducer.sideBarCollapse); // set localStorage?
	const menuIconClick = () => dispatch(allActions.userActions.setSideBarCollapse(!sideBarCollapse))

	const handleHomeClick = () => {
		if (searchTerm.length > 0) dispatch(allActions.contentActions.searchPosts(""))
		if (user && posts.length === 0) dispatch(allActions.contentActions.getAllPosts())
		if (user && rooms.length === 0) dispatch(allActions.roomActions.getRoomsMaster())  
	}

	const SidebarToggleIcon = () => {
		return (
			<>
				<button className="sidebar-toggle-icon-button" data-tip 
					data-for={sideBarCollapse ? "sidebar-toggle-expand-icon": "sidebar-toggle-icon"}
				>
					<svg 
						onClick={menuIconClick} 
						width="20px" height="20px" 
						style={{ cursor:"pointer" }}
						fill={colorTheme === "light" ? "black" : "white"}
						version="1.1" viewBox="0 0 20 20" x="0px" y="0px"
					><g>
						<path d={sideBarCollapse ? "M4 16V4H2v12h2zM13 15l-1.5-1.5L14 11H6V9h8l-2.5-2.5L13 5l5 5-5 5z" : "M16 16V4h2v12h-2zM6 9l2.501-2.5-1.5-1.5-5 5 5 5 1.5-1.5-2.5-2.5h8V9H6z"} >
						</path></g>
					</svg>
				</button>

				<ReactTooltip id="sidebar-toggle-icon" place="bottom" effect="float">
	          		Collapse
	        	</ReactTooltip>  	
				<ReactTooltip id="sidebar-toggle-expand-icon" place="right" effect="float"
					offset={{top: 0, right: 20}}		
				>
	          		Expand
	        	</ReactTooltip>  		        	

        	</>

		) 
	} 

	// const hamburgerMenuIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/hamburger-menu.svg` : `${process.env.PUBLIC_URL}/images/icons/hamburger-menu-dark.svg`;
	const pinIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/pin-black-outline.svg` : `${process.env.PUBLIC_URL}/images/icons/pin-white-outline.svg`;
	const deskIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/desk.svg` : `${process.env.PUBLIC_URL}/images/icons/desk-dark.svg`;
	const archiveIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/archive.svg` : `${process.env.PUBLIC_URL}/images/icons/archive-dark.svg`;

	useEffect(() => {
		// below is because the state is false by default: so that posts in PublicRoom is not small
		dispatch(allActions.userActions.setSideBarCollapse(false))
		// eslint-disable-next-line
	}, [])

	const isMobile = useMediaQuery({ query: '(max-width: 767px)' })	

	if (isSignupLoading) return <nav></nav> // TEST:  removed isOnboarding || 
	if (isMobile) return (<NavBar />) // mobile 

	if (sideBarCollapse) return (
		<nav className="sidebar-container sidebar-collapsed" >
			<SidebarToggleIcon />
			{/*<img onClick={menuIconClick} src={hamburgerMenuIcon} alt='sidebar-menu-icon' style={{cursor:"pointer"}} />	*/}

			<Link 
				className="dont-link-me" 
				to={user ? "/home" : "/"} 
				data-tip data-for="sidebar-collapsed-desk-icon"

			>
				<img 
					style={{ height: "25px", width: "25px", marginTop: "25px", verticalAlign: "middle"}} 
					src={`${process.env.PUBLIC_URL}/images/logo/osoji-logo-3.png`} 
					alt='osoji-logo' 
				/>
			</Link>	 	
			
			<RoomsAtCollapsedSideBar />

			<DarkModeToggle atCollapsedSideBar={true} />	

			<ReactTooltip id="sidebar-collapsed-desk-icon" place="right" effect="float"
				 offset={{top: 0, right: 20}}			
			>
          		Desk
        	</ReactTooltip>  	
		</nav>
	)

	return (
		<nav className="sidebar-container">
		 	{(selectedPosts.length > 0) && // show FixedNavbar when in Select Mode
		 		<FixedNavbarWithSelectedPosts 
		 			selectedPosts={selectedPosts} 
		 			colorTheme={colorTheme}
		 		/>	
		 	}

			<div className="sidebar-head" >
				<Link 
					className="dont-link-me" 
					to={user ? "/home" : "/"} 
					onClick={handleHomeClick}
				>
					<div style={{ height: "30px",width: "85px", marginBottom: "0px", marginLeft: "10px" }} >
						<img 
							style={{ height: "30px", width: "30px", marginRight: "8px", verticalAlign: "middle"}} 
							src={`${process.env.PUBLIC_URL}/images/logo/osoji-logo-3.png`} 
							alt='osoji-logo' 
						/> 
						<span style={{ 
							fontWeight: "600", fontSize: "18px", color: "var(--header)",
							verticalAlign: "middle",
						}}>Osoji</span> 					
					</div>
				</Link>  	

				<SidebarToggleIcon />

				{/* <img onClick={menuIconClick} src={hamburgerMenuIcon} alt='sidebar-menu' style={{cursor:"pointer"}} /> */}	
			</div>


		  	<div style={{ paddingLeft: "16px" , marginBottom: "20px", paddingRight: "14px"}}>
			  	<Search where="sidebar" /> 
			</div>


			<Link 
				className="dont-link-me sidebar-menu-item sidebar-desk-group" 
				to="/home" 
				onClick={handleHomeClick}				

				onDragOver={(e) => onDragOver(e)} 
			    onDragLeave={(e) => onDragLeave(e)}						
				onDrop={(e) => onDrop({
					e: e,
					roomDestination: "braindump", 
				})} 
				{...draggedPost &&  !draggedPost.owner && {style: { 
					backgroundColor: "rgba(250,128,114,0.3)",
					fontWeight: "bold"
				}}}				
				{...isRoomReceiving && { style: { backgroundColor: "var(--main)", opacity: "0.7" }}}	// this will overwrite above			
			>
				<span><img src={deskIcon} alt="desk" className="sidebar-desk-icon" /></span>
				Desk
			</Link> 				  	

		  	<RoomsAtSideBar />

		  	{sharedRooms.length > 0 && <SharedRoomsAtSideBar />}

			{labels && labels.size > 0 && <LabelsAtSideBar />}		  	

			<Link 
				className="dont-link-me sidebar-menu-item"  
				to="/home" 
				onClick={() => dispatch(allActions.contentActions.searchPosts(`pinned:`))} 
				style={{ paddingLeft: "16px", color: "var(--header)", marginBottom: "10px" }}
			>
				<span><img src={pinIcon} alt="pinned" style={{ width: "20px", marginRight: "10px", marginTop: "-3px" }} /></span>
				Pinned
			</Link>  		

			{userWantsArchive &&
				<Link 
					className="dont-link-me sidebar-menu-item"  
					to="/archive" 
					style={{ paddingLeft: "16px", color: "var(--header)", marginBottom: "10px" }}
				>
					<span><img src={archiveIcon} alt="archive" style={{ width: "20px", marginRight: "10px", marginTop: "-4px" }} /></span>
					Archive
				</Link>  	
			}				

			<Link 
				className="dont-link-me sidebar-menu-item"  
				to="/allposts" 
			>
				<span style={{ width: "20px", marginRight: "10px", marginTop: "-4px" }} >
					<AllPostsIcon 
						color={(colorTheme === "light") ? "black" : "white"} 
						height="18px"
						width="18px"
					/>
				</span>
				All Posts
			</Link>

			<hr style={{ borderTop: "1px dotted var(--search-border)", width: "90%"}} />

		  	<UserMenuAtSideBar />

			<Link className="dont-link-me sidebar-menu-item" style={{ paddingLeft: "16px", marginTop: "10px", marginBottom: "10px", color: "var(--header)" }} to="/news" >
				<span style={{ marginRight: "10px"}}>✨</span>What's New 
				<span>
					{(globalNewsVersion !== localNewsVersion) &&
						<img className="news-red-dot-sidebar"src={`${process.env.PUBLIC_URL}/images/navbar/red-dot.png`} alt='news' />
					}
				</span>
			</Link>  

		  	<div style={{ paddingLeft: "16px", marginTop: "10px", marginBottom: "10px"}}>
			  	<DarkModeToggle />
		  	</div>

		</nav>

	);
};


// export default React.memo(SideBar);
export default SideBar;