 import { useState } from 'react';
import { useDispatch } from 'react-redux'
import allActions from "actions";
import { SEARCH_MINIMUM } from "constants/constants";
import SearchIcon from "components/ButtonsAndIcons/SearchIcon/SearchIcon";
import { useMediaQuery } from 'react-responsive';
import "./search.css"


// used in NavBar, SideBar, and searchLocal
const Search = ({ where }) => { // where is "mobileNavBar" or "navBar" or "sidebar"

    const dispatch = useDispatch()

    const [searchTerm, setSearchTerm] = useState("");

    const handleSubmitSearch = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (searchTerm.length < SEARCH_MINIMUM) return dispatch(allActions.contentActions.searchError());

        dispatch(allActions.contentActions.searchPosts(searchTerm));
    } 
    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value)
        // so that if empty, it will update global state
        if (e.target.value === "") dispatch(allActions.contentActions.searchPosts(e.target.value));
    }

    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })    

     const searchStyle = 
         where === "navBar" && !isMobile 
             ? {display: "block"}
         : where === "navBar" && isMobile 
             ? {display: "none"}
         : where === "mobileNavBar" && !isMobile  // mobile navbar so that it is outside collapsed:  there are 2 Search components in NavBar
             ? {display: "none"}
         : where === "sidebar"
             ? { width: "100%", marginLeft: "0px"}
             : {display: "block", marginLeft: "0px", marginTop: "0px"}  // i.e. mobileNavBar && isMobile

    return (
        <form 
            className="search-form"
            style={searchStyle}
            onSubmit={handleSubmitSearch}
        >
            <div onClick={handleSubmitSearch} >
                <SearchIcon />
            </div>
            <input 
                className="search-box"
                aria-label="Search"
                type="search" 
                // name="searchTerm"
                // value={searchTerm}
                // id="searchTerm"
                placeholder={(where === "mobile") ? "Search" : "Search by keyword"}
                value={searchTerm}
                onChange={handleSearchTermChange}      
                {...where === "sidebar" && { style: { width: "100%" } }}    
            />        
        </form>
    )
}

export default Search

