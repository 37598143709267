import { 
  SET_USER_AUTH, CLOSE_ALERT, GET_USER_PROFILE,
  SIGNUP_LOADING, SIGNUP_SUCCESS, SIGNIN_SUCCESS,
  USER_SIGNOUT, UPDATE_LOCAL_NEWS_VERSION, 
} from 'constants/constants';
import {
  userUidMaster, userEmailMaster, successMessage, errorMessage,
  signupFail, batchActionUponSignup, sendVerificationAndWelcomeEmails,
  batchCreatePostsInReduxUponSignup,
} from './helperFunctions';
import firebase, { db } from 'config/Firebase';


// used for both login and signup
const userSignupWithGoogle = () => async (dispatch) => {
    const provider = new firebase.auth.GoogleAuthProvider()

    dispatch({ type: SIGNUP_LOADING })

    try {
        const result = await firebase.auth().signInWithPopup(provider)

        if (!result) return dispatch(signupFail("Having trouble signing up. Please try again"))  

        const userDoc = await db.collection('users')
            .doc(result.user.uid)
            .get();

        if (userDoc.exists) return dispatch({ type: SIGNIN_SUCCESS })   // if user already exists, login and not update firestore             
        
        try { // below is base case: if user is new  
            result.user.updateProfile({ displayName: result.user.displayName })

            const docRef_1 = db.collection('users').doc(result.user.uid).collection("posts").doc()
            const docRef_2 = db.collection('users').doc(result.user.uid).collection("posts").doc()
            const docRef_3 = db.collection('users').doc(result.user.uid).collection("posts").doc()

            const batch = db.batch();
            batchActionUponSignup({
                result: result, 
                batch: batch, 
                email: result.user.email, 
                name: result.user.displayName,
                docRef_1: docRef_1,
                docRef_2: docRef_2,
                docRef_3: docRef_3
            });          
            await batch.commit();

            const newUserDoc = await db.collection('users')
                .doc(result.user.uid)
                .get()

            if (!newUserDoc.exists) { // user NOT created successully in Firestore
                firebase.auth().currentUser.delete();
                alert("Having trouble signing up. Please try again") // so alert appears at home
                return dispatch(signupFail("Having trouble signing up. Please try again"))                    
            }    


            // user is created successfully
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: {
                  displayName: result.user.displayName
                }
            })   

            // NEW
            // create first seed posts in redux to fix the issue of seed posts not showing on signup immediately
            dispatch(batchCreatePostsInReduxUponSignup({    // need dispatch since need to update reducer
                 docRef_1: docRef_1,
                 docRef_2: docRef_2,
                 docRef_3: docRef_3                
            }))

            firebase.functions().httpsCallable('sendWelcomeEmail')({  // cloud function to send Welcome email
                email: result.user.email, 
                displayName: result.user.displayName
            })    
        } catch (e) {
            dispatch(signupFail(`Having trouble signing up: ${e.message}`))
        }                     
    } catch (e) {
        const errorMessage = e.message || "Having trouble signing up. Please try again";
        return dispatch(signupFail(errorMessage))
    }
}

const userSignupSubmit = ({ e, pwRef, nameRef, emailRef }) => async (dispatch) => {
    const email = document.getElementById('signupemail').value;
    const password = document.getElementById('signuppassword').value;
    const name = document.getElementById('signupname').value;

    e.preventDefault()
    // handle more validation later
    if (email.length < 7) {
        emailRef.current.focus()
        return dispatch(signupFail("Please enter a valid email"))
    }
    if (password.length < 8) {
        pwRef.current.focus()
        return dispatch(signupFail("Please enter a longer password"))
    }
    if (name.length < 1) {
        nameRef.current.focus()
        return dispatch(signupFail("Please enter a valid name"))
    } 

    dispatch({ type: SIGNUP_LOADING })

    try {
        const result = await firebase.auth().createUserWithEmailAndPassword(email, password);

        if (!result) return dispatch(signupFail("Having trouble signing up. Please try again"))  

        try {
            result.user.updateProfile({ displayName: name })         

            const docRef_1 = db.collection('users').doc(result.user.uid).collection("posts").doc()
            const docRef_2 = db.collection('users').doc(result.user.uid).collection("posts").doc()
            const docRef_3 = db.collection('users').doc(result.user.uid).collection("posts").doc()

            const batch = db.batch();
            batchActionUponSignup({
                result: result, 
                batch: batch, 
                email: email, 
                name: name,
                docRef_1: docRef_1,
                docRef_2: docRef_2,
                docRef_3: docRef_3
            });
            await batch.commit() 

            const doc = await db.collection('users')
                .doc(result.user.uid)
                .get()

            if (!doc.exists) { // user NOT created successully in Firestore
                firebase.auth().currentUser.delete();
                alert("Having trouble signing up. Please try again") // so alert appears at home
                return dispatch(signupFail("Having trouble signing up. Please try again"))                    
            }    

            // user created successfully in Firestore
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: { 
                    displayName: name, 
                }
            })       

            // NEW
            // create first seed posts in redux to fix the issue of seed posts not showing on signup immediately
            dispatch(batchCreatePostsInReduxUponSignup({    // need dispatch since need to update reducer
                 docRef_1: docRef_1,
                 docRef_2: docRef_2,
                 docRef_3: docRef_3                
            }))

            sendVerificationAndWelcomeEmails({
                email: email,
                name: name
            })     

        } catch (e) {
             dispatch(signupFail(`Having trouble signing up: ${e.message}`))
        }
    } catch (e) {
        const errorCode = e.code;
        const errorMessageFirebase = e.message || "Having trouble signing up. Please try again.";
        if (errorCode === 'auth/weak-password') {
            pwRef.current.focus()
            return dispatch(signupFail("Please enter a stronger password"))
        } else if (errorCode === 'auth/invalid-email') {
            emailRef.current.focus()
            return dispatch(signupFail("Email is invalid. Please try again"))    
        } else if (errorCode === 'auth/email-already-in-use') {
            return dispatch(signupFail("Email is already registered. Please login or reset password"))    
        } else return dispatch(signupFail(errorMessageFirebase))
    }
}
 
const userLoginSubmit = ( e, emailRef, pwRef ) => async (dispatch) => {
    const email = document.getElementById('loginemail').value;
    const password = document.getElementById('loginpassword').value;
    e.preventDefault()
    // ensure previous user is logged out
    if (firebase.auth().currentUser) {
        firebase.auth().signOut();
        return dispatch(errorMessage("We have logged you out. Please try again."))
    }   
    if (email.length < 7) {
        emailRef.current.focus()
        return dispatch(errorMessage("Please enter a valid email."))
    }
    if (password.length < 8) {
        pwRef.current.focus()
        return dispatch(errorMessage("Please enter a valid password."))
    }
    
    try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
        console.log("Welcome back to Osoji. It's gonna be a good day.")
    } catch (error) {
        const errorCode = error.code;
        const errorMessageFirebase = `error encountered during sign in: ${error.message}`;
        if (errorCode === 'auth/wrong-password') {
            pwRef.current.focus()
            return dispatch(errorMessage("Password is wrong. Please try again or reset your password.")) 
        } else {
           emailRef.current.focus()
           return dispatch(errorMessage(errorMessageFirebase))
        }
    }
}

const setUserAuth = (value) => (dispatch) => {
    return dispatch({
      type: SET_USER_AUTH,
      payload: value
    })
}

const userLogout = () => async (dispatch) => {
    try {
        await localStorage.removeItem('user')

        await firebase.auth().signOut()

        dispatch({
          type: USER_SIGNOUT,
          payload: null    
        })

    } catch (e) {
        dispatch(errorMessage(`error in logging out: ${e.message}`))
    }
}

const resetPassword = (email) => async (dispatch) => {
    const actionCodeSettings = {
      url: `https://osoji.io/login`
    }

    try {
        await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)

        alert("please check your email for password reset link")

    } catch (e) {
        dispatch(errorMessage(`error in resetting password: ${e.message}`))
    }
}

const closeAlert = () => (dispatch) => {
    return dispatch({
      type: CLOSE_ALERT,
      payload: null
    })
}

// new: use in <Profile />
const getUserProfileAtApp = () => async (dispatch) => {
    const userUid = userUidMaster();

    try {
        const doc = await db.collection('users').doc(userUid).get()

        dispatch({
            type: GET_USER_PROFILE,
            payload: {
                stats: doc.data()?.stats ?? {}, // used as guard clause in profile...but think of something else
                // isSubscribedWeekly: doc.data()?.isSubscribedWeekly  ?? true,
                isSubscribedMonthly: doc.data()?.isSubscribedMonthly  ?? true,
                forwardAddress: doc.data()?.forwardAddress,
                authorizedSenders: doc.data()?.authorizedSenders || [],
                userWantsArchive: doc.data()?.userWantsArchive ?? true, // because old user might not have it
                
                userNeedsOnboardingAtHome: doc.data()?.userNeedsOnboardingAtHome ?? false,
                userNeedsOnboardingAtRoom: doc.data()?.userNeedsOnboardingAtRoom ?? false,
                userNeedsOnboardingAtProfile: doc.data()?.userNeedsOnboardingAtProfile ?? false,
            }
        })  

    } catch (e) {
        dispatch(errorMessage(`error encountered in User Profile: ${e.message}`))
    }
}


const resendEmailVerification = () => async (dispatch) => {
    const actionCodeSettings = {
      url: `https://osoji.io/home`
    }

    try {
        await firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)

        dispatch(successMessage("email verification sent. please check your email."))
    } catch (e) {
        dispatch(errorMessage(`error encountered. please try again: ${e.message}`))
    }
}

// to-do: no need to update name if name doesn't change
const updatePersonalSettings = (props) => async (dispatch) => {
    const {
        userAuthObject,
        userWantsArchivePreference, monthlyEmailPreference
    } = props

    const userUid = userUidMaster();

    const name = document.getElementById('profilename').value;
    const updatedAuthObject = {...userAuthObject, ...{displayName: name}};  //to update user reducer
    // const monthly = document.getElementById("monthly-subscribe").checked;
    // const archive = document.getElementById("archive-subscribe").checked;
    // const weekly = document.getElementById("weekly-subscribe").checked;

    if (name.length < 1) return dispatch(errorMessage("Please enter a valid name."))
   
    const updatedUserObject = {
        name: name,
        isSubscribedMonthly: monthlyEmailPreference,
        userWantsArchive: userWantsArchivePreference
        // isSubscribedWeekly: weekly,
    }

    try {
        await firebase.auth().currentUser.updateProfile({ displayName: name })

        await db.collection('users').doc(userUid)
            .update(updatedUserObject) 

        dispatch({
            type: SET_USER_AUTH,
            payload: updatedAuthObject // update name in user reducer
        })   

        // need to update archive preference since it affects other part of the app
         dispatch({
            type: "UPDATE_USER_WANTS_ARCHIVE",
            payload: userWantsArchivePreference // 
        })          

        if (name !== userAuthObject.displayName) {
            localStorage.setItem('user', JSON.stringify({
                email: userAuthObject.email,
                uid: userAuthObject.uid,
                displayName: name,
                isVerified: userAuthObject.isVerified 
            }));              
        }


        dispatch(successMessage("Successfully updated Personal Settings.")) 
            
    } catch (e) {
        dispatch(errorMessage(`error in updating profile: ${e.message}`))
    } 
}    

// use one time only
const setForwardAddress = (address, forwardRef) => async (dispatch) => {
    // note: address is just "yinhow", not yinhow@....
    const userUid = userUidMaster();
    const userEmail = userEmailMaster();

    const isValidAddress = address.match('^([a-z0-9_.]){4,30}$');
    if (!isValidAddress) {
        forwardRef.current.focus()
        return dispatch(errorMessage("Please use alphanumerical characters only."))      
    }
    if (address.length < 4) {
        forwardRef.current.focus()
        return dispatch(errorMessage("Please enter a longer id. Minimum length is 4 characters."))
    }   
    if (address.length > 30) {
        forwardRef.current.focus()
        return dispatch(errorMessage("Please enter a shorter id. Maximum length is 30 characters."))
    }       

    const batch = db.batch();
    const userRef = db.collection('users').doc(userUid)
    const addressRef = db.collection('forwardaddresses').doc(address)

    try {
        // check if address is unique
        const doc = await addressRef.get()    
        
        if (doc.exists) {  // guard clause: if taken, show error
            forwardRef.current.focus()
            return dispatch(errorMessage("This username is already taken. Please choose another."))
        } 

        // if unique, then update both user doc and forwardaddresses doc
        try {
            batch.update(userRef, {
                forwardAddress: address,  
            })
            batch.set(addressRef, {
                userId: userUid,
                authorizedSenders: firebase.firestore.FieldValue.arrayUnion(userEmail)
            })      

            await batch.commit()    

            dispatch({
                type: "CREATE_FORWARD_ADDRESS_SUCCESS",
                payload: address
            }) 

            dispatch(successMessage(`Success! ${address}@mail.osoji.io is ready to forward now`))

        } catch (e) {
            dispatch(errorMessage(`Error creating address: ${e.message}`))
        } 

    } catch (e) {
        dispatch(errorMessage(`Error creating address: ${e.message}`))
    }      
}

const addAuthorizedEmail = ({ e, senderRef, authorizedSenders, userForwardAddress, address }) => async (dispatch) => {
    e.preventDefault();

    const userUid = userUidMaster();
    const userEmail = userEmailMaster();

    // user must have a forward address first
    if (!userForwardAddress) return dispatch(errorMessage("Please setup your forward address before adding authorized emails."))

    // check if duplicate
    if (authorizedSenders.includes(address) || address === userEmail) { // authorizedSenders in user doesn't include own email
        senderRef.current.focus()
        return dispatch(errorMessage("This email is already authorized."))
    }
    if (address.length < 8) {
        senderRef.current.focus()
        return dispatch(errorMessage("Please enter a longer address."))
    }   
    if (address.length > 100) {
        senderRef.current.focus()
        return dispatch(errorMessage("Please enter a shorter address."))
    }       
    if (authorizedSenders.length > 9) {
        senderRef.current.focus()
        return dispatch(errorMessage("You have exceeded the maximum number of authorized addresses."))      
    }


    const batch = db.batch();
    const userRef = db.collection('users').doc(userUid)
    const addressRef = db.collection('forwardaddresses').doc(userForwardAddress)

    try {
        const doc = await addressRef.get() // DOUBLE check forward adddress is setup

        if (!doc.exists) {    // guard clause
            senderRef.current.focus()
            return dispatch(errorMessage("Forward address is not setup properly. Sorry - please try again or contact us."))              
        }

        // exists, which is good
        try {
            batch.update(userRef, {
                authorizedSenders: firebase.firestore.FieldValue.arrayUnion(address),  
            })
            batch.update(addressRef, {
                authorizedSenders: firebase.firestore.FieldValue.arrayUnion(address)
            })     
            await batch.commit()

            dispatch({
                type: "ADD_AUTHORIZED_EMAIL_SUCCESS",
                payload: address
            })
            dispatch(successMessage(`Success! ${address} is now authorized to send to your Osoji`))
        } catch (e) {
            dispatch(errorMessage(`Error creating address: ${e.message}`))
        }

    } catch (e) {
        dispatch(errorMessage(`Error creating address: ${e.message}`))
    }
}

const removeAuthorizedEmail = ({ senderAddress, userForwardAddress }) => async (dispatch) => {

    const userUid = userUidMaster();
    const batch = db.batch();
    const userRef = db.collection('users').doc(userUid);
    const addressRef = db.collection('forwardaddresses').doc(userForwardAddress)

    try {
        const doc = await addressRef.get() // DOUBLE check forward adddress is setup

        // guard clause
        if (!doc.exists)  return dispatch(errorMessage("Forward address is not setup properly. Sorry - please try again or contact us."))  

        // exists, which is good
        try {
            batch.update(userRef, {
                authorizedSenders: firebase.firestore.FieldValue.arrayRemove(senderAddress),  
            })
            batch.update(addressRef, {
                authorizedSenders: firebase.firestore.FieldValue.arrayRemove(senderAddress)
            })     
            await batch.commit()

            dispatch({
                type: "REMOVE_AUTHORIZED_EMAIL_SUCCESS",
                payload: senderAddress
            })
            dispatch(successMessage(`${senderAddress} is successfully removed`))
        } catch (e) {
            dispatch(errorMessage(`Error removing address: ${e.message}`))
        }

    } catch (e) {
        dispatch(errorMessage(`Error removing address: ${e.message}`))
    }
}

const updateLocalNewsVersion = () => (dispatch) => {
    dispatch({
      type: UPDATE_LOCAL_NEWS_VERSION,
    })
}

const getAppVersionNumber = () => (dispatch) => {
    db.collection('constants').doc("AppVersionNumber")
    .get()
    .then((doc) => {
        dispatch({
            type: "GET_APP_VERSION_NUMBER",
            payload: doc.data().AppVersionNumber
        })  
    })
    .catch((e) => dispatch(errorMessage(`error: ${e.message}`)))     
}

const setColorTheme = (theme) => (dispatch) => {
    dispatch({
        type: "SET_COLOR_THEME",
        payload: theme
    })
}

const copyToClipboard = () => (dispatch) => dispatch(successMessage("Public link copied to clipboard"))

const setSideBarCollapse = (sideBarCollapse) => (dispatch) => {
    dispatch({
        type: "SET_SIDE_BAR_COLLAPSE",
        payload: sideBarCollapse
    })    
}

const setHomeShowArchiveNotice = (toShow) => (dispatch) => {
    dispatch({
        type: "SET_HOME_SHOW_ARCHIVE_NOTICE",
        payload: toShow
    })    
}

// for every page that needs onboarding
const userFinishesOnboarding = ({ where }) => async (dispatch) => { // where -- Home, Profile, Room
    const userUid = userUidMaster();
    // let updatedUserObject;
    let updatedUserObject = {
        [`userNeedsOnboardingAt${where}`]: false
    }

    try {
        await db.collection('users').doc(userUid).update(updatedUserObject) 

        dispatch({
            type: "USER_FINISHES_ONBOARDING",
            payload: where
        })       

    } catch (e){
        dispatch(errorMessage(`error encountered in updating onboarding: ${e.message}`)) 
    } 
}




const userActions = {
    userLoginSubmit, userSignupSubmit, setUserAuth, userLogout, resetPassword, closeAlert,
    userSignupWithGoogle,resendEmailVerification, updatePersonalSettings,
    updateLocalNewsVersion, getAppVersionNumber,
    setForwardAddress, addAuthorizedEmail, removeAuthorizedEmail, setColorTheme, copyToClipboard,
    setSideBarCollapse, setHomeShowArchiveNotice,
    getUserProfileAtApp, 
    userFinishesOnboarding
}

export default userActions;


/*
 const getUserProfile = () => async (dispatch) => {
     const userUid = userUidMaster();
 
     try {
         const doc = await db.collection('users').doc(userUid).get()
 
         dispatch({
             type: GET_USER_STATS,
             payload: {
               stats: doc.data().stats,
               isSubscribedWeekly: doc.data().isSubscribedWeekly,
               isSubscribedMonthly: doc.data().isSubscribedMonthly,
               forwardAddress: doc.data().forwardAddress,
               authorizedSenders: doc.data().authorizedSenders
             }
         })  
 
     } catch (e) {
         dispatch(errorMessage(`error encountered in User Profile: ${e.message}`))
     }
 }


*/
