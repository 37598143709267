import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from "actions";
import Alert from "components/Alert/Alert";

const EmailNotVerified = () => {
	const dispatch = useDispatch();
	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null

	const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
	const resendButtonColor = resendButtonDisabled ? "grey" : "var(--main)"

	return (
		<div style={{ width: "80%", margin: "auto", marginTop: "60px"}}>
			<h2 style={{ margin: "20px"}}>Verify your email address</h2>
			<img style={{ maxWidth: "400px"}} src={`${process.env.PUBLIC_URL}/images/contact/on-the-go.svg`} alt='contact-us' />		
			<div style={{ width: "80%", maxWidth: "400px", margin: "auto", textAlign: "left"}}>
				<p>We have sent you an email to <span style={{ fontWeight: "bold"}}>{user.email}</span>.</p>
				<p>Please check your inbox and click on the verification link in the email.</p>
			</div>
			<br />
			<h4>Troubleshoot</h4>
			<div style={{ width: "80%", maxWidth: "400px", margin: "auto", textAlign: "left"}}>
				<p>1) If you don't receive it in a minute, re-send the verification email: 
					{" "} 
					<button 
						onClick={() => {
							setResendButtonDisabled(true);
							dispatch(allActions.userActions.resendEmailVerification())
						}}
						disabled={resendButtonDisabled}
						className="main-button"
						style={{ backgroundColor: resendButtonColor, padding: "3px 10px" }}
					>here</button>
				</p>
				<p>2) If you have already verified, try refreshing the page.</p>
			</div>
			<Alert />
		</div>
	)
}

export default EmailNotVerified;