import { 
  CHECK_ADMIN, 
} from 'constants/constants';
import { 
	updateUserRecommendations, sortUsersByJoin, sortUsersByActive,
	sortUsersByNumberOfPosts, updateMonthlyRecommendation
} from './reducerHelperFunctions';


const initialUserState = {
	isAdmin: null,
	subscribedUsers: [],
	isAdminLoading: false,
	monthlyRecommendations: []
}


const adminReducer = (state=initialUserState, action) => {
	switch(action.type) {
		case CHECK_ADMIN:
			return {
				...state,
				isAdmin: action.payload,
				isAdminLoading: false
			}	
		case "GET_SUBSCRIBED_USERS":
			return {
				...state,
				subscribedUsers: action.payload,
				isAdminLoading: false
			}		
		case "ADMIN_LOADING":
			return {
				...state,
				isAdminLoading: true
			}	
		case "UPDATE_USER_RECOMMENDATIONS":
			return {
				...state,
				subscribedUsers: updateUserRecommendations(state, action.payload)
			}				
		case "SORT_USER_BY_JOIN":
			return {
				...state,
				subscribedUsers: sortUsersByJoin(state, action.payload)
			}		
		case "SORT_USER_BY_ACTIVE":
			return {
				...state,
				subscribedUsers: sortUsersByActive(state, action.payload)
			}			
		case "SORT_USER_BY_NUMBER_OF_POSTS":
			return {
				...state,
				subscribedUsers: sortUsersByNumberOfPosts(state, action.payload)
			}		
		case "ADMIN_GET_MONTHLY_RECOMMENDATIONS": 
			return {
				...state,
				monthlyRecommendations: action.payload,
				isAdminLoading: false
			}						
		case "ADMIN_UPDATE_MONTHLY_RECOMMENDATIONS":
			return {
				...state,
				monthlyRecommendations: updateMonthlyRecommendation(state, action.payload)
			}		
		default: 
			return state; 
	}	
}

export default adminReducer;