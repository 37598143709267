import { useDispatch } from 'react-redux';
import allActions from "actions";
import ReactTooltip from "react-tooltip";
import DeleteConfirmModal from "components/Modals/DeleteConfirmModal/DeleteConfirmModal";
import GroupChangeRoomModal from "components/Modals/GroupChangeRoomModal/GroupChangeRoomModal";
import {  useDeleteConfirmModal, useChangeRoomModal } from "hooks/useModals";


const FixedNavbarWithSelectedPosts = ({ selectedPosts, colorTheme }) => {
	const dispatch = useDispatch();

	const { postToDelete, modalDeletePost, toggleDeletePost } = useDeleteConfirmModal();
	const { postForChangeRoom, modalChangeRoom, toggleChangeRoom } = useChangeRoomModal();


	const fixedNavbarStyle = {
		position: "fixed",
	  	top: "0",
		zIndex: "99999",
		width: "100%", 
		backgroundColor: "var(--navbar-fixed-bg)"	,
		color: "var(--header)",
		height: "60px",
		alignItems: "center",
		padding: "15px",
		fontSize: "20px",
	}

	return (
		<div style={fixedNavbarStyle}>
			{selectedPosts.length} posts selected

			<span><img 
				style={{ marginLeft: "36px", cursor: "pointer", width: "20px"}}
				src={(colorTheme==="light") ? `${process.env.PUBLIC_URL}/images/icons/trash.svg` : `${process.env.PUBLIC_URL}/images/icons/trash-dark.svg` }
				alt="delete" 
				data-tip data-for="group-delete"
				onClick={() => { toggleDeletePost(selectedPosts) }}
			/></span>

			<span><img 
				style={{ marginLeft: "20px", cursor: "pointer", width: "18px"}}
				src={(colorTheme==="light") ? `${process.env.PUBLIC_URL}/images/icons/change-room.svg` : `${process.env.PUBLIC_URL}/images/icons/change-room-dark.svg` }
				alt="move" 
				data-tip data-for="group-move"
				onClick={() => toggleChangeRoom(selectedPosts)}
			/></span>

			<span><img 
				style={{ marginLeft: "16px", cursor: "pointer", width: "24px"}}
				src={(colorTheme==="light") ? `${process.env.PUBLIC_URL}/images/icons/X.svg` : `${process.env.PUBLIC_URL}/images/icons/x-white.svg` }
				alt="clear" 
				data-tip data-for="group-clear"
				onClick={() => dispatch(allActions.contentActions.clearPostsInGroup())}
			/></span>

			<ReactTooltip id="group-delete" place="bottom" effect="float">
          		delete selected
        	</ReactTooltip> 			
 			<ReactTooltip id="group-move" place="bottom" effect="float">
          		move
        	</ReactTooltip> 	       	
			<ReactTooltip id="group-clear" place="bottom" effect="float">
          		clear
        	</ReactTooltip> 	

		   	<DeleteConfirmModal
		   		postToDelete={postToDelete}
		   		modalDeletePost={modalDeletePost}
		   		toggleDeletePost={toggleDeletePost}
		   		// owner={owner}
		   	/>

		   	<GroupChangeRoomModal
		   		postForChangeRoom={postForChangeRoom}
		   		modalChangeRoom={modalChangeRoom}
		   		toggleChangeRoom={toggleChangeRoom}
		   	/>   	


		</div>
	)
}

export default FixedNavbarWithSelectedPosts;