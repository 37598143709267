import { Link } from 'react-router-dom';
import './footer.css'

// used at Landing, About, HowItWorks

const Footer = () => {
	return (
		<footer className="footer-landing">
			<div className="footer-container">
				<div>
					<Link to="/" className="dont-link-me" style={{ height: "60px",width: "200px", marginBottom: "0px", marginRight: "0px" }}>
						<img 
							style={{ height: "60px", width: "60px", marginRight: "16px", verticalAlign: "middle"}} 
							src={`${process.env.PUBLIC_URL}/images/logo/osoji-logo-3.png`} 
							alt='osoji-logo' 
						/> 
						<span style={{ 
							fontWeight: "600", fontSize: "40px", color: "var(--header)",
							verticalAlign: "middle",
						}}>Osoji</span> 					
					</Link>

					<p className="footer-copyright">© 2022 Osoji</p>
				</div>	

				<div className="footer-list-group">
					<div>
						<h4>About</h4>
						<ul>
							<li><Link to="/how-it-works" className="dont-link-me">Intro</Link></li>
							<li><Link to="/about" className="dont-link-me">Osoji Philosophy</Link></li>
							<li><Link to="/contact" className="dont-link-me">Contact</Link></li>
						</ul>
					</div>
					<div>
						<h4>Info</h4>
						<ul>
							<li><Link to="/FAQ" className="dont-link-me">FAQs</Link></li>						
							<li><Link to="/terms" className="dont-link-me">Terms of Service</Link></li>
							<li><Link to="/privacy" className="dont-link-me">Privacy Policy</Link></li>
						</ul>
					</div>	
				</div>								
			</div>


		</footer>
	)
}

export default Footer;

/*		

			<div className="footer-master"> 
				<div className="footer-text-group">
					<p>© 2022 Osoji</p>

				</div>
				<div className="footer-icon-group">
					<a href="https://www.facebook.com/osoji.io" target="_blank" rel="noreferrer"><img className="social-icon" src={`${process.env.PUBLIC_URL}/images/footer/fb-icon.svg`} alt='fb-icon' /></a>
					<a href="https://twitter.com/osoji_io" target="_blank" rel="noreferrer"><img className="social-icon" src={`${process.env.PUBLIC_URL}/images/footer/twitter-icon.svg`} alt='twitter-icon' /></a>
					<img className="social-icon" src={`${process.env.PUBLIC_URL}/images/footer/instagram-icon.svg`} alt='instagram-icon' />    
				</div>		
			</div>


	<img className="" src={`${process.env.PUBLIC_URL}/images/logo/LogoWithText_footer.png`} alt='osoji-logo-footer' />	
		<p>
			Made with love from 
			<span><img className="footer-flag" src={`${process.env.PUBLIC_URL}/images/footer/usa.svg`} alt='usa' /></span>
			<span><img className="footer-flag" src={`${process.env.PUBLIC_URL}/images/footer/korea.svg`} alt='korea' /></span>
			<span><img className="footer-flag" src={`${process.env.PUBLIC_URL}/images/footer/singapore.svg`} alt='singapore' /></span>
		</p> 


*/
