import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EmailNotVerified from 'components/EmailNotVerified/EmailNotVerified';
import SideBar from 'components/SideBar/SideBar';
import { useMediaQuery } from 'react-responsive';


const PrivateRoute = ({ component: Component, ...rest }) => {

	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null
	// const user = userFromState ? userFromState : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null 	

	const sideBarCollapse = useSelector(state => state.userReducer.sideBarCollapse);
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' })	

	const mainComponentStyle = isMobile // ( window.innerWidth < 768 )  
		? {marginLeft: "0px", transition: "margin-left .5s"}
		: sideBarCollapse  
		? {marginLeft: "40px", transition: "margin-left .5s"} 
		: {marginLeft: "250px", transition: "margin-left .5s"}

	return (
		<>
			<Route {...rest} render={() => {
			  return (!user) 
			    ? <Redirect to='/' />
			    : (user.isVerified)
			    ? <>
					<SideBar />
			    	<main style={mainComponentStyle}>
				    	<Component />
				    </main>	
			    </>
			    : <EmailNotVerified />
			}} />
		</>
	)
}


export default PrivateRoute;

		  // return (user) 
		  //   ? <Component />
		  //   : <Redirect to='/signup' />
		    
