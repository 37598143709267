import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import "fontsource-nunito-sans";

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

import {Provider} from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

const logger = createLogger();

const middleware = [thunkMiddleware]

if (process.env.NODE_ENV !== `production`) {
    middleware.push(logger);
}

const store = createStore(
    rootReducer, applyMiddleware(...middleware)
)
// const store = createStore(
//     rootReducer, applyMiddleware(thunkMiddleware, logger)
// )

// 	  <React.StrictMode> originally below provider
ReactDOM.render(
	<Provider store={store}>	
	    <BrowserRouter>
	    	<App />
	    </BrowserRouter>	
	</Provider>	  
  , document.getElementById('root')
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
