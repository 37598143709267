import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link, Redirect } from 'react-router-dom';
import './landing.css';
// import Solutions from './components/Solutions';
import SEO from 'components/SEO/SEO';
import UseCases from './components/UseCases';
import Testimonials from './components/Testimonials';

// const xIcon = <img loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/x.svg`} alt='x' />

// CURRENT USED AS /INSPIRATIONS
const Landing = () => {

	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null

	useEffect(() => {
		window.scrollTo(0, 0);
	},[])

 	if (user) return <Redirect to='/home' />    // if logged in, redirect

	return(
		<main>
			<SEO title="Inspirations: Osoji" />
		{/*	
			<section className="hero-container">	
				<div className="hero-top">
					<h1 className="hero-header">Your Personal Resource Space</h1>
					<h6 className="hero-subtitle">Save and organize links, notes, images </h6>

					<button className="main-button" style={{width: "200px"}}><Link to="/signup" className="dont-link-me">Get Started</Link></button>
				</div>

				<div className="hero-bottom">
					<img loading="lazy" className="hero-image" src={`${process.env.PUBLIC_URL}/images/landing/landing-hero-2.png`} alt='landing-hero-screenshot' />		
					<div className="hero-what-is-osoji">
						<ul>
							<li>{xIcon} too many tabs open</li>
							<li>{xIcon} emails / messages to yourself</li>
							<li>{xIcon} screenshots on phone</li>
							<li>{xIcon} messy browser bookmarks</li>
						</ul>
						<p className="hero-subtitle-2">Stop leaving your ideas and inspirations everywhere.</p> 
						<p className="hero-subtitle-2">Build your personal resource space at Osoji.</p> 
					</div>
				</div>
			</section>			
		
			<Solutions />
		*/}
			<UseCases />
		{/*	
			<section className="benefit-container-2">
					<div className="benefit-container-overlay-2">
						<img loading="lazy" className="benefit-image-2" src={`${process.env.PUBLIC_URL}/images/landing/notes-3.svg`} alt='notes-1' />  
						<div className="benefit-text-2">
							<h2>Don't leave your insights everywhere</h2>
							<img loading="lazy" className="benefit-image-2-mobile" src={`${process.env.PUBLIC_URL}/images/landing/notes-3.svg`} alt='notes-1' />					
							<h6>Keep them for reference later</h6>
							<div>
								<button className="main-button" style={{width: "160px"}}><Link to="/signup" className="dont-link-me">
								Start Saving
								</Link></button>				
							</div>							
						</div>
					</div>
			</section>
		*/}	


			<Testimonials />

			<section className="landing-outtro-section">
				<h4>Whether you want to get organized, keep your personal life on track, or boost work productivity</h4>
				<button className="main-button" style={{width: "200px"}}><Link to="/signup" className="dont-link-me">Try Osoji for Free</Link></button>			
				<p>Let your inspirations flow through Osoji</p>
			</section>
		</main>
	)
}

export default Landing;