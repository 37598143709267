import "./plusbutton.css";

// used in Navbar/RoomMenuDropdown, WarroomSectionNew, Profile/EmailForwardSetting 
const PlusButton = () => {
	return (
		<button 
			className="plus-button"
			type="submit"
		>
			<img src={`${process.env.PUBLIC_URL}/images/navbar/plus.svg`} alt="add" />
		</button>	
	)
}

export default PlusButton;