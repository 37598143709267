import { Route, } from 'react-router-dom';
import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';

// has NavBar and Footer
const PublicRoute = ({ component: Component, ...rest }) => {

	return (
		<Route {...rest} render={() => {
		  return 	 <>
				    	<NavBar />
					    <Component />
					    <Footer />
		    		</>

		}} />
	)
}


export default PublicRoute;

		    
