import { 
	GET_POSTS_PENDING, CREATE_ROOM, GET_ROOMS, DELETE_ROOM, EDIT_ROOM_TITLE, GET_ROOM_DETAILS, 
} from 'constants/constants';
import {
	userUidMaster, userEmailMaster, userObjectMaster,
	successMessage, errorMessage, 
	isDuplicateRoom, sendRoomsToExtension, sendTokenToExtension,
} from './helperFunctions';
import contentActions from './contentActions';
import firebase, { db } from 'config/Firebase';
import moment from 'moment';

const { getPostsPublic, getPostsShared } = contentActions

// ===========================================================================
//                            CRUD 1 War Room
// ===========================================================================

// used in NavBar/RoomsMenuDropdown and Sidebar and WarroomSection at Home
const createRoom = ({ rooms, e, submitRoomField, sharedRooms }) => async (dispatch) => {	// rooms is for room limit and check duplicate
	e.preventDefault()
	const userUid = userUidMaster();
	const roomTitle = submitRoomField;

	let roomsToSendExtension = [...rooms, ...sharedRooms];

	if (roomTitle.length < 1) return dispatch(errorMessage("Please enter a valid room title."))
	if (rooms.length > 30) return dispatch(errorMessage("You've reached the maximum number of Rooms.")) 		// temporary handle max room number 	
	if (isDuplicateRoom(roomTitle, rooms)) return dispatch(errorMessage("You have a room with the same name. Please create room with a different name."))

  	const newDocRef = db.collection('users').doc(userUid).collection('warrooms').doc()

  	try {
	  	await newDocRef.set({
	  		title: roomTitle,
	  		roomId: newDocRef.id,
	  		owner: userUid,
	  		isActive: true,
	  		createdAt: firebase.firestore.FieldValue.serverTimestamp(),
	  		count: firebase.firestore.FieldValue.increment(0)  // set initial count as 0
	  	})

	  	await dispatch({
	  		type: CREATE_ROOM,
	  		payload: {
		  		title: roomTitle,
		  		roomId: newDocRef.id,
		  		owner: userUid,
		  		isActive: true,
		  		createdAt: moment().format('Do MMMM YYYY'),
		  		count: 0
	  		}
	  	})

	  	await dispatch(successMessage("room created successfully."))

	  	await dispatch(sendRoomsToExtension(userUid, [{	  //TEST
				title: roomTitle, 
				roomId: newDocRef.id,
				count: 0,
				publicId: null,
				sharedWith: null
			}, ...roomsToSendExtension]))

  	} catch (e) {
  		dispatch(errorMessage(`error in creating room: ${e.message}`))
  	}
}

// delete room doc, then call cloud function to delete all posts of the room;  
// use in editRoomModal which is in WarroomSectionNew, WarroomDetailsPage
const deleteRoom = ({ room, rooms, sharedRooms }) => async (dispatch) => {
	const userUid = userUidMaster();

	const newRoomsArray = rooms.filter((eachRoom) => eachRoom.roomId !== room.roomId); // removing room to be d
	let roomsToSendExtension = [...newRoomsArray, ...sharedRooms];

	try {
		await db.collection('users').doc(userUid)
		    .collection("warrooms")
		    .doc(room.roomId)
		    .delete() 

		await dispatch({
	    	type: DELETE_ROOM,
	    	payload: newRoomsArray
	    })    

		await dispatch(successMessage("room deleted successfully."))

		await dispatch(sendRoomsToExtension(userUid, roomsToSendExtension))

		if (room.publicId) {
			await db.collection('publicrooms')
				.doc(room.publicId).delete()	
		}
		// new
		if (room.sharedWith) {	// if there is sharedWith, means there is a sharedroom doc
			await db.collection('sharedrooms')
				.where("originalRoomId", "==", room.roomId)
				.get()
				.then((rooms) => {
					rooms.forEach((sharedroom) => {
						db.collection('sharedrooms').doc(sharedroom.id)	// refer to sharedroom id
						.delete()
					})			
				})
		}			

		await firebase.functions().httpsCallable('deleteRoom')({  // cloud function to delete all posts in room
			room: room.roomId, 
		})

	} catch (e) {
		dispatch(errorMessage(`error in deleting room: ${e.message}`))
	}
}

// used at EditRoomModal
const editRoomTitle = ({ room, rooms, sharedRooms }) => async (dispatch) => {
	const userUid = userUidMaster();
	
	const newRoomTitle = document.getElementById(`editwarroom-${room.roomId}`).value;
	
	for (let i=0; i<rooms.length; i++) {
		if (rooms[i].roomId === room.roomId) {
			rooms[i].title = newRoomTitle
			break
		}
	}

	try {
	    await db.collection('users').doc(userUid)
		    .collection("warrooms")
		    .doc(room.roomId)
		    .update({
		  		title: newRoomTitle,
		  	})	 

		dispatch({
			type: EDIT_ROOM_TITLE, 
			payload: {
				rooms: rooms,
				newRoomTitle: newRoomTitle
			}
		})	

		dispatch(sendRoomsToExtension(userUid, [...rooms, ...sharedRooms]))

		if (room.publicId) {
			db.collection('publicrooms').doc(room.publicId)
			.update({
				roomTitle: newRoomTitle
			})	
		}
		
		if (room.sharedWith) {	// if there is sharedWith, means there is a sharedroom doc
			const sharedRoomsCollection = await db.collection('sharedrooms')
				.where("originalRoomId", "==", room.roomId)
				.get()

			sharedRoomsCollection.forEach(sharedRoomDoc => {
				db.collection('sharedrooms').doc(sharedRoomDoc.id)	// refer to sharedroom id
				.update({
					roomTitle: newRoomTitle
				})
			})	
		}

	} catch (e) {
		dispatch(errorMessage(`error in editing room: ${e.message}`))
	}
}

// ===========================================================================
//              list of War Rooms and WarroomDetailsPage
// ===========================================================================

/* to add shared rooms to extension

have a getRoomsMaster (that does both getRooms and getSharedRooms)
-- then in sendTokenToExtension, the room includes both own and shared rooms

in sendRoomsToExtension,  the tempRooms props need to include sharedRoom as well

for sharedRoom, the ID is:  `${room.originalRoomId}-${room.owner}-sharedroom` 

----------
in extension:
-- when receiving rooms,  a shared room title will end with (shared),  and the id will end with -sharedroom
-- also, REMOVED updating. User now needs to send to the right room and add labels. This is because changing room to sharedRoom is difficult. nEed to delete

-----------
in createXPostExtension: 
-- if "room" props is in `${room.originalRoomId}-${room.owner}-sharedroom` , then make uid as ownerId

------others-----
(done) change navbar sidebar onboarding to getRoomsMaster 
(to test) what about in sendRoomsToExtension???  create room, delete room, edit room title now includes sharedRooms too
*/

// used in App, Navbar, SideBar, onboarding
const getRoomsMaster = () => async (dispatch) => {
	const userUid = userUidMaster();
	const userEmail = userEmailMaster();	
	const tempRooms = [];
	const tempSharedRooms = [];
	const roomsToSendExtension = [];

	try {
		const rooms = await db
			.collection('users').doc(userUid)
			.collection('warrooms')
			.orderBy("createdAt", "desc")
			.get()	
		await rooms.forEach(room => {
			tempRooms.push({
				title: room.data().title,
				roomId: room.id,
				count: room.data().count,
				publicId: room.data().publicId,
				sharedWith: room.data().sharedWith,
				pin: room.data().pin
			})
			roomsToSendExtension.push({
				title: room.data().title,
				roomId: room.id,				
			})
		})	
		dispatch({
			type: GET_ROOMS,
			payload: tempRooms
		})

		const sharedRooms = await db
			.collection('sharedrooms')
			.where("sharedWith", "array-contains", userEmail)
			.get()
		await sharedRooms.forEach(room => {
			tempSharedRooms.push({
				originalRoomId: room.data().originalRoomId,
				sharedRoomId: room.id,
				owner: room.data().owner,
				ownerName: room.data().ownerName,
				roomTitle: room.data().roomTitle,
			})
			roomsToSendExtension.push({	// for extension
				title: `(shared) ${room.data().roomTitle}`,
				roomId: `${room.data().originalRoomId}-${room.data().owner}-sharedroom`,				
			})	
		})
		dispatch({
			type: "GET_SHARED_ROOMS",
			payload: tempSharedRooms
		})			


		dispatch(sendTokenToExtension(userUid, roomsToSendExtension)) 

	} catch (e) {
		dispatch(errorMessage(`error in getting Rooms: ${e.message}`))
	}

}

// for individual war room page
const getRoomDetails = (roomId) => async (dispatch) => {
	const userUid = userUidMaster();

	if (!userUid) return dispatch(errorMessage("You do not have access to this room"))  // HANDLE THIS LATER

	try {
		const room = await db.collection("users").doc(userUid)
			.collection("warrooms").doc(roomId)
			.get()

		if (!room.exists) {
			dispatch(errorMessage("You do not have access to this room"))
		} else {
			dispatch({
				type: GET_ROOM_DETAILS,
				payload: {
					title: room.data().title,
					roomId: room.id,
					publicId: room.data().publicId || null,
					sharedWith: room.data().sharedWith || []  
				}
			})
		}			
			
	} catch (e) {
		dispatch(errorMessage(`error in getting Room Details: ${e.message}`))
	}
}

// used in WarroomDetailsPage when component unmount
const resetRoomDetails = () => (dispatch) => {
	dispatch({
		type: GET_ROOM_DETAILS,
		payload: {
			title: "",
			roomId: "",
			publicId: null,
			sharedWith: []
		}
	})	
}

// for PUBLIC room . USED IN PublicWarroomDetailsPage
const getRoomDetailsPublic = (publicRoomId) => async (dispatch) => {
	//check across all subcollections
	dispatch({ type: GET_POSTS_PENDING })

	try {
		const room = await db.collection("publicrooms").doc(publicRoomId).get()

		if (room.exists) {
			dispatch({
				type: GET_ROOM_DETAILS,  // change later
				payload: {
					title: room.data().roomTitle,
					roomId: room.data().roomId, // aka original room
					owner: room.data().owner,
					ownerName: room.data().ownerName					
				}			
			})

			dispatch(getPostsPublic(room.data().roomId, room.data().owner))			

		} else dispatch(errorMessage("The room you're looking for is not available."))

	} catch (e) {
		dispatch(errorMessage(`error in getting Room details: ${e.message}`))
	}
}

const getRoomDetailsShared = (sharedRoomId) => async (dispatch) => {
	const userEmail = userEmailMaster();

	dispatch({ type: GET_POSTS_PENDING })

	try {
		const room = await db.collection("sharedrooms").doc(sharedRoomId).get()

		if (room.exists) {
			if (room.data().sharedWith.includes(userEmail)) {
				dispatch({
					type: GET_ROOM_DETAILS,  
					payload: {
						title: room.data().roomTitle,
						roomId: room.data().originalRoomId,
						owner: room.data().owner,
						ownerName: room.data().ownerName					
					}			
				})
				dispatch(getPostsShared(room.data().originalRoomId, room.data().owner))	
			} else {
				dispatch(errorMessage("Oops. You do not currently have access to this room."))			
			}			
		} else {
			dispatch(errorMessage("The room you're looking for is not available."))
		}

	} catch (e) {
		dispatch(errorMessage(`error in getting room details: ${e.message}`))
	}
}


// ===========================================================================
//              OTHERS
// ===========================================================================

// create public room. Used in WarroomDetailsPage
const createPublicLink = (roomId, roomTitle) => async (dispatch) => {
	const user = userObjectMaster()

	try {
		// add a new document
		const result = await db.collection('publicrooms')
			.add({
				owner: user.uid,
				ownerName: user.displayName,
				roomId: roomId,  // old room
				roomTitle: roomTitle
			})

		if (result) { // if success, add publicId to original room doc
			await db.collection('users').doc(user.uid)
			.collection('warrooms').doc(roomId)
			.update({
				publicId: result.id
			})
		}

		setTimeout(() => dispatch(getRoomDetails(roomId)), 1000)

		dispatch(successMessage("Public link created."))

	} catch (e) {
		dispatch(errorMessage(`error in creating public link: ${e.message}`))
	}	
}

// remove public link. Used in WarroomDetailsPage.
const deletePublicLink = (roomId, publicId) => async (dispatch) => {
	const userUid = userUidMaster();

	try {
		await db.collection('publicrooms').doc(publicId).delete()

		await db.collection('users').doc(userUid)
			.collection('warrooms').doc(roomId)
			.update({
				publicId: null
			})

		await setTimeout(() => dispatch(getRoomDetails(roomId)), 1000)	

		dispatch(successMessage("Public link removed."))

	} catch (e) {
		dispatch(errorMessage(`error in deleting public link: ${e.message}`))
	}
}

// in ShareModal
const addFriendToSharedRoom = (props) => async (dispatch) => {
	const { e, invitedEmail, roomId, roomTitle, sharedWith } = props;

	e.preventDefault()		// this prevent default submit form which refreshes the page

	const user = userObjectMaster();

	const userUid = user.uid;
	const userName = user.displayName;
	const invitedCount = sharedWith.length;
	const newRoomTitle = roomTitle.toLowerCase().replace(/\W+/g, "-");

	if (invitedCount > 9) {		
		return dispatch(errorMessage("You can invite only up to 10 friends."))		
	}
	if (sharedWith.includes(invitedEmail)) {	//  check against duplicate invite
		return dispatch(errorMessage(`Duplicate: You have already invited ${invitedEmail}`))	
	}
	if (userName === invitedEmail) {		// dont invite yourself
		return dispatch(errorMessage("Please enter other's email."))	
	}

	try {
		// FIRST update user's warroom sharedWith
	    await db.collection('users').doc(userUid)
	    .collection("warrooms")
	    .doc(roomId)
		.update({
			sharedWith: firebase.firestore.FieldValue.arrayUnion(invitedEmail)
		})

		await dispatch({
			type:"ADD_FRIEND_TO_SHARED_ROOM",
			payload: invitedEmail
		})
		// SECOND go to root level SharedRoom - if exists, add; else, create	
		const rooms = await db.collection('sharedrooms')
			.where("originalRoomId", "==", roomId)
			.get()

		if (rooms.size < 1) {	// if first time, create sharedrooms doc
		  	const newDocRef = db.collection('sharedrooms').doc()
		  	try {
			  	newDocRef.set({
			  		originalRoomId: roomId,
			  		owner: userUid,			  		
			  		ownerName: userName,
			  		roomTitle: roomTitle,
			  		sharedWith: [invitedEmail]
			  	})		  	
				firebase.functions().httpsCallable('sendInviteEmailRoomShared')({  // cloud function to send Invite email
					  email: invitedEmail, 
					  roomTitle: roomTitle,
					  ownerName: userName,
					  roomUrl: `https://osoji.io/room/shared-${newRoomTitle}-${newDocRef.id}` ,
				})			  		
		  	} catch (e) {
		  		dispatch(errorMessage(`error in adding friend: ${e.message}`))
		  	}
		} else { // if doc exists, just update
			rooms.forEach((room) => {
				// sharedRoomId = room.id;	// for email 
				db.collection('sharedrooms').doc(room.id)		// refer to sharedroom id
				.update({
					sharedWith: firebase.firestore.FieldValue.arrayUnion(invitedEmail)
				})
				
				firebase.functions().httpsCallable('sendInviteEmailRoomShared')({  // cloud function to send Invite email
				  email: invitedEmail, 
				  roomTitle: roomTitle,
				  ownerName: userName,
				  roomUrl: `https://osoji.io/room/shared-${newRoomTitle}-${room.id}` ,
				})
			})	
		}	

		dispatch(successMessage(`Invitation sent to ${invitedEmail}`))	

	} catch (e) {
		dispatch(errorMessage(`error in adding friend: ${e.message}`))
	}
}

const removeFriendFromSharedRoom = (removedEmail, roomId) => async (dispatch) => {
	const userUid = userUidMaster();

	try {
		await db.collection('users').doc(userUid)
		    .collection("warrooms")
		    .doc(roomId)
			.update({
				sharedWith: firebase.firestore.FieldValue.arrayRemove(removedEmail)
			})

		await dispatch({
			type:"REMOVE_FRIEND_TO_SHARED_ROOM",
			payload: removedEmail
		})

		const rooms = await db.collection('sharedrooms')
			.where("originalRoomId", "==", roomId)
			.get()

		rooms.forEach((room) => {
			db.collection('sharedrooms').doc(room.id)	// refer to sharedroom id
			.update({
				sharedWith: firebase.firestore.FieldValue.arrayRemove(removedEmail)
			})
		})		

		dispatch(successMessage(`Removed access from ${removedEmail}`))		

	} catch (e) {
		dispatch(errorMessage(`error in removing friend: ${e.message}`))
	}
}

// leave shared room as a sharee (used in ShareRoomsSection at Home)
const leaveSharedRoom = (roomId) => async (dispatch) => {		// sharedroom id
	const userEmail = userEmailMaster();
	
	let originalRoomId;
	let owner;

	const docRef = db.collection('sharedrooms').doc(roomId)

	try {
		const doc = await docRef.get();

		originalRoomId = doc.data().originalRoomId;
		owner = doc.data().owner;

		// update sharedrooms
		await docRef.update({
			sharedWith: firebase.firestore.FieldValue.arrayRemove(userEmail)
		})		
		// update original room
		await db.collection('users').doc(owner)
			.collection("warrooms")
	   		.doc(originalRoomId)
	   		.update({
	   			sharedWith: firebase.firestore.FieldValue.arrayRemove(userEmail)
	   		})		

	   	dispatch({
	    	type: "LEAVE_ROOM_SUCCESS",
			payload: roomId
	    })	

	    dispatch(successMessage(`leave room successfully.`))

	} catch (e) {
		dispatch(errorMessage(`error in leaving room: ${e.message}`))
	}
}

// pin or unpin a room
const updatePinRoom = (props) => async (dispatch) => {
	const { roomId, pin } = props;
	const userUid = userUidMaster();

	try {
	    await db.collection('users').doc(userUid)
		    .collection("warrooms")
		    .doc(roomId)
		    .update({
		  		pin: pin,
		  	})	 

		dispatch({
			type: "PIN_ROOM", 
			payload: {
				roomId: roomId,
				pin: pin
			}
		})	
	} catch (e) {
		dispatch(errorMessage(`failed to pin room: ${e.message}`))
	}
}


const roomActions = {
    createRoom, deleteRoom, editRoomTitle, 
    getRoomsMaster,
    getRoomDetails, resetRoomDetails, 
    getRoomDetailsPublic, getRoomDetailsShared, 
    createPublicLink, deletePublicLink, 
    addFriendToSharedRoom, removeFriendFromSharedRoom, leaveSharedRoom,
    updatePinRoom
}

export default roomActions;


// used at <App> , Navbar, SideBar, onboarding
// const getRooms = () => async (dispatch) => { 
// 	const userUid = userUidMaster();
// 	
// 	const tempRooms = [];
// 
// 	try {
// 		const rooms = await db
// 			.collection('users').doc(userUid)
// 			.collection('warrooms')
// 			.orderBy("createdAt", "desc")
// 			.get()	
// 
// 		await rooms.forEach(room => tempRooms.push({
// 			title: room.data().title,
// 			roomId: room.id,
// 			count: room.data().count,
// 			publicId: room.data().publicId,
// 			sharedWith: room.data().sharedWith,
// 			pin: room.data().pin
// 		}))	
// 
// 		dispatch({
// 			type: GET_ROOMS,
// 			payload: tempRooms
// 		})
// 
// 		dispatch(sendTokenToExtension(userUid, tempRooms)) // need dispatch?
// 
// 	} catch (e) {
// 		dispatch(errorMessage(`error in getting Rooms: ${e.message}`))
// 	}
// }

// use at <App>
// const getSharedRooms = () => async (dispatch) => {
// 
// 	const userEmail = userEmailMaster();
// 	
// 	const tempRooms = [];
// 
// 	try {
// 		const rooms = await db
// 			.collection('sharedrooms')
// 			.where("sharedWith", "array-contains", userEmail)
// 			.get()
// 
// 		await rooms.forEach(room => tempRooms.push({
// 			originalRoomId: room.data().originalRoomId,
// 			sharedRoomId: room.id,
// 			owner: room.data().owner,
// 			ownerName: room.data().ownerName,
// 			roomTitle: room.data().roomTitle,
// 		}))
// 
// 		dispatch({
// 			type: "GET_SHARED_ROOMS",
// 			payload: tempRooms
// 		})			
// 			
// 	} catch (e) {
// 		dispatch(errorMessage(`error in getting Shared Rooms: ${e.message}`))
// 	}
// }


// 
// 
//  (No longer in use) for Onboarding 
// const createRoomBatch = () => async (dispatch) => {
// 	const userUid = userUidMaster();
// 	
// 	const goal_1 = document.getElementById('goal-1').value
// 	const goal_2 = document.getElementById('goal-2').value;
// 	const goal_3 = document.getElementById('goal-3').value;
// 
// 	const goals = [goal_1, goal_2, goal_3]
// 	const roomIdHolders = []
//  
// 	const batch = db.batch();
// 
// 	try {
// 	  	await goals.forEach((roomTitle) => {
// 	  		const newDocRef = db.collection('users').doc(userUid)
// 	  		.collection('warrooms').doc()
// 
// 	  		if (roomTitle.length > 0) {
// 		  		roomIdHolders.push({
// 		  			roomId: newDocRef.id,
// 		  			title: roomTitle
// 		  		})
// 
// 			  	batch.set(newDocRef, {
// 			  		title: roomTitle,
// 			  		owner: userUid,
// 			  		roomId: newDocRef.id,
// 			  		count: firebase.firestore.FieldValue.increment(0),  
// 			        isActive: true,
// 			        createdAt: firebase.firestore.FieldValue.serverTimestamp()
// 			  	})    			
// 	  		}	
// 	  	})
// 
// 		await batch.commit()
// 
// 		roomIdHolders.forEach((room) => {
// 			dispatch({
// 		 		type: CREATE_ROOM,
// 		  		payload: {
// 			  		title: room.title,
// 			  		roomId: room.roomId,
// 			  		owner: userUid,
// 			  		isActive: true,
// 			  		createdAt: moment().format('Do MMMM YYYY'),
// 			  		count: 0
// 		  		}				
// 			})
// 		})		
// 
// 	} catch (e) {
// 		dispatch(errorMessage(`error in creating rooms. Please try again`))
// 	}
// }
// 
//  (No longer in use)  for onboarding if user submit without room (currently not in use)
// const createRoomBatchFail = () => (dispatch) => dispatch(errorMessage("please enter at least one goal."))
