import { 
	CREATE_POST, GET_NEW_LINK_POST, GET_NEW_LINK_POST_FAIL, DELETE_POST, UPDATE_POST, GET_POSTS_PUBLIC,
	GET_POSTS_PENDING, GET_POST_DETAILS, UPDATE_POST_DETAILS,
	CREATE_ROOM, GET_ROOMS, GET_ROOM_DETAILS, UPDATE_ROOM_COUNT_AND_ORDER,
	ADD_NOTE_IN_POST, DELETE_NOTE_IN_POST, EDIT_NOTE_IN_POST,
	SEARCH_POSTS, 
	EDIT_ROOM_TITLE, DELETE_ROOM, GET_ALL_POSTS
} from 'constants/constants';
import { postDetailsStructure } from 'actions/helperFunctions';
import { 
	filteredPosts, updateRoomCountRedux, 
	updatePost, updatePostShared, updatePostFiltered,
	noteInPostUpdatePostDetails, noteInPostUpdatePostDetailsShared, noteInPostsUpdatePosts, noteInPostsUpdateSharedPosts,
	getNewLinkPost, addRemoveFriendToSharedRoom, draggedPost,
	createPost, deletePost, deletePostFiltered,
	pinPosts, pinPostsShared, pinPostsFiltered, 
	labelPosts, labelPostsShared, minimizePosts, minimizePostsShared, 
	pinRooms, selectedPosts, deletePostsInGroup, movePostsInGroup, updateLabels, updateLabelsAfterDeletePost,
	labelAtPostDetails, deletePostsInArchive
} from './reducerHelperFunctions';


const initialUserState = {
	rooms: [],
	posts: [], // posts is an array of post document Object. used for ALL posts across all rooms
	publicPosts: [], // posts for public room - to avoid flicker if view is logged in
	sharedPosts: [], // for posts in a room shared to user
	filteredPosts: [], // for search
	filteredSharedPosts: [], // NEW: for search in shared rooms
	selectedPosts: [], // for group delete / move
	labels: new Map(),
	labelsInSharedRoom: new Map(),		// new: for filter in room
	labelsInPublicRoom: new Map(),		// new: for filter in room
	roomSelected: null, // for group delete / move to be able to update stats
	postDetails: postDetailsStructure({post: null}),
	roomDetails: {
		title: "",
		roomId: "",
		owner: "",
		ownerName: "",
		publicId: null,
		sharedWith: []		
	},
	isLoading: false,
	searchTerm: "",
	sharedRooms: [],
	draggedPost: null, 
}

const contentReducer = (state=initialUserState, action) => {

	switch(action.type) {
		case "STOP_LOADING": // sept 21 new
			return {
				...state,
				isLoading: false
			}	
		case GET_ALL_POSTS:
			return {
				...state,
				posts: action.payload.posts,
				labels: action.payload.labels,
				isLoading: false		 		
			}
		case GET_POSTS_PUBLIC:
			return {
				...state,
				publicPosts: action.payload.posts,
				isLoading: false,
				filteredPosts: action.payload.posts,
				labelsInPublicRoom: action.payload.labels, // TEST
			}	
		case "GET_POSTS_SHARED"	: 
			return {
				...state,
				sharedPosts: action.payload.posts,
				isLoading: false,
				filteredPosts: action.payload.posts,
				labelsInSharedRoom: action.payload.labels, // TEST
			}		
		case GET_POSTS_PENDING:
			return {
				...state,
				isLoading: true
			}				
		case GET_POST_DETAILS:
			return {
				...state,
				isLoading: false,
				postDetails: action.payload
			}				
		case CREATE_POST:  // for update without pulling posts from firebase again
			return {
				...state,
				posts: createPost(state, action.payload),
				isLoading: false,  // this is a hack for user only has one post, getAppPostSnapshot only will trigger this
			}	
		case "CREATE_POST_SHARED":  // for sharee creating post in shared room
			return {
				...state,
				sharedPosts: [action.payload, ...state.sharedPosts]
			}				
		case GET_NEW_LINK_POST:  // to replace newly created post over placeholder post
			return {
				...state,
				posts: getNewLinkPost(state, action.payload)
			}
		case "GET_NEW_LINK_POST_SHARED":  
			return {
				...state,
				sharedPosts: [action.payload, ...[...state.sharedPosts].filter(post => post.docId !== `pendingId+${action.payload.url}`)]	
			}			
		case GET_NEW_LINK_POST_FAIL:  // to remove pending post if scraping fail
			return {
				...state,
				posts: [...state.posts].filter(post => post.docId !== `pendingId+${action.payload}`)
			}		
		case "GET_NEW_LINK_POST_FAIL_SHARED":  // 
			return {
				...state,
				sharedPosts: [...state.sharedPosts].filter(post => post.docId !== `pendingId+${action.payload}`)
			}							
		case DELETE_POST:   
			return {
				...state,
				posts: deletePost(state, action.payload),
				filteredPosts: deletePostFiltered(state, action.payload)
			}	
		case "DELETE_POST_SHARED":   
			return {
				...state,
				sharedPosts: [...state.sharedPosts].filter(post => post.docId !== action.payload)
			}				
		case UPDATE_POST:   
			return {
				...state,
				posts: updatePost(state, action.payload),
				filteredPosts: updatePostFiltered(state, action.payload)
			}	
		case "UPDATE_POST_SHARED":   
			return {
				...state,
				sharedPosts: updatePostShared(state, action.payload)
			}				
		case UPDATE_POST_DETAILS:  
			return {
				...state,
				postDetails: {...state.postDetails, ...action.payload }
			}	
		case ADD_NOTE_IN_POST:  
			return {
				...state,
				postDetails: noteInPostUpdatePostDetails(state, action.payload, action.type),
				posts: noteInPostsUpdatePosts(state, action.payload, action.type)
			}		
		case "ADD_NOTE_IN_POST_SHARED":  
			return {
				...state,
				postDetails: noteInPostUpdatePostDetailsShared(state, action.payload, action.type),
				sharedPosts: noteInPostsUpdateSharedPosts(state, action.payload, action.type)
			}					
		case DELETE_NOTE_IN_POST:   
			return {
				...state,
				postDetails: noteInPostUpdatePostDetails(state, action.payload, action.type),
				posts: noteInPostsUpdatePosts(state, action.payload, action.type)
			}	
		case "DELETE_NOTE_IN_POST_SHARED":   
			return {
				...state,
				postDetails: noteInPostUpdatePostDetailsShared(state, action.payload, action.type),
				sharedPosts: noteInPostsUpdateSharedPosts(state, action.payload, action.type)
			}							
		case EDIT_NOTE_IN_POST: 
			return {
				...state,
				postDetails: noteInPostUpdatePostDetails(state, action.payload, action.type),
				posts: noteInPostsUpdatePosts(state, action.payload, action.type)
			}		
		case "EDIT_NOTE_IN_POST_SHARED": 
			return {
				...state,
				postDetails: noteInPostUpdatePostDetailsShared(state, action.payload, action.type),
				sharedPosts: noteInPostsUpdateSharedPosts(state, action.payload, action.type)
			}					
		case CREATE_ROOM:
			return {
				...state,
				rooms: [action.payload, ...state.rooms]
			}																														
		case GET_ROOMS:
			return {
				...state,
				rooms: action.payload
			}	
		case GET_ROOM_DETAILS:
			return {
				...state,
				roomDetails: action.payload,
				isLoading: false,				
			}	
		case DELETE_ROOM:   
			return {
				...state,
				rooms: action.payload
				// rooms: [...state.rooms].filter(room => room.roomId !== action.payload)
			}	
		case EDIT_ROOM_TITLE:   
			return {
				...state,
				rooms: action.payload.rooms,
				roomDetails: {...state.roomDetails, ...{title: action.payload.newRoomTitle} }
			}								
		case UPDATE_ROOM_COUNT_AND_ORDER:   
			return {
				...state,
				rooms: updateRoomCountRedux(state, action.payload.room, action.payload.increment)
			}																					
		case SEARCH_POSTS:
			return {
				...state,
				searchTerm: action.payload,
				filteredPosts: filteredPosts(state, action.payload),
				// isLoading: false 
			}		
		case "GET_SHARED_ROOMS":
			return {
				...state,
				sharedRooms: action.payload,
			}		
		case "ADD_FRIEND_TO_SHARED_ROOM":
			return {
				...state,
				roomDetails: addRemoveFriendToSharedRoom(state, action.payload, action.type),
			}		
		case "REMOVE_FRIEND_TO_SHARED_ROOM":
			return {
				...state,
				roomDetails: addRemoveFriendToSharedRoom(state, action.payload, action.type),
			}		
		case "LEAVE_ROOM_SUCCESS":
			return {
				...state,
				sharedRooms: [...state.sharedRooms].filter(each => each.sharedRoomId !== action.payload)
			}			
		case "SET_DRAGGED_POST":
			return {
				...state,
				draggedPost: action.payload
			}			
		case "RECEIVING_DRAGGED_POST":
			return {
				...state,
				[action.payload.owner ? "sharedPosts" : "posts"]: draggedPost(state, action.payload)
				// ^ computer property ES6
			}		
		case "PIN_POST":
			return {
				...state,
				posts: pinPosts(state, action.payload),
				filteredPosts: pinPostsFiltered(state, action.payload)
			}		
		case "PIN_POST_SHARED":
			return {
				...state,
				sharedPosts: pinPostsShared(state, action.payload)
			}						
		case "CREATE_LABEL":
			return {
				...state,
				posts: labelPosts(state, action.payload, action.type),
				labels: updateLabels(state, action.payload, action.type)
			}			
		case "CREATE_LABEL_SHARED":
			return {
				...state,
				sharedPosts: labelPostsShared(state, action.payload, action.type),
				labelsInSharedRoom: updateLabels(state, action.payload, action.type)	
			}					
		case "REMOVE_LABEL":
			return {
				...state,
				posts: labelPosts(state, action.payload, action.type),
				labels: updateLabels(state, action.payload, action.type)			
			}		
		case "REMOVE_LABEL_SHARED":
			return {
				...state,
				sharedPosts: labelPostsShared(state, action.payload, action.type),
				labelsInSharedRoom: updateLabels(state, action.payload, action.type)	
			}	
		case "CREATE_LABEL_POST_DETAILS":
			return {
				...state,
				postDetails: labelAtPostDetails(state, action.payload, action.type),
			}						
		case "REMOVE_LABEL_POST_DETAILS":
			return {
				...state,
				postDetails: labelAtPostDetails(state, action.payload, action.type),
			}						
		case "UPDATE_LABELS_AFTER_DELETE_POST":
			return {
				...state,
				labels: updateLabelsAfterDeletePost(state, action.payload)// new				
			}				
		case "MINIMIZE_POST":
			return {
				...state,
				posts: minimizePosts(state, action.payload)
			}			
		case "MINIMIZE_POST_SHARED":
			return {
				...state,
				posts: minimizePostsShared(state, action.payload)
			}	
		case "PIN_ROOM":
			return {
				...state,
				rooms: pinRooms(state, action.payload),
			}	
		case "SELECT_POST_FOR_GROUP_DELETE_OR_MOVE":
			return {
				...state,
				selectedPosts: selectedPosts(state, action.payload),
				roomSelected: action.payload.post.room
			}		
		case "DELETE_POSTS_IN_GROUP":
			return {
				...state,
				posts: deletePostsInGroup(state, "posts"),
				filteredPosts: deletePostsInGroup(state, "filteredPosts"),
				selectedPosts: []
			}			
		case "CLEAR_POSTS_IN_GROUP":
			return {
				...state,
				selectedPosts: []
			}		
		case "MOVE_POSTS_IN_GROUP":
			return {
				...state,
				posts: movePostsInGroup(state, action.payload),
				selectedPosts: []
			}				
		case "DELETE_POSTS_IN_ARCHIVE":
			return {
				...state,
				posts: deletePostsInArchive(state),
			}							

		default: 
			return state; 
	}	
}

export default contentReducer;