
// update this and Firebase when deploy
export const APP_VERSION_NUMBER = "1.5.09";
export const SEARCH_MINIMUM = 2;
// increase both below when there's new news and updates
export const GLOBAL_NEWS_VERSION = 24;
export const LOCAL_NEWS_VERSION = 23;


const today = new Date();	
const ARCHIVEPERIOD = 604800000; // 14 days
export const timestampToShowInDesk = today - ARCHIVEPERIOD;

export const GET_APP_VERSION_NUMBER = "GET_APP_VERSION_NUMBER"
export const SET_USER_AUTH = "SET_USER_AUTH";
export const GET_USER_PROFILE = "GET_USER_PROFILE";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNUP_LOADING = "SIGNUP_LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const USER_SIGNOUT = "USER_SIGNOUT";

export const CREATE_ROOM = "CREATE_ROOM";
export const GET_ROOMS = "GET_ROOMS";
export const DELETE_ROOM = "DELETE_ROOM";
export const EDIT_ROOM_TITLE = "EDIT_ROOM_TITLE";
export const GET_ROOM_DETAILS = "GET_ROOM_DETAILS";
// export const UPDATE_EDIT_ROOM_VIEW = "UPDATE_EDIT_ROOM_VIEW";
export const UPDATE_ROOM_COUNT_AND_ORDER = "UPDATE_ROOM_COUNT_AND_ORDER";

export const CREATE_POST = "CREATE_POST";
export const GET_NEW_LINK_POST = "GET_NEW_LINK_POST";
export const GET_NEW_LINK_POST_FAIL = "GET_NEW_LINK_POST_FAIL";
export const DELETE_POST = "DELETE_POST";
export const UPDATE_POST = "UPDATE_POST";

export const GET_ALL_POSTS = "GET_ALL_POSTS";
export const GET_POSTS_PUBLIC = "GET_POSTS_PUBLIC";
// export const GET_POSTS = "GET_POSTS";
export const GET_POST_DETAILS = "GET_POST_DETAILS";
export const UPDATE_POST_DETAILS = "UPDATE_POST_DETAILS";

export const SEARCH_POSTS ="SEARCH_POSTS"

export const ADD_NOTE_IN_POST = "ADD_NOTE_IN_POST";
export const DELETE_NOTE_IN_POST = "DELETE_NOTE_IN_POST";
export const EDIT_NOTE_IN_POST = "EDIT_NOTE_IN_POST";

export const GET_POSTS_PENDING = "GET_POSTS_PENDING";

export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const CLOSE_ALERT = "CLOSE_ALERT";

export const CHECK_ADMIN = "CHECK_ADMIN";
export const UPDATE_LOCAL_NEWS_VERSION = "UPDATE_LOCAL_NEWS_VERSION";

export const chromeExtId = 'eobhhbghffhlbfbpngljpmakaflgbddi';  


// export const EDIT_POST = "EDIT_POST";