import { 
	ERROR_MESSAGE, SUCCESS_MESSAGE, CLOSE_ALERT,
	SIGNUP_FAIL
} from 'constants/constants';

const initialUserState = {
	alert: null,
	alertType: null 
}
 
const alertsReducer = (state=initialUserState, action) => {
	switch(action.type) {
		case CLOSE_ALERT:
			return {
				...state,
				alert: action.payload,
				alertType: action.payload
			}			
		case ERROR_MESSAGE:
			return {
				...state,
				alert: action.payload.message,
				alertType: action.payload.type,
			}											
		case SUCCESS_MESSAGE:
			return {
				...state,
				alert: action.payload.message,
				alertType: action.payload.type,
			}
		case SIGNUP_FAIL:
			return {
				...state,
				alert: action.payload.message,
				alertType: action.payload.type,
			}				
		default: 
			return state; 
	}	
}

export default alertsReducer;