import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { useState } from 'react';
import { landingHeroSrc, landingIntroOne, landingIntroTwo, landingIntroThree, arrowImage } from 'assets/landingAssets';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './landingv2.css';

const LandingV2 = () => {
 	const [imageLoaded, setImageLoaded] = useState(false);	

	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null

 	if (user) return <Redirect to='/home' />  // if logged in, redirect

	return(
		<main>
			<section className="landing-hero-section">	
				<div>
					<h1 className="landing-hero-header">Your Personal Resource Space</h1>
					<h6 className="landing-hero-subtitle">Build your dedicated libraries around your projects, hobbies, & interests</h6>
					
					{/*<h6 className="landing-hero-subtitle">Save and organize links, notes, images</h6> */}
					<button className="main-button main-cta-button" style={{ marginRight: "20px"}}><Link to="/signup" className="dont-link-me">Start Building for Free</Link></button>

				</div>

				<img 
					className="landing-hero-image" 
					src={landingHeroSrc}
					alt='hero-screenshot' 
					onLoad={() => setImageLoaded(true)}
					style={imageLoaded ? {display: "block"} : {display: "none"}  } 
				/>		

				<div className="landing-hero-image" style={!imageLoaded ? {display: "block" } : {display: "none"}  } >
					<SkeletonTheme color="var(--skeleton-bg)" highlightColor="var(--skeleton-highlight)">
					 	<Skeleton count={1} height={500} /> 
					</SkeletonTheme>					
				</div>
			</section>		

			<h2 className="landing-how-osoji-works" style={{ marginBottom: "40px"}}>How Osoji Works</h2>

			<section className="landing-intro-section-A">
				<img className="landing-intro-image" src={landingIntroOne} alt='screenshot-1' loading="lazy" />	

				<div className="landing-intro-text-group">
					<h2>Save anything, from anywhere</h2>
					<h4>Save useful articles, text snippets, videos, emails, recipes with 1-click using <span><Link to="/extensions">Osoji browser extensions</Link></span>.
					</h4>

					<Link to="/how-it-works" className="landing-intro-learn-more">
						LEARN MORE
						<span>{arrowImage}</span>
					</Link>
				</div>
			</section>

			<section className="landing-intro-section-A landing-intro-section-B">
				<div className="landing-intro-text-group">
					<h2>Organize with ease</h2>
					<h4>Add structure and organizational hierarchy to build up your own knowledge base for your projects, hobbies, interests. 
					</h4>

					<Link to="/how-it-works" className="landing-intro-learn-more">
						LEARN MORE
						<span>{arrowImage}</span>
					</Link>
				</div>

				<img className="landing-intro-image" src={landingIntroTwo} alt='screenshot-2' loading="lazy" />					
			</section>

			<section className="landing-intro-section-A">
				<img className="landing-intro-image" src={landingIntroThree} alt='screenshot-3' loading="lazy" />	

				<div className="landing-intro-text-group">
					<h2>Collaborate</h2>
					<h4>Invite friends and colleagues to contribute to a Room together for projects, trip planning, shopping lists.</h4>

					<Link to="/how-it-works" className="landing-intro-learn-more">
						LEARN MORE
						<span>{arrowImage}</span>
					</Link>
				</div>
			</section>
			
			<section className="landing-outtro-section">
				<h4>Whether you want to get organized, keep your personal life on track, or boost work productivity</h4>
				<button className="main-button" style={{width: "200px"}}><Link to="/signup" className="dont-link-me">Try Osoji for Free</Link></button>			
	
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
					<p>Need some ideas? 👉 check out some <span><Link to="/inspirations">public rooms</Link></span> from our users</p>
					<Link to="/inspirations"><img style={{ marginLeft: "10px"}}className="landing-testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/errol-3.png`} alt='testimonial-1' /></Link>
					<Link to="/inspirations"><img style={{ marginLeft: "-10px"}} className="landing-testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/duc.jpeg`} alt='testimonial-2' /></Link>
					<Link to="/inspirations"><img style={{ marginLeft: "-10px"}} className="landing-testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/emma-edwards.png`} alt='testimonial-3' /></Link>
					<Link to="/inspirations"><img style={{ marginLeft: "-10px"}} className="landing-testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/aris.jpeg`} alt='testimonial-4' /></Link>
				</div>
			</section>

		</main>
	)
}

export default LandingV2;	