import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { Link } from 'react-router-dom';
import "../sidebar.css";


const LabelsAtSideBar = () => {

	const dispatch = useDispatch();

	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const labels = useSelector(state => state.contentReducer.labels);
	// const sharedRooms = useSelector(state => state.contentReducer.sharedRooms);

	const [labelsSectionCollapsed, setLabelsSectionCollapsed] = useState(true);
	const labelIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/labelsidebar.svg` : `${process.env.PUBLIC_URL}/images/icons/labelsidebar-dark.svg`;

	// in Map  { "tech": 2, "design": 4 }
	const labelsOnMenu = [...labels.keys()]
		.sort((a,b) => labels.get(b) - labels.get(a))
		.map((key) => (
			<Link 
				to="/home" 
				className="dont-link-me"
				onClick={() => {
					dispatch(allActions.contentActions.searchPosts(`label:${key}`) )
				}} 
				key={key} 
			>
				<div className="sidebar-dropdown-item">
					<div className="sidebar-dropdown-item-text">{key}</div>	
					<div className="sidebar-dropdown-item-count">{labels.get(key)}</div>					
				</div>	
			</Link>	
		))


	return (
        <div className="rooms-sidebar-section">

        	<div className="rooms-sidebar-header">
        		<h6 onClick={() => setLabelsSectionCollapsed(!labelsSectionCollapsed)}>
        			<span><img src={labelIcon} alt="labels" style={{ width: "20px", marginRight: "10px", marginTop: "-4px" }} /></span>
        			Labels
        		</h6>
        	</div>

            {!labelsSectionCollapsed &&
	           labelsOnMenu
            }              
        </div>
	)
}

export default LabelsAtSideBar;