import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from "react-responsive";
import Toggle from "./Toggle/Toggle";
import sun from './sun.png';
import moon from './moon.png';
import ReactTooltip from "react-tooltip";
import allActions from "actions";

const DarkModeToggle = ({ atCollapsedSideBar }) => {
	const dispatch = useDispatch();
	const systemPrefersDark = useMediaQuery(	// magic that gets prefers-color-scheme from system and updates automatically
		{
		  query: "(prefers-color-scheme: dark)"
		},
		undefined,
		prefersDark => {
		  setIsDark(prefersDark);
		}
	);

	 // is there preference at local storage
	const localPreference = localStorage.getItem('osojiTheme');
	// if there is preference, yes if dark, no if light; if no preference, return systemPreference
	// we need localPreference === "dark"  because isDark is a boolean
	const initialPreferenceState = localPreference ? (localPreference === "dark") : systemPrefersDark 
	const [isDark, setIsDark] = useState(initialPreferenceState);

	const handleToggle = (e) => {
		setIsDark(e.target.checked);

		if (e.target.checked) {
			localStorage.setItem('osojiTheme', 'dark');
		} else {
			localStorage.setItem('osojiTheme', 'light');
		}
	}

	useEffect(() => {
        if (isDark) {
        	document.body.classList.add("dark");
        	dispatch(allActions.userActions.setColorTheme("dark"));
        } else {
        	document.body.classList.remove("dark");
        	dispatch(allActions.userActions.setColorTheme("light"));
        }
	}, [isDark, dispatch])

	if (atCollapsedSideBar) {
		return (
			<>
				<img
					src={isDark? moon : sun} 
					style={{ width: "20px", marginTop: "25px", cursor: "pointer" }}
					onClick={() => {
				        if (isDark) {
				        	localStorage.setItem('osojiTheme', 'light');
				        	setIsDark(false)
				        }	
				        else {
				        	localStorage.setItem('osojiTheme', 'dark');
				        	setIsDark(true)
				        } 			
					}}
					alt="dark-mode"
					data-tip 
					data-for="darkmode-toggle-icon-side-menu"
				/>

				<ReactTooltip id="darkmode-toggle-icon-side-menu" place="right" effect="float"
					offset={{top: 0, right: 20}}		
				>
	          		{isDark ? "Toggle Light Mode" : "Toggle Dark Mode"}
	        	</ReactTooltip>  	
			</>
		)
	}

	return (
		<Toggle
			icons={{
				checked: (
					<img
					  src={moon}
					  width="16"
					  height="16"
					  alt="presentation"
					  style={{ pointerEvents: 'none' }}
					/>
				),
				unchecked: (
					<img
					  src={sun}
					  width="16"
					  height="16"
					  alt="presentation"
					  style={{ pointerEvents: 'none' }}
					/>
				),
			}}
			checked={isDark}
			onChange={handleToggle}			
			// checked={this.state.theme === 'dark'}
			// onChange={e =>
			//   window.__setPreferredTheme(
			//     e.target.checked ? 'dark' : 'light'
			//   )
			// }
		/>		
	)
}



export default DarkModeToggle;