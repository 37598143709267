import {Helmet} from "react-helmet";

const SEO = ({ title, description, image }) => {

	const newTitle = title || "Osoji - Your Personal Resource Space"
	const newDescription = description || "Build your personal resource space. Organize links, notes, and images."
	const keywords = "Organize Ideas, Insights, Second Brain, Note, Personal Knowledge Management, Declutter, Unclutter, growth"
	const newImage = image || `https://osoji.io/images/about-image-osoji.webp`

  // note: this component is mostly used for GA purpose only. Since ReactHelmet can't work with CRA, needs to work with SSR
  // we can delete all the og and twitter meta here..

	return(
		<Helmet>
            <title>{newTitle}</title>

            <meta name="description" content={newDescription} />
            <meta name="keywords"content={keywords} />
            
            <meta property="og:title" content={newTitle} />
            <meta property="og:description" content={newDescription} />
           	<meta property="og:image" content={newImage} />
           	<meta property="og:type" content="website" />	

           	<meta name="twitter:title" content={newTitle} />
           	<meta name="twitter:description" content={newDescription} />
           	<meta name="twitter:image" content={newImage} />
            <meta name="twitter:card" content="summary_large_image" /> 

            <script async src="https://www.googletagmanager.com/gtag/js?id=G-7CG4GQDS0H"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('set', {cookie_flags: 'SameSite=None;Secure'});
                gtag('config', 'G-7CG4GQDS0H');
              `}
            </script>
		</Helmet>
	)
}

export default SEO;