import firebase, { db } from 'config/Firebase';
import moment from 'moment';
import {
    errorMessage, userUidMaster
} from './helperFunctions'
import { 
  CHECK_ADMIN, 
} from 'constants/constants';

// const currentYear = "y2021" // hardcode for now 
const currentYear = `y${moment().format('YYYY')}`
const currentMonth = moment().format('MMMM');    


const checkAdmin = () => async (dispatch) => {
    const userUid = userUidMaster();

    try {
        dispatch({ type: "ADMIN_LOADING" })  

        const userDoc = await db.collection('users').doc(userUid).get();

        dispatch({
            type: CHECK_ADMIN,  
            payload: userDoc.data().isAdmin ? true : false // default is null, but we need "false" to redirect
        })  

    } catch (e) {
        dispatch(errorMessage(`error: ${e.message}`))
    }
}    

// used in Admin only. Used For monthly digest.
const sendMonthlyEmail = () => async (dispatch) => {
    try {
        await firebase.functions().httpsCallable('manualMonthlyEmail')()
        console.log("executed monthly")
    } catch (e) {
        dispatch(errorMessage(`error: ${e.message}`))
    }
}

// used in secrettestonly
const adminGetSubscriberDetails = () => async (dispatch) => {
    const usersList = [];

    dispatch({
          type: "ADMIN_LOADING",
    })

    const countContentAddedYear = (stats) => {
        if (!stats) return 0    // guard clause
        if (!stats[currentYear]) return 0       

        let count = 0;

        Object.values(stats[currentYear]).forEach((month) => {     // hardcode year for now
            month.PostAdded && Object.values(month.PostAdded).forEach((roomCount) => count = count + roomCount)
        })    

        return count;    
    }    
    const countContentAddedMonth = (stats) => {
        if (!stats) return 0;   // guard clause
        if (!stats[currentYear]) return 0       
        if (!stats[currentYear][currentMonth]) return 0            
        if (!stats[currentYear][currentMonth]["PostAdded"]) return 0

        let count = 0;
        // count values of each room in PostAdded
        Object.values(stats[currentYear][currentMonth]["PostAdded"]).forEach((roomCount) => count = count + roomCount)    

        return count;    
    }

    const notesInPostAddedYear = (stats) => {
        if (!stats) return 0    // guard clause
        if (!stats[currentYear]) return 0       

        let count = 0;

        Object.values(stats[currentYear]).forEach((month) => {     // hardcode year for now
            month.InsightAdded && Object.values(month.InsightAdded).forEach((roomCount) => count = count + roomCount)
        })    

        return count;    
    }    
    const notesInPostAddedMonth = (stats) => {
        if (!stats) return 0;   // guard clause
        if (!stats[currentYear]) return 0       
        if (!stats[currentYear][currentMonth]) return 0            
        if (!stats[currentYear][currentMonth]["InsightAdded"]) return 0

        let count = 0;
        // count values of each room in PostAdded
        Object.values(stats[currentYear][currentMonth]["InsightAdded"]).forEach((roomCount) => count = count + roomCount)    

        return count;    
    }

    try {
        const users = await db.collection('users').get()

        await users.forEach((user) => {
          usersList.push({
            email: user.data().email,
            name: user.data().name,
            uid: user.id,
            rooms: [],
            join: moment(user.data().joinTimestamp.toDate()).format('Do MMMM YYYY'),
            lastActive: moment(user.data().lastDeleteTimestamp.toDate()).format('Do MMMM YYYY'),
            joinTimestamp: user.data().joinTimestamp.toDate(),
            lastDeleteTimestamp: user.data().lastDeleteTimestamp.toDate(),
            rec1: user.data().recommendations[0],
            rec2: user.data().recommendations[1],
            rec3: user.data().recommendations[2],
            isSubscribedMonthly: (user.data().isSubscribedMonthly ? "yes" : "no"),
            // isSubscribedWeekly: (user.data().isSubscribedWeekly ? "yes" : "no"),
            postAddedThisYear: countContentAddedYear(user.data().stats),    // posts added in 2021
            postAddedThisMonth: countContentAddedMonth(user.data().stats),
            notesInPostAddedThisMonth: notesInPostAddedMonth(user.data().stats),
            notesInPostAddedThisYear: notesInPostAddedYear(user.data().stats)
            // stats: user.data().stats, // no need other stats for now            
          })
        })

        dispatch({
          type: "GET_SUBSCRIBED_USERS",
          payload: usersList
        })
    } catch (e) {
        dispatch(errorMessage(`failed to get users: ${e.message}`))
    }
}

const updateUserRecommendations = (uid) => (dispatch) => {
    const rec1 = document.getElementById(`$${uid}+rec1`).value;  
    const rec2 = document.getElementById(`$${uid}+rec2`).value;  
    const rec3 = document.getElementById(`$${uid}+rec3`).value;  

    const updatedUserObject = {
        recommendations: [rec1, rec2, rec3],
    }

    db.collection('users').doc(uid)
    .update(updatedUserObject)
    .then(dispatch({
        type: "UPDATE_USER_RECOMMENDATIONS", 
        payload: {
           recommendations: [rec1, rec2, rec3],
           uid: uid 
        }
    }))
    .catch(function(e) {
        dispatch(errorMessage(`failed to update: ${e.message}`))
    })    
}

const sortUsersByJoin = (direction) => (dispatch) => {
    dispatch({
        type: "SORT_USER_BY_JOIN",
        payload: direction
    })    
}

const sortUsersByActive = (direction) => (dispatch) => {
    dispatch({
        type: "SORT_USER_BY_ACTIVE",
        payload: direction
    })    
}

const sortUsersByNumberOfPosts = (period, direction) => (dispatch) => {
    dispatch({
        type: "SORT_USER_BY_NUMBER_OF_POSTS",
        payload: {
            period: period,
            direction: direction
        }       
    })    
}

// master recommendations;  NOT individual user
const getMonthlyRecommendations = () => async (dispatch) => {
    dispatch({
        type: "ADMIN_LOADING",
    })

    const recList = [];

    try {
        const recommendations = await db.collection('recommendations')
            .orderBy("order")
            .get()

        await recommendations.forEach((rec) => {
            recList.push({
                url: rec.data().url,
                title: rec.data().title,
                desc: rec.data().desc,   
                order: rec.data().order,  
                image: rec.data().image              
            }) 
        })

        dispatch({
            type: "ADMIN_GET_MONTHLY_RECOMMENDATIONS",
            payload: recList
        })
    } catch (e) {
        dispatch(errorMessage(`failed to get recommendations: ${e.message}`))
    }
}

const updateMonthlyRecommendation = ({ index }) => async (dispatch) => {
    const userUid = userUidMaster();
    const recommendationIndex = index.toString();
    const docId = document.getElementById(`$${index}+docId`).value; 
    // get from yinhow's post id

    try {
        const article = await db.collection("users").doc(userUid)
            .collection("posts").doc(docId)
            .get() // desc, title, url

        const recommendationObj = {
                url: article.data().url,
                title: article.data().title,
                desc: article.data().desc,
                image: article.data().thumbnail
            }

        await db.collection("recommendations").doc(recommendationIndex)
            .update(recommendationObj)

        dispatch({
            type: "ADMIN_UPDATE_MONTHLY_RECOMMENDATIONS",
            payload: {
                recommendationObj: recommendationObj,
                index: index
            }
        })

    } catch (e) {
        dispatch(errorMessage(`failed to update recommendations: ${e.message}`))
    }
}

const adminActions = {
    checkAdmin, sendMonthlyEmail, adminGetSubscriberDetails, updateUserRecommendations,
    sortUsersByJoin, sortUsersByActive, sortUsersByNumberOfPosts,
    getMonthlyRecommendations, updateMonthlyRecommendation
}

export default adminActions;

// used in Admin only. For Testing.
// const testWeeklyEmail = () => (dispatch) => {
//     firebase.functions().httpsCallable('manualWeeklyEmail')()
//     .then(function(){
//         console.log("executed weekly")
//     })
// }