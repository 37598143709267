import { Spinner } from 'reactstrap';

// used in App, Signup, Login
const LoaderFullScreen = () => {
	return (
		<div style={{ height: "80vh",position: "relative" }}>
			<Spinner color="warning" style={{ top: "30%", position: "absolute" }}/>
		</div>
	)
}

export default LoaderFullScreen;