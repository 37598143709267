import { useState } from "react";

// edit post in PostListing
export const useEditPostModal = () => {
	const [postUnderEdit, setPostUnderEdit] = useState(null);
	const [modalEdit, setModalEdit] = useState(false);

	const toggleEdit = (post) => {
		if (!modalEdit) {
			setModalEdit(true)
			setPostUnderEdit(post)
		} else {
			setModalEdit(false)
			setPostUnderEdit(null)
		}
	}	

	return { postUnderEdit, modalEdit, toggleEdit }
}

// show full note text in PostListing, PostDetailsPage (Note in Post)
export const useFullTextModal = () => {
	const [textView, setTextView] = useState(null);	
	const [modalTextView, setModalTextView] = useState(false);

	const toggleShowFullText = ({ desc, isEmail }) => {
		if (!modalTextView) {
			setModalTextView(true)
			setTextView({
				desc: desc, 
				isEmail: isEmail
			})
		} else {
			setModalTextView(false)
			setTextView(null)
		}
	}	

	return { textView, modalTextView, toggleShowFullText }
}

// show full imagepost in PostListing
export const useFullImageModal = () => {
	const [imageFullScreen, setImageFullScreen] = useState(null);
	const [modalImageFullScreen, setModalImageFullScreen] = useState(false);

	const toggleShowFullImage = (post) => {
		if (!modalImageFullScreen) {
			setModalImageFullScreen(true)
			setImageFullScreen(post.url) // different!!!
		} else {
			setModalImageFullScreen(false)
			setImageFullScreen(null)
		}
	}	

	return { imageFullScreen, modalImageFullScreen, toggleShowFullImage }
}

// change room in PostListing  +  group change room in FixedNavbar...
export const useChangeRoomModal = () => {
	const [postForChangeRoom, setPostForChangeRoom] = useState(null);
	const [modalChangeRoom, setModalChangeRoom] = useState(false);	

	const toggleChangeRoom = (post) => {
		if (!modalChangeRoom) {
			setModalChangeRoom(true)
			setPostForChangeRoom(post) // different!!!
		} else {
			setModalChangeRoom(false)
			setPostForChangeRoom(null)
		}
	}		

	return { postForChangeRoom, modalChangeRoom, toggleChangeRoom }
}


// delete post in PostListing
export const useDeleteConfirmModal = () => {
	const [postToDelete, setPostToDelete] = useState(null);
	const [modalDeletePost, setModalDeletePost] = useState(false);

	const toggleDeletePost = (post) => {
		if (!modalDeletePost) {
			setModalDeletePost(true)
			setPostToDelete(post) 
		} else {
			setModalDeletePost(false)
			setPostToDelete(null)
		}
	}	

	return { postToDelete, modalDeletePost, toggleDeletePost }
}

// edit room in WarroomDetailsPage & WarroomSectionNew
export const useEditRoomModal = () => {
	const [editRoomModal, setEditRoomModal] = useState(false);
	const [roomUnderEdit, setRoomUnderEdit] = useState(null);
	const toggleEditRoom = (room) => {
		if (editRoomModal) {
			setEditRoomModal(false)
			setRoomUnderEdit(null)
		} else {
			setEditRoomModal(true)
			setRoomUnderEdit(room)
		}
	}

	return { roomUnderEdit, editRoomModal, toggleEditRoom }
}



// to confirm LEAVE room in SharedRoomsSection, WarroomDetailsPage
export const useLeaveConfirmModal = () => {
	const [leaveConfirmModal, setLeaveConfirmModal] = useState(false);
	const [leaveWhichRoom, setLeaveWhichRoom] = useState(null);
	const toggleLeave = (sharedRoomId) => {
		if (leaveConfirmModal) {
			setLeaveConfirmModal(false)
			setLeaveWhichRoom(null)
		} else {
			setLeaveConfirmModal(true)
			setLeaveWhichRoom(sharedRoomId)
		}
	}

	return {leaveWhichRoom, leaveConfirmModal, toggleLeave }
}

// Add Post at AddPostCard
export const useAddPostModal = () => {
	const [postToAdd, setPostToAdd] = useState(null);
	const [modalAdd, setModalAdd] = useState(false);
	const toggleAdd = (post) => {
		if (!modalAdd) {
			setModalAdd(true)
			setPostToAdd(post)
		} else {
			setModalAdd(false)
			setPostToAdd(null)
		}
	}	

	return { modalAdd, postToAdd, toggleAdd }	
}


export const useYouTubeFullScreenModal = () => {
	const [videoUrl, setVideoUrl] = useState(null);
	const [modalYouTubeFullScreen, setModalYouTubeFullScreen] = useState(false);

	const toggleYouTubeFullScreen = (videoUrl) => {
		if (!modalYouTubeFullScreen) {
			setModalYouTubeFullScreen(true)
			setVideoUrl(videoUrl)
		} else {
			setModalYouTubeFullScreen(false)
			setVideoUrl(null)
		}
	}	

	return { videoUrl, modalYouTubeFullScreen, toggleYouTubeFullScreen }
}