import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import allActions from "actions";
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import "../sidebar.css";


const UserMenuAtSideBar = () => {

	const dispatch = useDispatch();
	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const userFromState = useSelector(state => state.userReducer.user);	
	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null

	const profileIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/profile${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='profile' />; 
	const guideIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/guide${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='guide' />;
	const helpIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/help${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='help' />;
	const contactIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/contact${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='contact' />;
	const logoutIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/logout${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='logout' />;
	const extensionIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/extension${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='extension' />;

	const userIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/user.svg` : `${process.env.PUBLIC_URL}/images/icons/user-dark.svg`;

	const userNameCurtailed = (name) => {
		if (name.length < 23) return name;

		return name.slice(0, 23) + "..."
	}

	return (
        <UncontrolledDropdown style={{ width: "100%"}}>
            <DropdownToggle nav style={{ padding: "0px", width: "100%"}}>
            	<div className="dont-link-me sidebar-user-group">
	               	<img src={userIcon} alt='user-icon' className="sidebar-user-icon" />
	               	<h6>{user?.displayName && userNameCurtailed(user.displayName)}</h6>	
               	</div>
            </DropdownToggle>

            <DropdownMenu className="sidebar-user-dropdown-menu" right>
                
                <Link className="dont-link-me" to="/profile">
	                <DropdownItem className="sidebar-user-dropdown-item">
						{profileIcon} Profile    	                  
	                </DropdownItem>
	            </Link> 			
	            	            
	            <a className="dont-link-me" href="https://www.notion.so/Osoji-User-Guide-c107875c42de4e50aacc9058536cf71a" target="_blank" rel="noreferrer">      
	                <DropdownItem className="sidebar-user-dropdown-item">
						{guideIcon} Guide    	                  
	                </DropdownItem>	
	            </a> 

	            <Link className="dont-link-me" to="/extensions">      
	                <DropdownItem className="sidebar-user-dropdown-item">
						{extensionIcon} Extensions   	                  
	                </DropdownItem>	
	            </Link> 		

	            <Link className="dont-link-me" to="/faq">      
	                <DropdownItem className="sidebar-user-dropdown-item">
						{helpIcon} FAQ    	                  
	                </DropdownItem>	
	            </Link> 	
        
	            <Link className="dont-link-me" to="/contact">      
	                <DropdownItem className="sidebar-user-dropdown-item">
						{contactIcon} Contact / Help   	                  
	                </DropdownItem>	
	            </Link> 	

	            <div onClick={() => dispatch(allActions.userActions.userLogout())}>      					                
	                <DropdownItem className="sidebar-user-dropdown-item">
		          		{logoutIcon} Logout     	                  
	                </DropdownItem>
	            </div>     
            </DropdownMenu>
        </UncontrolledDropdown>
	)
}

export default UserMenuAtSideBar;