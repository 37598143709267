import React, { useEffect, Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import firebase from 'config/Firebase';
import allActions from 'actions';
import { chromeExtId } from 'constants/constants';
import './App.css';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import PublicRoute from 'components/PublicRoute/PublicRoute';
import SEO from 'components/SEO/SEO';
import Landing from 'pages/Landing/Landing';
import LandingV2 from 'pages/LandingV2/LandingV2';
import LoaderFullScreen from 'components/LoaderFullScreen/LoaderFullScreen';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
/*global chrome*/

const Signup = lazy(() => import('./pages/Signup/Signup'));
const Login = lazy(() => import('./pages/Login/Login'));
const About = lazy(() => import('./pages/About/About'));
const HowItWorks = lazy(() => import('./pages/HowItWorks/HowItWorks'));
const Home = lazy(() => import('./pages/Home/Home'));
const WarroomDetailsPage = lazy(() => import('./pages/WarroomDetailsPage/WarroomDetailsPage'));
const PublicWarroomDetailsPage = lazy(() => import('./pages/PublicWarroomDetailsPage/PublicWarroomDetailsPage'));
const PostDetailsPage = lazy(() => import('./pages/PostDetailsPage/PostDetailsPage'));
const PublicPostDetailsPage = lazy(() => import('./pages/PublicPostDetailsPage/PublicPostDetailsPage'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const ArchiveFolder = lazy(() => import('./pages/ArchiveFolder/ArchiveFolder'));
const RoomWithAllPosts = lazy(() => import('./pages/RoomWithAllPosts/RoomWithAllPosts'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));
const Terms = lazy(() => import('./pages/Terms/Terms'));
const Privacy = lazy(() => import('./pages/Privacy/Privacy'));
const Faq = lazy(() => import('./pages/Faq/Faq'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Extensions = lazy(() => import('./pages/Extensions/Extensions'));
const News = lazy(() => import('./pages/News/News'));
const BrowserExtensionAfterInstall = lazy(() => import('./pages/BrowserExtensionAfterInstall/BrowserExtensionAfterInstall'));
const SECRETTESTONLY = lazy(() => import('./pages/SECRETTESTONLY/SECRETTESTONLY'));

const ShareFromPWA = lazy(() => import('./pages/ShareFromPWA/ShareFromPWA'));


const App = () => {

    const dispatch = useDispatch();
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // const chromeExtId = 'eobhhbghffhlbfbpngljpmakaflgbddi';  

    useEffect(() => {
        serviceWorkerRegistration.register();

        // move this function somewhere
        const authListener = () => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    dispatch(allActions.userActions.setUserAuth({
                        email: user.email,
                        uid: user.uid,
                        displayName: user.displayName,
                        isVerified: user.emailVerified,
                    }));

                    // no need to write to localStorage every time
                    if (!localStorage.getItem('user') || !JSON.parse(localStorage.getItem('user')).email === user.email || !JSON.parse(localStorage.getItem('user')).isVerified) {
                        localStorage.setItem('user', JSON.stringify({
                            email: user.email,
                            uid: user.uid,
                            displayName: user.displayName,
                            isVerified: user.emailVerified
                        }));  
                    }

                    dispatch(allActions.userActions.getUserProfileAtApp()) 
                    dispatch(allActions.contentActions.getAllPostsSnapshot()) 
                    dispatch(allActions.contentActions.updateLoginTimestamp())    
                    dispatch(allActions.roomActions.getRoomsMaster())  

                } else {
                    localStorage.removeItem('user');
                    dispatch(allActions.userActions.setUserAuth(null));

                    if (isChrome) {
                        chrome.runtime.sendMessage(chromeExtId, "signout");             
                    } else {
                        window.postMessage({ type: "token", text: "signout" }, window.parent.origin);
                    }
                }
            })
        }
        //  adding this to avoid flicker when refresh
        dispatch(allActions.contentActions.loadingWhileGetAllPosts())  
        authListener()
        dispatch(allActions.userActions.getAppVersionNumber()) 

    // eslint-disable-next-line
    }, [dispatch, isChrome])

  // public route has NavBar and FooterLanding
  // private route has SideBar
  return (
    <div className="App">
        <SEO />
      
        <Suspense fallback={<LoaderFullScreen />}> 
            <Switch>
                <PublicRoute path="/" component={LandingV2} exact />   
                <PublicRoute path="/inspirations" component={Landing} exact />   

                <PrivateRoute path="/home" component={Home} exact />

                <PrivateRoute path="/share" component={ShareFromPWA} exact />
                

                <PublicRoute path="/signup" component={Signup} />  
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/about" component={About} />
                <PublicRoute path="/how-it-works" component={HowItWorks} />
                <PublicRoute path="/forgotpassword" component={ForgotPassword} /> 
                <PublicRoute path="/terms" component={Terms} />       
                <PublicRoute path="/privacy" component={Privacy} />       

                <PrivateRoute path="/desk" component={Home} exact />
                <PrivateRoute path="/archive" component={ArchiveFolder} exact />
                <PrivateRoute path="/allposts" component={RoomWithAllPosts} exact />

                <PrivateRoute path="/warroom/:id" component={WarroomDetailsPage} /> 
                <PrivateRoute path="/room/:id" component={WarroomDetailsPage} /> 
                <PublicRoute path="/publicwarroom/:id" component={PublicWarroomDetailsPage} />    
                <PublicRoute path="/public/:id" component={PublicWarroomDetailsPage} />   

                <PrivateRoute path="/post/:id" component={PostDetailsPage} />
                <PublicRoute path="/publicpost/:id" component={PublicPostDetailsPage} />    
                <PrivateRoute path="/profile" component={Profile} exact />
                <PrivateRoute path="/News" component={News} exact />
                <PublicRoute path="/extensions" component={Extensions} exact />
                <PrivateRoute path="/extension" component={BrowserExtensionAfterInstall} exact />
                <PrivateRoute path="/news" component={News} exact />
                <PrivateRoute path="/secrettestonly" component={SECRETTESTONLY} exact />

                <PublicRoute path="/faq" component={Faq} />
                <PublicRoute path="/contact" component={Contact} />
                <PublicRoute component={ErrorPage} />
            </Switch>
        </Suspense> 
    </div>
  )
}

export default App;


/* notes

product chrome id:  eobhhbghffhlbfbpngljpmakaflgbddi
"key":  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuu272sbsukX8vzkn1+bm
kxfogT+jPE6Oi29gLkPdOp0IOj+olON/gIa8j9Cd4jMU9TDp7nrVctw1tQ3YDpxj
euxxw0EECP99+YRXZDD1LCOlzlXmY8dfcvXV7GqZmlHfNcD3vB7epCpVEIIVa+x6
LULiftnYcRKk43KJLA2jY5HWVRKXfjzYtJCRpvwv67OAZZa8rHyQ2kRPuoaAWQEl
0cCmnRYEoedsa5MQZqjemTZxhFrWexa3j20w719Pu6oFSozln++vOnPsgYcSXvx3
tGNARnM2DI+y8WiYlJtDDVpkyO9E6N3bubjXNBUAJ/xLqvm8aZYgNVxbvgiQwELT
1wIDAQAB"

testing:
in /functions, firebase emulators:start --only functions

DEPLOY:
comment OFF using emulator in Firebase Console
npm run build
firebase deploy --only hosting
firebase deploy --only hosting:staging to https://osoji-staging.web.app/
firebase deploy --only hosting:prod
firebase deploy --only functions:createPostChat

Request for re-indexing google

INSTALLED:
redux, redux-logger, redux-thunk
react-redux
firebase
npm install moment --save for date/time formatting
react-router-dom  (v5)
npm install --save reactstrap 
npm install --save bootstrap
in index.js, import 'bootstrap/dist/css/bootstrap.min.css';
icons directly from https://feathericons.com/
illustration from https://www.manypixels.co/gallery
npm install --save react-typing-effect
npm install draft-js
npm install --save draft-js-export-html
react-tooltip (since ReactStrap tooltip caused a buggy): see here for different params: https://wwayne.github.io/react-tooltip/
npm install --save react-helmet
mockup image on laptop generated from shotsnapp
on /functions, npm install moment
on /functions, npm install mailersend
npm install fontsource-nunito-sans (remember to put it after bootstrap in index.js)
npm install --save react-twitter-embed
npm install react-masonry-css
npm install react-loading-skeleton
desk icon from flaticon   https://www.flaticon.com/free-icon/desk_1320793
CSS trick:  transform 3d with overflow hidden;  change opacity for overlay on hover
For editor style icons
  npm i --save @fortawesome/fontawesome-svg-core
  npm install --save @fortawesome/free-solid-svg-icons
  npm install --save @fortawesome/react-fontawesome
npm install --save react-toastify   // in serviceWorkReg file test
created custom hook useEditors
display: -webkit-box;  for curtailing text
install Naomi in Sublime for react fragment syntax: https://github.com/borela/naomi
installed but UNINSTALLED npm install react-html-parser --legacy-peer-deps  
npm install --save formidable-serverless  // on functions, for parsing mail
npm install react-responsive for dark mode media query
test: npm install react-pro-sidebar --legacy-peer-deps

FIREBASE:
created braindump-dev project on firebase  
on dashboard, go to Authentication to setup
on dashboard, setup Cloud Firestore
create config folder and file
change Cloud Firestore security to allow....true

FIREBASE CLOUD FUNCTIONS (later)
npm install -g firebase-tools   (global 1 time)
firebase init functions  on terminal, same project folder
firebase init firestore
in the new functions filder, change node version in package.json to 12
in index.js file, initialize firebase-admin to allow you to write to Firestore
TO DEPLOY
firebase deploy --only functions

install OGS on Function folder (not root)
npm install open-graph-scraper



firebase-admin is the library for server-side sdk
https://www.youtube.com/watch?v=rERRuBjxJ80
prevent function fired duplicate by using context.id 


removed JS and CSS source maps....see if it has impact

*/



/*  before production
add basename at index browserrouter later
remove cloud function testing


*/