// used in frontend, and contentAction createPost master only
export const isImagePost = (url) => {	// png, jpg jpeg svg tiff gif webp
	// const length = url.length
	const lastFourCharacters = url.slice(url.length - 4);
	const lastFiveCharacters = url.slice(url.length - 5);	

	return lastFourCharacters === ".png" || lastFourCharacters === ".jpg" 
	|| lastFiveCharacters === ".jpeg" || lastFourCharacters === ".svg" 
	|| lastFourCharacters === ".gif" || lastFiveCharacters === ".tiff"
	|| lastFiveCharacters === ".webp"
}

export const isYouTubePost = (url) => {
	return (url.includes("youtube.com") || url.includes("youtu.be"))
		&& !url.includes("/user/") 
		&& !url.includes("/channel/") 
}

export const isTwitterPost = (url) => {
	return url.includes("twitter.com") && url.includes("status")
}
