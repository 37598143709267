import { 
	SET_USER_AUTH, GET_USER_PROFILE,
	SIGNUP_LOADING, SIGNUP_SUCCESS, SIGNUP_FAIL, SIGNIN_SUCCESS,
	USER_SIGNOUT, UPDATE_LOCAL_NEWS_VERSION,
	GET_APP_VERSION_NUMBER, GLOBAL_NEWS_VERSION, LOCAL_NEWS_VERSION
} from 'constants/constants';

const initialUserState = {
	user: null, 
	isSignupLoading: false,
	userStats: null,
	isSubscribedWeekly: true,
	isSubscribedMonthly: true,
	forwardAddress: null,
	authorizedSenders: [],
	// move below to theme?
	globalNewsVersion: GLOBAL_NEWS_VERSION, // increase this when there's new news and updates
	localNewsVersion: LOCAL_NEWS_VERSION,
	globalAppVersionNumber: null,
	colorTheme: "light", 
	sideBarCollapse: true, 
	homeShowArchiveNotice: true, 

	userWantsArchive: true,

	userNeedsOnboardingAtHome: false, 
	userNeedsOnboardingAtRoom: false, 
	userNeedsOnboardingAtProfile: false, 
}


const userReducer = (state=initialUserState, action) => {

	switch(action.type) {
		case SIGNUP_LOADING:
			return {
				...state,
				isSignupLoading: true
			}
		case SIGNUP_SUCCESS: 
			return {
				...state,
				isSignupLoading: false,
				user: {...state.user, ...action.payload },
				userNeedsOnboardingAtHome: true,  // NEW
				userNeedsOnboardingAtRoom: true,  // NEW
				userNeedsOnboardingAtProfile: true,  // NEW
			}	
		case SIGNUP_FAIL:
			return {
				...state,
				isSignupLoading: false
			}	
		case SIGNIN_SUCCESS:
			return {
				...state,
				isSignupLoading: false,				
			}
		case USER_SIGNOUT:
			return {
				...state,
				user: null,
			}									
		case SET_USER_AUTH:
			return {
				...state,
				user: action.payload
			}		
		case GET_USER_PROFILE: // new !!!!!!!!
			return {
				...state,
				userStats: action.payload.stats,
				// isSubscribedWeekly: action.payload.isSubscribedWeekly,
				isSubscribedMonthly: action.payload.isSubscribedMonthly,
				forwardAddress: action.payload.forwardAddress,
				authorizedSenders: action.payload.authorizedSenders, 
				userWantsArchive: action.payload.userWantsArchive,
				userNeedsOnboardingAtHome: action.payload.userNeedsOnboardingAtHome,
				userNeedsOnboardingAtRoom: action.payload.userNeedsOnboardingAtRoom,
				userNeedsOnboardingAtProfile: action.payload.userNeedsOnboardingAtProfile,					
			}

		case UPDATE_LOCAL_NEWS_VERSION:
			return {
				...state,
				localNewsVersion: state.globalNewsVersion
			}				
		case GET_APP_VERSION_NUMBER:
			return {
				...state,
				globalAppVersionNumber: action.payload
			}			
		case "CREATE_FORWARD_ADDRESS_SUCCESS":
			return {
				...state,
				forwardAddress: action.payload
			}				
		case "ADD_AUTHORIZED_EMAIL_SUCCESS":
			return {
				...state,
				authorizedSenders: [...state.authorizedSenders, action.payload]
			}			
		case "REMOVE_AUTHORIZED_EMAIL_SUCCESS":	
			return {
				...state,
				authorizedSenders: [...state.authorizedSenders].filter(sender => sender !== action.payload)
			}				
		case "SET_COLOR_THEME":
			return {
				...state,
				colorTheme: action.payload
			}		
		case "SET_SIDE_BAR_COLLAPSE":
			return {
				...state,
				sideBarCollapse: action.payload
			}				
		case "SET_HOME_SHOW_ARCHIVE_NOTICE": 
			return {
				...state,
				homeShowArchiveNotice: action.payload
			}	
		case "GET_WHETHER_USER_WANTS_ARCHIVE": 
			return {
				...state,
				userWantsArchive: action.payload
			}			
		case "UPDATE_USER_WANTS_ARCHIVE":  
			return {
				...state,
				userWantsArchive: action.payload
			}					

		case "USER_FINISHES_ONBOARDING":
			return {
				...state,
				[`userNeedsOnboardingAt${action.payload}`]: false	
			}																							
		default: 
			return state; 
	}	
}

export default userReducer;

/*

		// case GET_USER_STATS:
		// 	return {
		// 		...state,
		// 		userStats: action.payload.stats,
		// 		isSubscribedWeekly: action.payload.isSubscribedWeekly,
		// 		isSubscribedMonthly: action.payload.isSubscribedMonthly,
		// 		forwardAddress: action.payload.forwardAddress,
		// 		authorizedSenders: action.payload.authorizedSenders || [], 
		// 	}

*/