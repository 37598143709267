

export const AllPostsIcon = ({ color, height, width }) => {
	return (
		<svg fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
			 viewBox="0 0 512 512" style={{enableBackground:"new 0 0 512 512"}} xml="preserve"

			 height={height || "20px"} width={height || "20px"}
		>
			<g>
				<g>
					<g>
						<path d="M420.418,91.582L335.085,6.248c-0.004-0.004-0.008-0.006-0.011-0.01c-0.494-0.493-1.012-0.96-1.552-1.403
							c-0.247-0.203-0.507-0.379-0.761-0.569c-0.303-0.227-0.6-0.462-0.916-0.673c-0.304-0.203-0.619-0.379-0.931-0.565
							c-0.286-0.171-0.565-0.35-0.859-0.508c-0.318-0.17-0.644-0.314-0.969-0.467c-0.307-0.145-0.609-0.298-0.923-0.429
							c-0.315-0.13-0.637-0.236-0.957-0.35c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.646-0.168-0.969-0.249
							c-0.351-0.089-0.698-0.187-1.055-0.258c-0.375-0.074-0.753-0.119-1.13-0.173c-0.311-0.044-0.617-0.104-0.933-0.135
							c-1.4-0.138-2.811-0.138-4.211,0c-0.315,0.031-0.621,0.09-0.933,0.135c-0.377,0.054-0.756,0.098-1.13,0.173
							c-0.358,0.071-0.704,0.169-1.055,0.258c-0.324,0.081-0.649,0.152-0.969,0.249c-0.344,0.104-0.677,0.233-1.013,0.354
							c-0.32,0.115-0.642,0.22-0.957,0.35c-0.315,0.13-0.616,0.284-0.923,0.429c-0.324,0.153-0.651,0.297-0.969,0.467
							c-0.294,0.158-0.573,0.337-0.859,0.508c-0.312,0.186-0.627,0.362-0.931,0.565c-0.316,0.211-0.612,0.446-0.916,0.673
							c-0.254,0.19-0.514,0.366-0.761,0.569c-0.54,0.443-1.059,0.91-1.552,1.403c-0.004,0.004-0.008,0.006-0.011,0.01l-85.333,85.333
							c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l48.915-48.915V320c0,11.782,9.551,21.333,21.333,21.333
							s21.333-9.551,21.333-21.333V72.837l48.915,48.915c8.331,8.331,21.839,8.331,30.17,0
							C428.749,113.42,428.749,99.913,420.418,91.582z"/>
						<path d="M262.248,390.248l-48.915,48.915V192c0-11.782-9.551-21.333-21.333-21.333c-11.782,0-21.333,9.551-21.333,21.333v247.163
							l-48.915-48.915c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17l85.333,85.333c0.004,0.004,0.008,0.007,0.012,0.011
							c0.493,0.492,1.012,0.959,1.551,1.402c0.247,0.203,0.508,0.379,0.762,0.57c0.303,0.227,0.6,0.462,0.915,0.673
							c0.304,0.203,0.619,0.379,0.931,0.565c0.286,0.171,0.565,0.35,0.859,0.507c0.318,0.17,0.645,0.314,0.97,0.467
							c0.306,0.145,0.608,0.298,0.922,0.428c0.315,0.13,0.637,0.236,0.957,0.35c0.337,0.121,0.669,0.25,1.013,0.354
							c0.32,0.097,0.645,0.168,0.969,0.249c0.351,0.089,0.698,0.187,1.056,0.258c0.375,0.074,0.753,0.118,1.13,0.172
							c0.311,0.044,0.618,0.104,0.933,0.135c1.4,0.138,2.811,0.138,4.211,0c0.315-0.031,0.621-0.09,0.933-0.135
							c0.377-0.054,0.756-0.098,1.13-0.173c0.358-0.071,0.704-0.169,1.055-0.258c0.324-0.081,0.649-0.152,0.969-0.249
							c0.344-0.104,0.677-0.233,1.013-0.354c0.32-0.115,0.642-0.22,0.957-0.35c0.314-0.13,0.615-0.283,0.921-0.428
							c0.325-0.153,0.653-0.297,0.971-0.468c0.293-0.157,0.572-0.336,0.857-0.506c0.312-0.186,0.628-0.363,0.932-0.566
							c0.315-0.211,0.611-0.445,0.913-0.671c0.255-0.191,0.516-0.368,0.764-0.571c0.535-0.439,1.05-0.903,1.54-1.392
							c0.008-0.007,0.016-0.014,0.023-0.021l85.333-85.333c8.331-8.331,8.331-21.839,0-30.17S270.58,381.917,262.248,390.248z"/>
					</g>
				</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</svg>

	)
}  



export const sortIconSVG =  
<svg fill="grey" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 512 512" style={{enableBackground:"new 0 0 512 512"}} xml="preserve"

	 height="20px" width="20px"
>
	<g>
		<g>
			<g>
				<path d="M420.418,91.582L335.085,6.248c-0.004-0.004-0.008-0.006-0.011-0.01c-0.494-0.493-1.012-0.96-1.552-1.403
					c-0.247-0.203-0.507-0.379-0.761-0.569c-0.303-0.227-0.6-0.462-0.916-0.673c-0.304-0.203-0.619-0.379-0.931-0.565
					c-0.286-0.171-0.565-0.35-0.859-0.508c-0.318-0.17-0.644-0.314-0.969-0.467c-0.307-0.145-0.609-0.298-0.923-0.429
					c-0.315-0.13-0.637-0.236-0.957-0.35c-0.337-0.121-0.669-0.25-1.013-0.354c-0.32-0.097-0.646-0.168-0.969-0.249
					c-0.351-0.089-0.698-0.187-1.055-0.258c-0.375-0.074-0.753-0.119-1.13-0.173c-0.311-0.044-0.617-0.104-0.933-0.135
					c-1.4-0.138-2.811-0.138-4.211,0c-0.315,0.031-0.621,0.09-0.933,0.135c-0.377,0.054-0.756,0.098-1.13,0.173
					c-0.358,0.071-0.704,0.169-1.055,0.258c-0.324,0.081-0.649,0.152-0.969,0.249c-0.344,0.104-0.677,0.233-1.013,0.354
					c-0.32,0.115-0.642,0.22-0.957,0.35c-0.315,0.13-0.616,0.284-0.923,0.429c-0.324,0.153-0.651,0.297-0.969,0.467
					c-0.294,0.158-0.573,0.337-0.859,0.508c-0.312,0.186-0.627,0.362-0.931,0.565c-0.316,0.211-0.612,0.446-0.916,0.673
					c-0.254,0.19-0.514,0.366-0.761,0.569c-0.54,0.443-1.059,0.91-1.552,1.403c-0.004,0.004-0.008,0.006-0.011,0.01l-85.333,85.333
					c-8.331,8.331-8.331,21.839,0,30.17c8.331,8.331,21.839,8.331,30.17,0l48.915-48.915V320c0,11.782,9.551,21.333,21.333,21.333
					s21.333-9.551,21.333-21.333V72.837l48.915,48.915c8.331,8.331,21.839,8.331,30.17,0
					C428.749,113.42,428.749,99.913,420.418,91.582z"/>
				<path d="M262.248,390.248l-48.915,48.915V192c0-11.782-9.551-21.333-21.333-21.333c-11.782,0-21.333,9.551-21.333,21.333v247.163
					l-48.915-48.915c-8.331-8.331-21.839-8.331-30.17,0s-8.331,21.839,0,30.17l85.333,85.333c0.004,0.004,0.008,0.007,0.012,0.011
					c0.493,0.492,1.012,0.959,1.551,1.402c0.247,0.203,0.508,0.379,0.762,0.57c0.303,0.227,0.6,0.462,0.915,0.673
					c0.304,0.203,0.619,0.379,0.931,0.565c0.286,0.171,0.565,0.35,0.859,0.507c0.318,0.17,0.645,0.314,0.97,0.467
					c0.306,0.145,0.608,0.298,0.922,0.428c0.315,0.13,0.637,0.236,0.957,0.35c0.337,0.121,0.669,0.25,1.013,0.354
					c0.32,0.097,0.645,0.168,0.969,0.249c0.351,0.089,0.698,0.187,1.056,0.258c0.375,0.074,0.753,0.118,1.13,0.172
					c0.311,0.044,0.618,0.104,0.933,0.135c1.4,0.138,2.811,0.138,4.211,0c0.315-0.031,0.621-0.09,0.933-0.135
					c0.377-0.054,0.756-0.098,1.13-0.173c0.358-0.071,0.704-0.169,1.055-0.258c0.324-0.081,0.649-0.152,0.969-0.249
					c0.344-0.104,0.677-0.233,1.013-0.354c0.32-0.115,0.642-0.22,0.957-0.35c0.314-0.13,0.615-0.283,0.921-0.428
					c0.325-0.153,0.653-0.297,0.971-0.468c0.293-0.157,0.572-0.336,0.857-0.506c0.312-0.186,0.628-0.363,0.932-0.566
					c0.315-0.211,0.611-0.445,0.913-0.671c0.255-0.191,0.516-0.368,0.764-0.571c0.535-0.439,1.05-0.903,1.54-1.392
					c0.008-0.007,0.016-0.014,0.023-0.021l85.333-85.333c8.331-8.331,8.331-21.839,0-30.17S270.58,381.917,262.248,390.248z"/>
			</g>
		</g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
</svg>