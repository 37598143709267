import { useSelector, useDispatch } from 'react-redux';
import allActions from "../../../actions";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './groupchangeroommodal.css';

// used in FixedNavbar....postForChangeRoom here refers to selectedPosts
// can only move within your account, not to shared
const GroupChangeRoomModal = ({ postForChangeRoom, modalChangeRoom, toggleChangeRoom }) => {
	const dispatch = useDispatch();

	const roomSelected = useSelector(state => state.contentReducer.roomSelected);
	const ownRooms = useSelector(state => state.contentReducer.rooms);
 	// showing pinned rooms first
 	const roomsWithPin = ownRooms.filter(room => room.pin === true)
	const roomsWithoutPin = ownRooms.filter(room => room.pin !== true)
	const rooms = [...roomsWithPin, ...roomsWithoutPin];	
 
	const curtailedRoomTitle = (title) => {
		if (title.length > 50) {
			return title.slice(0, 50).concat("...")
		} else {
			return title
		}		
	}

	const roomList = rooms.map(room => (
		<div 
			key={room.roomId || room.originalRoomId} 
		  	onClick={() => {
		  		toggleChangeRoom()
		  		dispatch(allActions.contentActions.changeRoomForPostsInGroup({
		  			selectedPosts: postForChangeRoom, 
		  			selectedwarroom: room.roomId,
		  			currentRoom: roomSelected
		  		})) 
			}}
			className="groupchangeroom-room-card"
		>
			{curtailedRoomTitle(room.title)} 
		</div>
	))

	if (!postForChangeRoom) return <Modal size="md"></Modal>

	return (
		<Modal size="md" isOpen={modalChangeRoom} toggle={toggleChangeRoom}> 
		   <ModalHeader 
		   		toggle={toggleChangeRoom} 
		   		className="modal-general"
		   	>Move {postForChangeRoom.length} Posts to:</ModalHeader>

		   <ModalBody className="modal-general">
			   	<div className="groupchangeroom-container">
				   	<div className="groupchangeroom-room-group">
				   		{roomList}
				   	</div>
				</div>   		
		   </ModalBody>
		</Modal>
	)
}


export default GroupChangeRoomModal;