import './testimonials.css';

const Testimonials = () => {
	return (
		<section className="testimonial-section-container">
			<h2 className="landing-section-header">What People are Saying</h2>
			<div className="testimonial-container">
				<div className="testimonial-card">
					<div className="testimonial-card-top">
						<img loading="lazy" style={{ width: "40px" }} src={`${process.env.PUBLIC_URL}/images/landing/left-quote.svg`} alt='left-quote' />
					</div>

		{/*			Osoji helps me to be more focused and organized in saving ideas for my different projects. It is my new Chrome bookmarks + Google Keep. */}
					<div className="testimonial-card-middle">
						I like how simple and easy Osoji is. It serves as my repository for cooking recipes, and helps me plan brunch ideas and organize inspiration for trips with my friends.  
					</div>
					<hr />
					<div className="testimonial-card-bottom">
						<img className="testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/emma-edwards.png`} alt='emma' />
						<div className="testimonial-card-bottom-right">
							<p className="testimonial-name">Emma Edwards</p>
							<p className="testimonial-title">Business Analyst</p>
						</div>
					</div>					
				</div>				

				<div className="testimonial-card">
					<div className="testimonial-card-top">
						<img loading="lazy" style={{ width: "40px" }} src={`${process.env.PUBLIC_URL}/images/landing/left-quote.svg`} alt='left-quote' />
					</div>
					<div className="testimonial-card-middle">
						I finally have a tool I can use as a brain dump. Osoji has made me so much more productive in my learning process by categorizing my learning materials in a sensible way. 
						No more digging through bookmarks or leaving tabs open for days on end.
		{/*				I like how simple and intuitive Osoji is, and it solves a problem I've had for ages - having random notes and links all over the place that I can't find.
						Kudos to the Osoji team!  */}
					</div>
					<hr />
					<div className="testimonial-card-bottom">
						<img className="testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/errol-3.png`} alt='testimonial' />
						<div className="testimonial-card-bottom-right">
							<p className="testimonial-name">Errol Watson</p>
							<p className="testimonial-title">Software Engineer</p>
						</div>
					</div>					
				</div>

				<div className="testimonial-card">
					<div className="testimonial-card-top">
						<img loading="lazy" style={{ width: "40px" }} src={`${process.env.PUBLIC_URL}/images/landing/left-quote.svg`} alt='left-quote' />
					</div>
					<div className="testimonial-card-middle">	
						Osoji is now part of my daily routine to keep and remember what is important. 
						These stuff used to be scattered across bookmarks, emails, Slack, WhatsApp.

					</div>
					<hr />
					<div className="testimonial-card-bottom">
						<img className="testimonial-image" loading="lazy" src={`${process.env.PUBLIC_URL}/images/landing/duc.jpeg`} alt='testimonial' />
						<div className="testimonial-card-bottom-right">
							<p className="testimonial-name">Duc Phung</p>
							<p className="testimonial-title">CEO, Gojek Vietnam</p>
						</div>
					</div>					
				</div>		
			</div>
		</section>
	)
}

export default Testimonials;