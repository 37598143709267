import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import allActions from "actions";
import { Link } from 'react-router-dom';
import { warroomUrl } from "utils/transformIdAndUrl";
import { useDropForDnD } from "hooks/useDropForDnD";	
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import "../sidebar.css";


const EachRoom = (props) => {
	const { room, posts } = props;

	// DND
	const dropForDnD = useDropForDnD(); 
	const {
		isRoomReceiving, 
		onDragOver, onDragLeave, onDrop
	} = dropForDnD;

	return (
		<Link className="dont-link-me" to={warroomUrl(room)} key={room.roomId}>
			<DropdownItem 
				className="sidebar-dropdown-item"

				onDragOver={(e) => onDragOver(e)} 
			    onDragLeave={(e) => onDragLeave(e)}						
				onDrop={(e) => onDrop({
					e: e,
					roomDestination: room.roomId, 
				})} 
				{...isRoomReceiving && { style: { backgroundColor: "var(--main)", opacity: "0.7" }}}
			>
				<div className="sidebar-dropdown-item-text" style={{ paddingLeft: "0px"}}>
					{room.title}
				</div>	
				<div className="sidebar-dropdown-item-count" style={{ marginLeft: "10px"}}>
					{posts.filter(post => post.room === room.roomId).length}
				</div>
			</DropdownItem>		
		</Link>        
	)	
}

const RoomsAtCollapsedSideBar = () => {
	// INCLUDE DESK TOO
	const posts = useSelector(state => state.contentReducer.posts);
	const rooms = useSelector(state => state.contentReducer.rooms);

	const [roomsMenuOpen, setRoomsMenuOpen] = useState(false); // change later
	const toggle = () => setRoomsMenuOpen(!roomsMenuOpen);

	const colorTheme = useSelector(state => state.userReducer.colorTheme);

 	// showing pinned rooms first
 	const roomsWithPin = rooms.filter(room => room.pin === true)
	const roomsWithoutPin = rooms.filter(room => room.pin !== true)
	const combinedRooms = [...roomsWithPin, ...roomsWithoutPin];	

	// DND
	const draggedPost = useSelector(state => state.contentReducer.draggedPost);

	const warroomsOnMenu = combinedRooms.map((room) => (
		<EachRoom 
			room={room} 
			posts={posts}
			key={room.roomId}			
		/>
	))	

	const roomMenuIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/folder.svg` : `${process.env.PUBLIC_URL}/images/icons/folder-dark.svg`;
	
	useEffect(() => {
		if (draggedPost) setRoomsMenuOpen(true) // so that dropdown will auto open if uesr drags a post
		else setRoomsMenuOpen(false)
	}, [draggedPost])


	return (

        <Dropdown 
        	isOpen={roomsMenuOpen} 
        	toggle={toggle}
        	onMouseEnter={toggle}
        	onMouseLeave={toggle}
        >
            <DropdownToggle nav>
            	<img src={roomMenuIcon} alt='rooms' style={{ width: "20px", marginTop: "25px" }}/>	
            </DropdownToggle>

            <DropdownMenu 
            	className="rooms-dropdown-at-collapsed"
            >
                {warroomsOnMenu}
            </DropdownMenu>

        </Dropdown>

	)
}

export default RoomsAtCollapsedSideBar;