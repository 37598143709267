import { Alert as ReactstrapAlert } from 'reactstrap';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { useMediaQuery } from 'react-responsive';
import './alert.css';

const Alert = () => {  // removed style and autoDismiss prop 

	const alert = useSelector(state => state.alertReducer.alert);
	const alertType = useSelector(state => state.alertReducer.alertType);
 	const sideBarCollapse = useSelector(state => state.userReducer.sideBarCollapse);	
	const dispatch = useDispatch();

	const isDesktop = useMediaQuery({ minWidth: "768px" });	

	useEffect(() => {
		if (alert && alertType === "success") { // warning and danger don't auto close
			setTimeout(() => dispatch(allActions.userActions.closeAlert()), 3000)		
		}
	}, [alert, alertType, dispatch])

	if (!alert) return <div></div>


	return (
		<ReactstrapAlert 
			color={alertType} 
			className="new-alert"
			{...!sideBarCollapse &&  isDesktop && { style: {marginLeft: "-80px"}}} // so that alert can position centrally when sidebar open
		>	
			{alert}

			<button 
				type="button" className="close" data-dismiss="alert" aria-label="Close"
			  	onClick={() => dispatch(allActions.userActions.closeAlert())}
			>
			  <span aria-hidden="true">&times;</span>
			</button>				

		</ReactstrapAlert>			
	)		
}

export default Alert;
