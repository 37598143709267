import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { Link } from 'react-router-dom';
import {
  Collapse, Navbar, NavbarToggler, Nav, NavbarText
} from 'reactstrap';
import Search from "../Search/Search";
import DarkModeToggle from "components/DarkModeToggle/DarkModeToggle";
import RoomsMenuDropdown from "./components/RoomsMenuDropdown";
import UserMenuDropdown from "./components/UserMenuDropdown";
import FixedNavbarWithSelectedPosts from "./components/FixedNavbarWithSelectedPosts";
import LabelsMenuDropdown from "./components/LabelsMenuDropdown";
import './navbar.css';


const NavBar = () => {

	const dispatch = useDispatch();
	const userFromState = useSelector(state => state.userReducer.user);
	const isSignupLoading = useSelector(state => state.userReducer.isSignupLoading);	
	
	const localNewsVersionfromState = useSelector(state => state.userReducer.localNewsVersion);
	const globalNewsVersion = useSelector(state => state.userReducer.globalNewsVersion);

	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const selectedPosts = useSelector(state => state.contentReducer.selectedPosts);
	const posts = useSelector(state => state.contentReducer.posts);
	const rooms = useSelector(state => state.contentReducer.rooms);
	const searchTerm = useSelector(state => state.contentReducer.searchTerm);
	const labels = useSelector(state => state.contentReducer.labels);

	const user = userFromState || JSON.parse(localStorage.getItem('user')) || null
	
	// this is for displaying the red-dot
	// const localNewsVersion =  
	// 	localStorage.getItem('localNewsVersion')  // get from localstorage first if there is
	// 	? JSON.parse(localStorage.getItem('localNewsVersion')).version 
	// 	: localNewsVersionfromState
	const localNewsVersion = JSON.parse(localStorage.getItem('localNewsVersion'))?.version  || localNewsVersionfromState;

	const [NavbarWarroomDropdown, setNavbarWarroomDropdown] = useState(false);

	const toggle = () => {
		if (NavbarWarroomDropdown) {
			setNavbarWarroomDropdown(false);
		} else {
			if (window.innerWidth < 768) {
				setNavbarWarroomDropdown(true);
			}
		}
	} 

	const handleLogoClick = () => {
		toggle() // for mobile UI
		if (searchTerm.length > 0) dispatch(allActions.contentActions.searchPosts(""))
		if (user && posts.length === 0) dispatch(allActions.contentActions.getAllPosts())
		if (user && rooms.length === 0) dispatch(allActions.roomActions.getRoomsMaster())  
	}
	const handleDeskClick = () => {
		if (searchTerm.length > 0) dispatch(allActions.contentActions.searchPosts(""))
		if (user && posts.length === 0) dispatch(allActions.contentActions.getAllPosts())
		if (user && rooms.length === 0) dispatch(allActions.roomActions.getRoomsMaster())  
	}


	if (isSignupLoading) return <header></header>

	return (
		 <div className="navbar-container">
		 	{(selectedPosts.length > 0) && // show FixedNavbar when in Select Mode
		 		<FixedNavbarWithSelectedPosts 
		 			selectedPosts={selectedPosts} 
		 			colorTheme={colorTheme}
		 		/>	
		 	}

			<Navbar 
				light={(colorTheme === "light")}
				dark={(colorTheme === "dark")}  
				expand="md"> 
				<Link 
					className="dont-link-me" 
					to={user ? "/home" : "/"} 
					onClick={handleDeskClick}>
					<div style={{ 
							height: "30px",width: "85px", marginBottom: "0px", marginRight: "15px", 
						}} >
						<img 
							style={{ height: "30px", width: "30px", marginRight: "8px", verticalAlign: "middle"}} 
							src={`${process.env.PUBLIC_URL}/images/logo/osoji-logo-3.png`} 
							alt='osoji-logo' 
						/> 
						<span style={{ 
							fontWeight: "600", fontSize: "18px", color: "var(--header)",
							verticalAlign: "middle",
						}}>Osoji</span> 					
					</div>
					{/*	<img 
								style={{ height: "30px", marginBottom: "2px", marginRight: "15px"}} 
								src={`${process.env.PUBLIC_URL}/images/logo/LogoWithText_nav.png`} 
								alt='osoji-logo' 
							/>  */}	
				</Link>  		        
				
				{user &&  /*this is to show in mobile only so that it is outside collapsed menu*/
					<Search where="mobileNavBar" /> 
				}	

		        <NavbarToggler 
		        	onClick={toggle} 
		        	style={{border: "none", outline: "none"}}
		        />
		    	
		    	{/* Collapse contain everything in hamburger menu incl. when displayed in desktop - rooms menu, all links, profile*/}
		        <Collapse isOpen={NavbarWarroomDropdown} navbar>
			      	{user
			      	? /* signed in */
			      	<>
			      		{/* left side */}
			          	<Nav className="mr-auto" navbar>
							<Link 
								className="nav-quick-links dont-link-me" 
								to="/home" 
								onClick={handleLogoClick}
							>
								Desk
							</Link> 		

							<RoomsMenuDropdown toggle={toggle} />

							{labels && labels.size > 0 && <LabelsMenuDropdown toggle={toggle} />}

							<Link 
								className="nav-quick-links dont-link-me nav-link-padding hide-in-ipad"  
								to="/home" 
								onClick={() => {
									toggle()
									dispatch(allActions.contentActions.searchPosts(`pinned:`))
								}} 
							>
								Pinned
							</Link>  		

							{/*	
							<a 
								className="nav-quick-links dont-link-me nav-link-padding hide-in-desktop" 
								href="https://www.notion.so/How-to-install-Osoji-for-mobile-and-desktop-4b61ac756e7545478d0a091e0688885d" 
								onClick={toggle} 
								target="_blank" rel="noreferrer">
								Mobile
							</a>
							*/}  	

							<Link className="nav-quick-links dont-link-me nav-link-padding"  to="/news" onClick={toggle} >
								What's New 
								<span>
									{(globalNewsVersion !== localNewsVersion) &&
										<img className="news-red-dot"src={`${process.env.PUBLIC_URL}/images/navbar/red-dot.png`} alt='news' />
									}
								</span>
							</Link>  																	            
			          	</Nav>

			          	{/* right side begins*/}	
						<DarkModeToggle /> 
						
						<Search where="navBar" /> 
			
						<UserMenuDropdown 
							user={user}
							toggle={toggle}
						/>
			        </>    
			        : /* not signed in */
			        <>
			         	<Nav className="mr-auto" navbar> {/*so that sign in can be pushed to right*/}
			          	</Nav>			

				        <NavbarText className="navbar-right">
		         			<Link className="dont-link-me navbar-right-action-login" to="/login" onClick={toggle}>
								Sign In
							</Link>   						
		         			<Link className="dont-link-me navbar-right-action-signup" to="/signup" onClick={toggle}>
								<button className="main-button">
								Get Started
								</button>
							</Link>   						          		
				        </NavbarText>
			        </>			          
			        }			
		        </Collapse>
		    </Navbar>
		</div>     
	)
}

export default NavBar;

/*

<a className="nav-quick-links dont-link-me nav-feedback" href="https://tally.so/r/Vw2y93" onClick={toggle} target="_blank" rel="noreferrer">
								Feedback <span><img src={`${process.env.PUBLIC_URL}/images/navbar/external-link.svg`} alt='external-link' style={{ height: "16px", marginBottom: "5px" }}/></span>
							</a>  	

<Link className="dont-link-me" to="/archive" onClick={toggle}>      
							                <DropdownItem>
												{archiveIcon} Archive	                  
							                </DropdownItem>	
							            </Link> 	

							            <a className="dont-link-me" href="https://www.feedbackvote.com/Home/feedback/806#" onClick={toggle} target="_blank" rel="noreferrer">      
							                <DropdownItem>
												Feature Vote    	                  
							                </DropdownItem>	
							            </a> 	
     		


*/
