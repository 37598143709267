
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { Link } from 'react-router-dom';
import {
	UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import "../navbar.css";


const LabelsMenuDropdown = ({ toggle }) => {

	const dispatch = useDispatch();
	const labels = useSelector(state => state.contentReducer.labels);

	// in Map  { "tech": 2, "design": 4 }
	const labelsOnMenu = [...labels.keys()]
		.sort((a,b) => labels.get(b) - labels.get(a))
		.map((key) => (
			<Link 
				to="/home" 
				className="dont-link-me"
				onClick={() => {
					toggle()
					dispatch(allActions.contentActions.searchPosts(`label:${key}`) )
				}} 
				key={key} 
			>
				<DropdownItem className="navbar-dropdown-item" >
					{key} ({labels.get(key)})
				</DropdownItem>	
			</Link>	
		))

	return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret className="dont-link-me nav-rooms-menu-header" > {/* hide-in-ipad */}
                Labels
            </DropdownToggle>				

            <DropdownMenu className="navbar-dropdown-menu">  		
       			{labelsOnMenu}						
            </DropdownMenu>
        </UncontrolledDropdown>
	)
}

export default LabelsMenuDropdown;