import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDVgfog4XH4vchXfTbLQSKkCt7kr_Wr_Bg",
  authDomain: "auth.custom.osoji.io",  // braindump-dev-1932d.firebaseapp.com
  // databaseURL: "https://boxgreen.firebaseio.com", 
  // storageBucket: 'boxgreen.appspot.com', 
  projectId: "braindump-dev-1932d",
};

firebase.initializeApp(firebaseConfig);

// if (process.env.NODE_ENV !== `production`) {
//     firebase.functions().useFunctionsEmulator('http://localhost:5001') 
// }


export const db = firebase.firestore(); 
export default firebase;