import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import allActions from "actions";


// used at SideBar (moving to Desk), RoomsAtSideBar, SharedRoomsAtSideBar, RoomsAtCollapsedSideBar, RoomCard (warroomsectionew)
export const useDropForDnD = () => {

	const dispatch = useDispatch();
	const [isRoomReceiving, setIsRoomReceiving] = useState(false);
	const draggedPost = useSelector(state => state.contentReducer.draggedPost);

	const onDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!draggedPost) return // prevent receiving other things
		if (draggedPost?.owner) return  // dont show if dragging from sharedRoom

		setIsRoomReceiving(true) 
	}			
	const onDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!draggedPost) return // prevent receiving other things		
		if (draggedPost?.owner) return  // dont show if dragging from sharedRoom

		setIsRoomReceiving(false)
	}			
	const onDrop = ({ e, roomDestination, owner}) => {
		// final drop, triggered on DESTINATION
		e.preventDefault();
		e.stopPropagation();
		if (!draggedPost) return // prevent receiving other things		
		if (draggedPost?.owner) return  // dont show if dragging from sharedRoom

		dispatch(allActions.contentActions.dropPostToRoomConfirm({
			draggedPost: draggedPost, 
			roomDestination: roomDestination,
			owner: owner
		}))

		setIsRoomReceiving(false)
	}	



	return { 
		draggedPost, isRoomReceiving, 
		onDragOver, onDragLeave, onDrop
	}
}