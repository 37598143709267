import { 
	ERROR_MESSAGE, SUCCESS_MESSAGE, SIGNUP_FAIL,
	CREATE_POST, GET_NEW_LINK_POST, GET_NEW_LINK_POST_FAIL, UPDATE_ROOM_COUNT_AND_ORDER, 
	chromeExtId
} from 'constants/constants';
import firebase, { db } from 'config/Firebase';

/*global chrome*/
// const seedPostWelcomeMessage = "<p>Welcome to <strong>Osoji</strong> 👋</p><p>Desk is a special space for your content before they are sorted to Rooms.</p>"
const seedPostDesc = [
  '<p>Quick tips here 👇</p>',
  '<p>🏷️ Organize your posts with labels and pins.</p>',
  '<p>📭 Save important emails to Osoji - get [yourname]@mail.osoji.io address at Profile.</p>',
  '<p>🤝 Invite others to collaborate in your Room.</p>',
  '<p>🔗 Rooms are private, but you can share a room publicly.</p>',
  '<p>✅ Check out our Chrome and Firefox extensions <a href="https://osoji.io/extensions" rel="noopener nofollow" target="_blank">here</a>.</p>',  
]


// redux post structure
//used in getAllPostsSnapshot and getPostsShared,  and getPostsPublic?
export const postStructure = ({ post, owner }) => {
	// only getPostsShared has owner
	return {
		desc: post.data().desc,
		title: post.data().title,
		room: post.data().room,
		thumbnail: post.data().thumbnail,
		url: post.data().url,
		docId: post.id,	
		notes: post.data().notes || [],	
		serverTimestamp: post.data().serverTimestamp?.toDate() || new Date(),
		source: post.data().source, 	// for EMAIL. 
		descEmailText: post.data().descEmailText, // for EMAIL.
		pin: post.data().pin, // for pin posts
		labels: post.data().labels || [], // for labels			
		minimize: post.data().minimize, // for minimize
		owner: owner // added this so that public post can be viewed directly			
	}
} 

// used in getPostDetails, getPostDetailsPublic, resetPostDetails, and contentReducer
export const postDetailsStructure = ({ post }) => {

	if (!post) return { // for default or to reset if post is null
	 	serverTimestamp: "",
	 	desc: "",
	 	room: "",
	 	thumbnail: "",
	 	title: "",
	 	url: "",
	 	docId: "",
	 	notes: [],
	 	labels: []			
	}

	return {
		serverTimestamp: post.data().serverTimestamp.toDate(),
		desc: post.data().desc,
		room: post.data().room,
		thumbnail: post.data().thumbnail,
		title: post.data().title,
		url: post.data().url,
		docId: post.id,
		notes: post.data().notes ? post.data().notes.reverse() : [], // reverse so that latest shows first	
		labels: post.data().labels || [],		
	}
} 

 
// used in getAllPostSnapshot, getAllPosts, getPostsPublic, getPostsShared
export const getDataFromEachPost = ({ post, postsHolder, labelsHolder, owner }) => {
	if (!post.data().serverTimestamp) return // hack fix signup issue duplicate posts before serverTimestamp is added
		
	postsHolder.push(postStructure({ 
		post: post, 
		owner: owner 
	}))

	// for labels
	 if (post.data().labels?.length > 0) {
	 	post.data().labels?.forEach((label) => {
	 		if (labelsHolder.has(label)) { // add 1
	 			labelsHolder.set(label, labelsHolder.get(label) + 1)
	 		} else { // add  new key: value
	 			labelsHolder.set(label, 1)
	 		}
	 	})
	 } 		
}

export const getVariablesForCreatePost = ({ ownerDirectFromSharedRoom, currentRoom, currentTitle }) => {
	const isSavingFromOwnRoomToSharedRoom = document.getElementById('warroomforpost')?.value?.includes("-sharedroom");

	const owner = ownerDirectFromSharedRoom											// save at Shared Room directly
			? ownerDirectFromSharedRoom														// get ownerId directly	
			: isSavingFromOwnRoomToSharedRoom 											// save from own room to Shared Room
			? derivedOwner(document.getElementById('warroomforpost')?.value)				// get ownerId derived
			: null 																			// normal case

	const selectedwarroom = 
		(ownerDirectFromSharedRoom || !document.getElementById('warroomforpost')?.value) // save at Shared Room directly OR adding to current room via AddPostCard
		? currentRoom 																		// use current location;  if at Shared Room, it is already derived, see AddPostCard --> roomIdToUse
		: isSavingFromOwnRoomToSharedRoom 												// save from own room to Shared Room
		? derivedOwnerRoom(document.getElementById('warroomforpost')?.value) 				// get ownerRoom derived
		: document.getElementById('warroomforpost').value 									// else, use dropdown value (i.e. might add to other rooms excl shared rooms)

	const destinationRoomTitle = 
		document.getElementById('warroomforpost')?.value 
		? document.getElementById('warroomforpost').options[document.getElementById('warroomforpost').selectedIndex].title 
		: currentTitle

	return {
		isSavingFromOwnRoomToSharedRoom, 
		owner, 
		selectedwarroom, // change this naming!!
		destinationRoomTitle
	}
}

// used in createPost (Master)
export const cleanUrlForCreatePost = (newpost) => {
		// add https and remove <p> and </p> for URL
	if (newpost.slice(0,7) === "<p>www.") return `https://${newpost.slice(3, newpost.length - 4)}`
		// remove <p> and </p> for URL
	else return newpost.slice(3, newpost.length - 4)  
}

// used in createPost (Master)
export const isNewPostAnUrl = (newpost) => {
	const firstTenCharacters = newpost.slice(0, 10);
	const withoutPTags = newpost.slice(3, newpost.length - 4);

	if (firstTenCharacters === "<p>https:/" || firstTenCharacters === "<p>http://" || firstTenCharacters.slice(0,7) === "<p>www.") {

		if (newpost.includes(" ")) return false // if "www.yinhow.com some text here", save as note, not link
		if (withoutPTags.includes("</")) return false //if <p>www.yinhow.com</p><p>test3</p>

		return true
	} else {
		return false
	}
}

// helper function (used only in createPost function, not in frontend)
// for post that needs scraping: link post, youtube post, twitter post
export const createLinkPost = (props) => async (dispatch) => {
	const { url, userUid, selectedwarroom, destinationRoomTitle, owner } = props;

	const pendingId = `pendingId+${url}` // this is so we can have multiple pending at one time

	dispatch({
		type: owner ? "CREATE_POST_SHARED" :  CREATE_POST,  
		payload: {
			title: "adding in progress",
			desc: "give us a second",
			room: selectedwarroom,
			docId: pendingId,
			thumbnail: `${process.env.PUBLIC_URL}/images/postlisting/loading-post.gif`,
			url: url,
			notes: [],	
			serverTimestamp: new Date(), // for redux, we update with new Date() instead of serverTimestamp because the latter depends on what it first invokes on frontend (only use it for updating Firebase)	
		}
	})		

	try {
		const result = await firebase.functions().httpsCallable('createPostCloud')({  // call cloud function to execute scraping and creating
			newpost: url, 
			selectedwarroom: selectedwarroom, 
			owner: owner 
		})

		if (result.data.docId) { // if successful  
			try {
				const post = await db.collection('users').doc(userUid)
					.collection("posts").doc(result.data.docId) 
					.get()

				dispatch({ // update redux with successfully scraped link and replace loading
					type: owner ? "GET_NEW_LINK_POST_SHARED" :  GET_NEW_LINK_POST,  
					payload: {
						serverTimestamp: post.data().serverTimestamp.toDate(),
						desc: post.data().desc,
						room: post.data().room,
						thumbnail: post.data().thumbnail,
						title: post.data().title,
						url: post.data().url,
						docId: post.id,
						notes: post.data().notes || [],					
						labels: []							
					}
				})
			} catch (e) {
				dispatch(errorMessage(`error encounted in creating post: ${e.message}`))
			}

			if (result.data.result.success) dispatch(successMessage(`post created successfully at ${destinationRoomTitle} `))						
			else dispatch(errorMessage("sorry, we are unable to retrieve this website.")) // notify if scraping fails (but we still create the post)

			!owner && dispatch({	// if at sharedroom, no need update redux
		  		type: UPDATE_ROOM_COUNT_AND_ORDER,  //so that we don't need to fetch getRooms every time
		  		payload: {
		  			room: selectedwarroom,
		  			increment: 1
		  		}
		  	})	
		} else {
			dispatch({
				type: owner ? "GET_NEW_LINK_POST_FAIL_SHARED" :  GET_NEW_LINK_POST_FAIL,   // to remove the pendingId post
				payload: url // so that we know which pendingId to remove
			})
			dispatch(errorMessage("failed to create post. please try again"))			
		}

	} catch (e) {
		dispatch(errorMessage(`failed to create post: ${e.message}`))

	}
}

// helper function (used only in createPost function, not in frontend)
export const createNotePost = (props) => async (dispatch) => {
	const { newpost, userUid, selectedwarroom, destinationRoomTitle, owner, serverTimestamp } = props;

	const batch = db.batch();

	const postRef = db.collection('users').doc(userUid).collection("posts").doc()
	const roomRef = db.collection('users').doc(userUid).collection("warrooms").doc(selectedwarroom)
	
	// hack to remove trailing spaces that causes issue with draft.js
	const cleanNote = newpost.replace(/&nbsp;/g, "");

	try {
		batch.set(postRef, {
			desc: cleanNote,
			room: selectedwarroom,
			serverTimestamp: serverTimestamp			
		})
		batch.update(roomRef, {
			createdAt: serverTimestamp			
		})		

		await batch.commit()

		dispatch({
			type: owner ? "CREATE_POST_SHARED" :  CREATE_POST,   // HANDLE SHARING
			payload: {
				desc: cleanNote,
				room: selectedwarroom,
				docId: postRef.id,
				notes: [],	
				serverTimestamp: new Date(),	// for REDUX, use new Date() and not serverTimestamp 
				labels: []
			}
		})		

		// dispatch(successMessage("post created successfully."))		
		dispatch(successMessage(`post created successfully at ${destinationRoomTitle} `))	

		!owner && dispatch({	// if at shared room, no need update redux
	  		type: UPDATE_ROOM_COUNT_AND_ORDER,  //so that we don't need to fetch getRooms every time
	  		payload: {
	  			room: selectedwarroom,
	  			increment: 1
	  		}
	  	})			
	} catch (e) {
		dispatch(errorMessage(`failed to create post: ${e.message}`))
	}
}

// helper function (used only in createPost function, not in frontend)  
// image is treated as a special type of link post
export const createImagePost = (props) => async (dispatch) => {
	const { url, userUid, selectedwarroom, destinationRoomTitle, owner, serverTimestamp } = props;

	const batch = db.batch();
	const newDocRef = db.collection('users').doc(userUid).collection('posts').doc();
	const roomRef = db.collection('users').doc(userUid).collection("warrooms").doc(selectedwarroom)

	try {
	  	batch.set(newDocRef, {
			title: "untitled image",
			desc: "no description",
			thumbnail: url, 
			url: url,
			room: selectedwarroom,
			serverTimestamp: serverTimestamp	
	  	})	
		batch.update(roomRef, {
			createdAt: serverTimestamp			
		})		

		await batch.commit()

		dispatch({
			type: owner ? "CREATE_POST_SHARED" :  CREATE_POST,  
			payload: {
				title: "untitled image",
				desc: "no description",
				room: selectedwarroom,
				docId: newDocRef.id,
				thumbnail: url,
				url: url,
				notes: [],	
				serverTimestamp: new Date(),	
				labels: []		
			}
		})	

		dispatch(successMessage(`post created successfully at ${destinationRoomTitle} `))	

  	!owner && dispatch({
  		type: UPDATE_ROOM_COUNT_AND_ORDER,  //so that we don't need to fetch getRooms every time
  		payload: {
  			room: selectedwarroom,
  			increment: 1
  		}
  	})		
  	
	} catch (e) {
		dispatch(errorMessage(`failed to create post: ${e.message}`))
	}
}


// helper function used in movePostToSharedRoom only (since email post can't be created directly on web - it's for MOVING ONLY)
export const moveEmailPostToShared = (props) => async (dispatch) => {
	const { post, room, owner, serverTimestamp } = props;

	try {
		await db.collection('users').doc(owner).collection('posts')
			.add({
				desc: post.desc,
				descEmailText: post.descEmailText,
				title: post.title,
				room: room,
				serverTimestamp: serverTimestamp,
				source: "email",
				...post.notes && post.notes.length > 0 && {notes: firebase.firestore.FieldValue.arrayUnion(...post.notes)}
			})

		dispatch(successMessage("post moved successfully."))
	} catch (e) {
		dispatch(errorMessage(`failed to move post to Shared Room: ${e.message}`))
	}
}

// used in movePostToSharedRoom only; create without scraping again; 
export const moveLinkPostToShared = (props) => async (dispatch) => {
	const { post, room, owner, serverTimestamp } = props;

	try {
		await db.collection('users').doc(owner).collection('posts')
			.add({
				desc: post.desc,
				title: post.title,
				url: post.url,
				thumbnail: post.thumbnail,
				room: room,
				serverTimestamp: serverTimestamp,
				...post.notes && post.notes.length > 0 && {notes: firebase.firestore.FieldValue.arrayUnion(...post.notes)}
			})

		dispatch(successMessage("post moved successfully."))
	} catch (e) {
		dispatch(errorMessage(`failed to move post to Shared Room: ${e.message}`))
	}
}

// used in movePostToSharedRoom only
export const moveNotePostToShared = (props) => async (dispatch) => {
	const { post, room, owner, serverTimestamp } = props;

	try {
		await db.collection('users').doc(owner).collection('posts')
			.add({
				desc: post.desc, // notepost when moved pass in new desc
				room: room,
				serverTimestamp: serverTimestamp,
			})
		dispatch(successMessage("post moved successfully."))
	} catch (e) {
		dispatch(errorMessage(`failed to move post to Shared Room: ${e.message}`))
	}
}

export const isDuplicateLink = (url, posts, room) => {
	let isDuplicate = false;
	for(let i = 0; i < posts.length; i++) {
	    if (posts[i].url === url && posts[i].room === room) {	// check in the same room only
	        isDuplicate = true;
	        break;
	    }
	}
	return isDuplicate
}

export const isDuplicateRoom = (title, rooms) => {
	let isDuplicate = false;
	for(let i = 0; i < rooms.length; i++) {
	    if (rooms[i].title === title) {	
	        isDuplicate = true;
	        break;
	    }
	}
	return isDuplicate	
}

// userUidMaster needs to be invoked rather than referenced directly, because it changes when user signup / login
export const userUidMaster = (owner) => {
	return owner || JSON.parse(localStorage.getItem('user'))?.uid || firebase.auth().currentUser?.uid || null
}
export const userEmailMaster = (owner) => {
	return owner || JSON.parse(localStorage.getItem('user'))?.email || firebase.auth().currentUser?.email || null
}
export const userObjectMaster = (owner) => {  // for cases where we need displayName as well
	return owner || JSON.parse(localStorage.getItem('user')) || firebase.auth().currentUser || null
}

// used if user save At self room TO shared room,  to get room owner and originalRoomId
export const derivedOwner= (value) => {	// `${room.originalRoomId}-${room.owner}-sharedroom`   get owner
	const indexOfDash = value.indexOf("-")
	const lastIndexOfDash = value.lastIndexOf("-")
	return value.slice(indexOfDash + 1, lastIndexOfDash) 
}	

// used if user save At self room TO shared room,  to get room owner and originalRoomId
export const derivedOwnerRoom = (value) => {	// `${room.originalRoomId}-${room.owner}-sharedroom`   get originalRoomId
	const indexOfDash = value.indexOf("-")
	return value.slice(0, indexOfDash) 
}

// update count of posts in a room every time a post is MOVED (for create and delete, we have cloud function)
export const updateRoomCountFirebaseAndReorderRooms = (room, increment) => async (dispatch) => {
	const userUid = userUidMaster();

	try {
		await db.collection('users').doc(userUid)
			.collection("warrooms")
			.doc(room)
			.update({
				count: firebase.firestore.FieldValue.increment(increment),
			})

		dispatch({
			type: UPDATE_ROOM_COUNT_AND_ORDER,  //so that we don't need to fetch getRooms every time
			payload: {
				room: room,
				increment: increment
			}
		})
	} catch (e) {
		console.log(`failed to update room count and reorder: ${e.message}`)
	}
}

// used at createRoom, editRooms etc;  send ROOMS only
// not sending token
export const sendRoomsToExtension = (userUid, tempRooms) => (dispatch) => {

	tempRooms.forEach((room) => {
		if (room.originalRoomId) {
			room.title = `(shared) ${room.roomTitle}`;
			room.roomId = `${room.originalRoomId}-${room.owner}-sharedroom`
		} 
		// above is to pass in sharedRooms in the correct format
	})

	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	// const chromeExtId = 'eobhhbghffhlbfbpngljpmakaflgbddi'; 

	if (isChrome) {
		chrome.runtime.sendMessage(chromeExtId, { type: "roomsFromWeb", rooms: tempRooms });
	} else {
		window.postMessage({ type: "roomsFromWeb", rooms:tempRooms }, window.parent.origin);
	}
}     
 
//send TOKEN and ROOMS; used at GetRoomsMaster only
export const sendTokenToExtension = (userUid, tempRooms) => (dispatch) => {
	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	const chromeExtId = 'eobhhbghffhlbfbpngljpmakaflgbddi'; 

     firebase.functions().httpsCallable('createToken')(userUid).then((token) => {
         if (isChrome) {
           chrome.runtime.sendMessage(chromeExtId, { type: "token", text: token.data, rooms: tempRooms });
         } else {
           window.postMessage({ type: "token", text: token.data, rooms:tempRooms }, window.parent.origin);
         }
     }).catch((error) => {
         console.log(error);
     })   
	// const tokenForExtension = 
	// localStorage.getItem('tokenForExtension') 
	// ? JSON.parse(localStorage.getItem('tokenForExtension')).token 
	// : null

	//     if (tokenForExtension) {
	//         if (isChrome) {
	//           chrome.runtime.sendMessage(chromeExtId, { type: "token", text: tokenForExtension, rooms: tempRooms });
	//         } else {
	//           window.postMessage({ type: "token", text: tokenForExtension, rooms:tempRooms }, window.parent.origin);
	//         }    	
	//     } else {
	// 	    firebase.functions().httpsCallable('createToken')(userUid).then((token) => {
	//             localStorage.setItem('tokenForExtension', JSON.stringify({
	//               token: token.data
	//             }));  		    	
	// 
	// 	        if (isChrome) {
	// 	          chrome.runtime.sendMessage(chromeExtId, { type: "token", text: token.data, rooms: tempRooms });
	// 	        } else {
	// 	          window.postMessage({ type: "token", text: token.data, rooms:tempRooms }, window.parent.origin);
	// 	        }
	// 	    }).catch((error) => {
	// 	        console.log(error);
	// 	    })   	    	
	//     }
}

export const successMessage = (message) => ({
	type: SUCCESS_MESSAGE, 
	payload: {
		message: message,
		type: "success"
	}
})

export const errorMessage = (message) => ({
  	type: ERROR_MESSAGE,
	payload: {
		message: message,
		type: "danger"
	}
})

// use "primary" because warning is yellow and hard to see in our bg
export const warningMessage = (message) => ({
  	type: ERROR_MESSAGE,
	payload: {
		message: message,
		type: "primary"
	}
})

// use in userActions
export const signupFail = (message) => ({
  	type: SIGNUP_FAIL,
	payload: {
		message: message,
		type: "danger"
	}
})
// use in userActions - userSignupSubmit and userSignupWithGoogle
export const batchActionUponSignup = (props) => {
	const { 
		result, batch, email, name, 
		docRef_1, docRef_2, docRef_3
	} = props;
  
	const userRef = db.collection('users').doc(result.user.uid)
	const roomRef = db.collection('users').doc(result.user.uid).collection('warrooms').doc('braindump')
	// const docRef_1 = db.collection('users').doc(result.user.uid).collection("posts").doc()
	// const docRef_2 = db.collection('users').doc(result.user.uid).collection("posts").doc()
	// const docRef_3 = db.collection('users').doc(result.user.uid).collection("posts").doc()

 	batch.set(userRef, {	// somehow this causes some error message in console log but it doesn't affect general user flow
 		email: email,
 		name: name,
 		lastDeleteTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
 		joinTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
 		isSubscribedMonthly: true,
 		isSubscribedWeekly: true,
 		recommendations: ["0","1","2"],
 
 		userNeedsOnboardingAtHome: true,       // NEW     
 		userNeedsOnboardingAtRoom: true, 
 		userNeedsOnboardingAtProfile: true, 
 	})
	batch.set(roomRef, {
		title: "Desk",
		roomId: "braindump",
		isActive: true,
		createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		count: firebase.firestore.FieldValue.increment(0)         
	})     
	batch.set(docRef_1, {
		url: `https://www.notion.so/Osoji-User-Guide-c107875c42de4e50aacc9058536cf71a`,
		title: "📱 User Guide - Install Osoji App",
		thumbnail: "https://osoji.io/images/osoji-on-the-go.png",
		desc: "This is a link post. Click the link 👇 for User Guide. Click here to pin notes and highlights to your post.",
		room: "braindump",
		notes: ["example of notes within a link", "you can use it for highlights and snippets"],
		serverTimestamp: new Date(Date.now() - 90)   // this is to ensure the order of posts are correct 
	})
	batch.set(docRef_2, {
		desc: seedPostDesc.join(""),
		room: "braindump",
		serverTimestamp: new Date(Date.now() - 50)     
	})
	batch.set(docRef_3, {
        url: `https://osoji.io/extensions`,
        title: "Osoji Browser Extension",
        thumbnail: "https://osoji.io/images/extension/extension-thumbnail.png",
        desc: "Save links, text snippets, images with 1-click on your browser: Chrome, Firefox, Brave, Edge",
        room: "braindump",   
        notes: ["example of notes within a link", "you can use it for highlights and snippets"],
        serverTimestamp: new Date(Date.now() - 10)  // show this first (hence latest)
	})
}

export const batchCreatePostsInReduxUponSignup = (props) => (dispatch) => {
	const {
		docRef_1, docRef_2, docRef_3
	} = props

	try {
	     dispatch({
	         type: CREATE_POST,   
	         payload: {
	             desc: "This is a link post. Click the link 👇 for User Guide. Click here to pin notes and highlights to your post.",
	             title: "📱 User Guide - Install Osoji App",
	             room: "braindump",
	             thumbnail: "https://osoji.io/images/osoji-on-the-go.png",
	             url: `https://www.notion.so/Osoji-User-Guide-c107875c42de4e50aacc9058536cf71a`,
	             docId: docRef_1.id,    
	             notes: ["example of notes within a link", "you can use it for highlights and snippets"],    
	             serverTimestamp: new Date(),
	             pin: false, 
	             labels: [],    
	             minimize: false,
	         }
	     })    
	     dispatch({
	         type: CREATE_POST,  
	         payload: {
	            desc: seedPostDesc.join(""),
	            room: "braindump",
	            docId: docRef_2.id,    
	            serverTimestamp: new Date(),
	            notes: [],
	            pin: false, 
	            labels: [],       
	            minimize: false, 
	         }
	     })  	
	     dispatch({
	         type: CREATE_POST,   
	         payload: {
	             desc: "Save links, text snippets, images with 1-click on your browser: Chrome, Firefox, Brave, Edge",
	             title: "Osoji Browser Extension",
	             room: "braindump",
	             thumbnail: "https://osoji.io/images/landing/new/save.webp",
	             url: `https://osoji.io/extensions`,
	             docId: docRef_3.id,    
	             notes: ["example of notes within a link", "you can use it for highlights and snippets"],  
	             serverTimestamp: new Date(),
	             pin: false, 
	             labels: [],    
	             minimize: false,
	         }
	     })  	     		
	 } catch (e) {
	 	console.log("ERROR IN CREATE BATCH: ", e.message || "")
	 }

}

// use in userActions - userSignupSubmit 
export const sendVerificationAndWelcomeEmails = async ({ email, name }) => {
	const actionCodeSettings = {	// changed from /onboarding
		url: `https://osoji.io/home`
	}

	await firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)

	await firebase.functions().httpsCallable('sendWelcomeEmail')({  // cloud function to send Welcome email
			email: email, 
			displayName: name
		})

	console.log("email verification sent") 	
}

