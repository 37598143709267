import userReducer from './userReducer';
import contentReducer from './contentReducer';
import alertReducer from './alertReducer';
import adminReducer from './adminReducer';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    userReducer, contentReducer, alertReducer, adminReducer
})

export default rootReducer