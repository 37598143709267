import { useDispatch } from 'react-redux';
import allActions from "actions";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
// import './changeroommodal.css';

// used in PostListing (single delete) and FixedNavbarWithSelectedPosts (group delete)
// also used in Archive
const DeleteConfirmModal = ({ postToDelete, modalDeletePost, toggleDeletePost, owner, isAtArchive }) => {
	const dispatch = useDispatch();

	let isGroupDelete = false;
	if (postToDelete && !postToDelete.docId) isGroupDelete = true;

	const handleDeleteClick = () => {
  		toggleDeletePost()

  		if (isGroupDelete) {
			dispatch(allActions.contentActions.deletePostsInGroup({
				selectedPosts: postToDelete, // postToDelete here is selectedPosts
				isAtArchive: isAtArchive
			}))  			
  		} else {
	  		dispatch(allActions.contentActions.deletePost({
				post: postToDelete, 
				owner: owner, 
				isMoving: false				  			
	  		}))  			
  		}
	}

	return (
		<Modal size="md" isOpen={modalDeletePost} toggle={toggleDeletePost}>
			<ModalHeader toggle={toggleDeletePost} className="modal-general">Delete Post</ModalHeader>
			
			<ModalBody className="modal-general">
				<div>
					{isGroupDelete
						? "Are you sure you want to permanently delete these posts?"
						: "Are you sure you want to permanently delete this post?"
					}
				</div>					
			</ModalBody>	

			<ModalFooter className="modal-general">
				  <Button 
				  	outline
				  	onClick={() => toggleDeletePost()}
				  >Cancel</Button>{' '}

				  <Button 
				  	color="danger"
				  	onClick={handleDeleteClick}
				  >Delete</Button>	
  		  
			</ModalFooter>								
		</Modal>		
	)
}


export default DeleteConfirmModal;