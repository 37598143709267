import { 
	CREATE_POST, DELETE_POST, UPDATE_POST, GET_POSTS_PUBLIC, 
	GET_POSTS_PENDING, GET_POST_DETAILS, UPDATE_POST_DETAILS,
	UPDATE_ROOM_COUNT_AND_ORDER, 
	ADD_NOTE_IN_POST, DELETE_NOTE_IN_POST, EDIT_NOTE_IN_POST,
	SEARCH_POSTS, GET_ALL_POSTS,
} from 'constants/constants';
import {
	userUidMaster, userEmailMaster, derivedOwner, derivedOwnerRoom,
	successMessage, errorMessage, warningMessage, 
	isDuplicateLink, updateRoomCountFirebaseAndReorderRooms,
	moveEmailPostToShared, moveLinkPostToShared, moveNotePostToShared,
	cleanUrlForCreatePost, isNewPostAnUrl,
	createLinkPost, createNotePost, createImagePost,
	postStructure, postDetailsStructure, getDataFromEachPost, getVariablesForCreatePost
} from './helperFunctions'
import { isImagePost } from "utils/whatPostTypeIsThis";
import firebase, { db } from 'config/Firebase';
import moment from 'moment';


const year = `y${moment().format('YYYY')}`	// used for user stats update. add a y in front because number as key is tricky
const month = moment().format('MMMM')	// used for user stats update


// ===========================================================================
//                            CRUD 1 POST
// ===========================================================================

// MASTER CREATE POST used in AddPostCard / AddPostModal component (which is in PostListingNew)
/*
	When at Shared Room, user can ONLY add to Shared Room
		ownerDirectFromSharedRoom means use AT shared room;  not save TO shared room from own room
	if there is warroomforpost value, it means user is saving from own's room to a different room (self or shared)
		if shared, 	warroomforpost value will include "-sharedroom" and ownerId and originalRoomId which we then derive
	for more details, see google doc Osoji Master sheet --> tech details	
*/
const createPost = (props) => (dispatch) => { 
	const { newpost, currentRoom, currentTitle, ownerDirectFromSharedRoom, posts, sharedPosts } = props; 

	/*  >>>>>   VARIABLES   <<<<<<     */
	const {
		isSavingFromOwnRoomToSharedRoom, owner, selectedwarroom, destinationRoomTitle
	} = getVariablesForCreatePost({ ownerDirectFromSharedRoom, currentRoom, currentTitle });

	const userUid = userUidMaster(owner);		
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

	/*   >>>>>   MAIN CHECK START HERE  <<<<<<      */
	// if blank
	if (newpost === "<p><br></p>") return dispatch(errorMessage("please add a link or text")) // reject if blank	

	// IF (linkpost, youtube, image, etc) i.e. not Note
	if (isNewPostAnUrl(newpost)) {
		// remove <p></p> amd add https
		const cleanUrl = cleanUrlForCreatePost(newpost); 

		 // check Duplicate
		const whichPostsToUse = (ownerDirectFromSharedRoom || isSavingFromOwnRoomToSharedRoom) ? sharedPosts : posts;
 		if (isDuplicateLink(cleanUrl, whichPostsToUse, selectedwarroom)) return dispatch(warningMessage("This link already exists in this room.")); 
 
		// if it is an IMAGE, no need to scrape
		if (isImagePost(cleanUrl)) dispatch(createImagePost({
			url: cleanUrl, 
			userUid: userUid, 
			selectedwarroom: selectedwarroom, 
			destinationRoomTitle: destinationRoomTitle, 
			owner: owner, 
			serverTimestamp: serverTimestamp			
		}))
		// for post that needs scraping: link post, youtube post, twitter post
		else dispatch(createLinkPost({
			url: cleanUrl, 
			userUid: userUid, 
			selectedwarroom: selectedwarroom, 
			destinationRoomTitle: destinationRoomTitle, 
			owner: owner, 
		}))

	} else {	// IF TEXT POST		
		dispatch(createNotePost({
			newpost: newpost, 
			userUid: userUid, 
			selectedwarroom: selectedwarroom, 
			destinationRoomTitle: destinationRoomTitle, 
			owner: owner, 
			serverTimestamp: serverTimestamp			
		}))
	}	
}

// used in DeleteConfirmModal, EditPostModal, movePostToSharedRoom,
const deletePost = (props) => async (dispatch) => {
	const { post, owner, isMoving } = props;

	const userUid = userUidMaster(owner)

	try {
		await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(post.docId)
		    .delete()

		// note that due to onsnapshot, delete_post fires multiple times. But no worries.   
		 dispatch({
	     	type: owner ? "DELETE_POST_SHARED" :  DELETE_POST,  
	     	payload: post.docId
	     })    

	    !isMoving && dispatch(successMessage("post deleted successfully."));

	    if (post.labels?.length > 0) dispatch({ // new
	    	type: "UPDATE_LABELS_AFTER_DELETE_POST",
	    	payload: { 
	    		labels: post.labels
	    	}
	    })

	    dispatch({
	  		type: UPDATE_ROOM_COUNT_AND_ORDER,  //this updates redux only so that we don't need to fetch getRooms every time; cloud is auto updated
	  		payload: {
	  			room: post.room,
	  			increment: -1
	  		}
	  	})		
	} catch (e) {
		dispatch(errorMessage(`failed to delete post: ${e.message}`))
	}
}

// used in editLinkPost, editNotePost, editEmailPost and changeRoomForPost
const movePostToSharedRoom = (props) => (dispatch) => {
	const { docId, post, room, owner } = props
	// delete, then create
	dispatch(deletePost({
		post: { docId: docId }, 
		owner: null, 
		isMoving: true
	})) 	// arguments are post object, owner, and isMoving: so that if moving to shared room, don't show delete message

	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

	const propsForHelperFunction = {
		post: post,
		room: room,
		owner: owner,
		serverTimestamp: serverTimestamp
	}

	if (post.source === "email") dispatch(moveEmailPostToShared(propsForHelperFunction))  
	else if (post.url) dispatch(moveLinkPostToShared(propsForHelperFunction))
	else dispatch(moveNotePostToShared(propsForHelperFunction))
}

// use in ChangeRoomModal.js
const changeRoomForPost = (props) => async (dispatch) => {
	const { post, selectedwarroom, posts, sharedPosts } = props;
	// can't move from owner's room to your room
	// can move from your room to owner's
	// different from EditLinkPost is that this action can't be done in a shared room
	const isMovingToSharedRoom = selectedwarroom.includes("-sharedroom");
	if (isMovingToSharedRoom) {
		// check duplicate if moving to shared rooms
		if (post.url && isDuplicateLink(post.url, sharedPosts, derivedOwnerRoom(selectedwarroom))) return dispatch(warningMessage("This link already exists in this room.")) 
	
		return dispatch(movePostToSharedRoom({
			docId: post.docId, 
			post: post, 
			room: derivedOwnerRoom(selectedwarroom), 
			owner: derivedOwner(selectedwarroom)
		}))	// don't execute the below
	}
	// check duplicate if moving to own rooms
	if (post.url && isDuplicateLink(post.url, posts, selectedwarroom) && post.room !== selectedwarroom) { // added last condition for user to move within a room and update timestamp
		return dispatch(warningMessage("You have already saved this link in this room.")) 
	}

	const userUid = userUidMaster();
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
	const batch = db.batch();

  	const docRef = db.collection('users').doc(userUid).collection('posts').doc(post.docId);
	const roomRef = db.collection('users').doc(userUid).collection("warrooms").doc(selectedwarroom)

	try {
	  	batch.update(docRef, {
	  		room: selectedwarroom,
	  		serverTimestamp: serverTimestamp
	  	})	
		batch.update(roomRef, {
			createdAt: serverTimestamp			
		})		

		await batch.commit()	

		dispatch({
			type: UPDATE_POST, 	
			payload: {
				post: post,
				new: {
			  		room: selectedwarroom,
			  		serverTimestamp: new Date()				
				}		
			}
		})

		dispatch(successMessage("post moved successfully."))
		dispatch(updateRoomCountFirebaseAndReorderRooms(post.room, -1)) // we need this because this is moving. Cloud onlu update on craete and delete
		dispatch(updateRoomCountFirebaseAndReorderRooms(selectedwarroom, 1))  

	} catch (e) {
		dispatch(errorMessage(`failed to move post: ${e.message}`))
	}
}

// used in EditPostModal (which is at PostListingNew (Home and Warroom) and separately at PostDetailsPage)
const editLinkPost = (props) => async (dispatch) => {
	const { post, owner, posts, sharedPosts, isAtPostDetailsPage } = props;

	const userUid = userUidMaster(owner);
	const newtitle = document.getElementById('editposttitle').value;
	const newdesc = document.getElementById('editpostdesc').value;

	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
	const selectedwarroom = owner ? post.room : document.getElementById('warroomforedit').value;

	// moving post to owner's room   selectedwarroom value will be `${room.originalRoomId}-${room.owner}-sharedroom`
	const isMovingToSharedRoom = document.getElementById('warroomforedit')?.value?.includes("-sharedroom")
	if (isMovingToSharedRoom) {
		// check duplicate if moving to shared rooms
		if (isDuplicateLink(post.url, sharedPosts, derivedOwnerRoom(selectedwarroom))) return dispatch(warningMessage("This link already exists in this room.")) 

		const newPost = {
			desc: newdesc,
			title: newtitle,
			url: post.url,
			thumbnail: post.thumbnail,
			notes: post.notes	// new  				
		}

		return dispatch(movePostToSharedRoom({
			docId: post.docId, 
			post: newPost, 
			room: derivedOwnerRoom(selectedwarroom), 
			owner: derivedOwner(selectedwarroom)
		}))
	}

	if ((post.room !== selectedwarroom) && isDuplicateLink(post.url, posts, selectedwarroom)) { // only check if moving room
		return dispatch(warningMessage("You have already saved this link in this room.")) 
	}

	// PENDING:  ADD A CONDITION IF ROOM IS NOT CHANGED OR description/title NOT CHANGED
	try {
		await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(post.docId)
		    .update({
		  		title: newtitle,
		  		desc: newdesc,
		  		room: selectedwarroom,
		  		serverTimestamp: serverTimestamp
		  	})

		if (isAtPostDetailsPage) {	
			dispatch({
				type: UPDATE_POST_DETAILS,
				payload: {
			  		title: newtitle,
			  		desc: newdesc,
			  		room: selectedwarroom,	
			  		serverTimestamp: new Date()	
				}
			})			
		}

		dispatch({
			type: owner ? "UPDATE_POST_SHARED" :  UPDATE_POST, 	
			payload: {
				post: post,
				new: {
			  		title: newtitle,
			  		desc: newdesc,
			  		room: selectedwarroom,
			  		serverTimestamp: new Date()					
				}		
			}
		})				  		    

		dispatch(successMessage("post edited successfully."))

		if (!owner && (post.room !== selectedwarroom)) {	// no need if shared room; and only if post changes room
			// LAZY TO ADD: update room createdAt (similar to create post and move)	

			dispatch(updateRoomCountFirebaseAndReorderRooms(post.room, -1)) 		// minus old room count
			dispatch(updateRoomCountFirebaseAndReorderRooms(selectedwarroom, 1))  	// plus new room count

			// OLD LOGIC -- REMOVE LATER
			if (post.room === "braindump") {			// only if user moves post from braindump to warroom
				db.collection('users').doc(userUid)
				.update({
					[`stats.` + year + `.` + month + `.PostDeleted.` + post.room]: firebase.firestore.FieldValue.increment(1),	// braindump delete + 1
					[`stats.` + year + `.` + month + `.PostAdded.` + selectedwarroom]: firebase.firestore.FieldValue.increment(1) // warroom add + 1
				})						
			}
		}	

	} catch (e) {
		dispatch(errorMessage(`failed to edit post: ${e.message}`))
	}
}

const editTextPost = (props) => async (dispatch) => {
	const { post, newdesc, owner } = props;

	const userUid = userUidMaster(owner);
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
	const selectedwarroom = owner ? post.room : document.getElementById('warroomforedit').value;

	// hack to remove trailing spaces that causes issue with draft.js
	const cleanNote = newdesc.replace(/&nbsp;/g, "");

	// moving post to owner's room   selectedwarroom value will be `${room.originalRoomId}-${room.owner}-sharedroom`
	const isMovingToSharedRoom = document.getElementById('warroomforedit')?.value?.includes("-sharedroom")

	if (isMovingToSharedRoom) {
		const newPost = {
			desc: cleanNote, // notepost when moved pass in new desc		
		}

		return dispatch(movePostToSharedRoom({
			docId: post.docId, 
			post: newPost, 
			room: derivedOwnerRoom(selectedwarroom), 
			owner: derivedOwner(selectedwarroom)
		}))
	}
	
	try {
		await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(post.docId)
		    .update({
		  		desc: cleanNote,
		  		room: selectedwarroom,
		  		serverTimestamp: serverTimestamp
		  	})

		dispatch({
			type: owner ? "UPDATE_POST_SHARED" :  UPDATE_POST, 	
			payload: {
				post: post,
				new: {
			  		desc: cleanNote,
			  		room: selectedwarroom,	
			  		serverTimestamp: new Date()				
				}								
			}
	    })    
		
		dispatch(successMessage("post edited successfully."))  	  

		if (!owner && (post.room !== selectedwarroom)) {	// no need if shared room; and only if post changes room
			dispatch(updateRoomCountFirebaseAndReorderRooms(post.room, -1)) 		// minus old room count
			dispatch(updateRoomCountFirebaseAndReorderRooms(selectedwarroom, 1))  	// plus new room count

			if (post.room === "braindump") {			// only if user moves post from braindump to warroom
				db.collection('users').doc(userUid)
				.update({
					[`stats.` + year + `.` + month + `.PostDeleted.` + post.room]: firebase.firestore.FieldValue.increment(1),	// braindump delete + 1
					[`stats.` + year + `.` + month + `.PostAdded.` + selectedwarroom]: firebase.firestore.FieldValue.increment(1) // warroom add + 1
				})						
			}
		}	

	} catch (e) {
		dispatch(errorMessage(`failed to edit post: ${e.message}`))
	}
}
// update title only
const editEmailPost = (props) => async (dispatch) => {
	const { post, owner } = props;

	const userUid = userUidMaster(owner);
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
	const newtitle = document.getElementById('editposttitle')?.value;		
	const selectedwarroom = owner ? post.room : document.getElementById('warroomforedit').value;

	// moving post to owner's room   selectedwarroom value will be `${room.originalRoomId}-${room.owner}-sharedroom`
	const isMovingToSharedRoom = document.getElementById('warroomforedit')?.value?.includes("-sharedroom")
	if (isMovingToSharedRoom) {
		const newPost = {...post, ...{title: newtitle}}

		return dispatch(movePostToSharedRoom({
			docId: post.docId, 
			post: newPost, 
			room: derivedOwnerRoom(selectedwarroom), 
			owner: derivedOwner(selectedwarroom),
		}))
	}

	try {
		await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(post.docId)
		    .update({
		  		title: newtitle,
		  		room: selectedwarroom,
		  		serverTimestamp: serverTimestamp
		  	})	   

		dispatch({
			type: owner ? "UPDATE_POST_SHARED" :  UPDATE_POST, 	
			payload: {
				post: post,
				new: {
			  		title: newtitle,
			  		room: selectedwarroom,
			  		serverTimestamp: new Date()					
				}		
			}
		})		    
		
		dispatch(successMessage("post edited successfully."))

		if (!owner && (post.room !== selectedwarroom)) {	// no need if at shared room; and only if post changes room
			dispatch(updateRoomCountFirebaseAndReorderRooms(post.room, -1)) 		// minus old room count
			dispatch(updateRoomCountFirebaseAndReorderRooms(selectedwarroom, 1))  	// plus new room count

			if (post.room === "braindump") {			// only if user moves post from braindump to warroom
				db.collection('users').doc(userUid)
				.update({
					[`stats.` + year + `.` + month + `.PostDeleted.` + post.room]: firebase.firestore.FieldValue.increment(1),	// braindump delete + 1
					[`stats.` + year + `.` + month + `.PostAdded.` + selectedwarroom]: firebase.firestore.FieldValue.increment(1) // warroom add + 1
				})						
			}
		}	

	} catch (e) {
		dispatch(errorMessage(`failed to edit post: ${e.message}`))
	}	
}


// for creating notes IN a post details page
const createNoteInPost = (props) => async (dispatch) => {
	const { id, room, owner, note } = props;

	const userUid = userUidMaster(owner);

	try {
		await db.collection('users').doc(userUid)
			.collection("posts").doc(id)
			.update({
				notes: firebase.firestore.FieldValue.arrayUnion(note)
			})

		dispatch({
			type: owner ? "ADD_NOTE_IN_POST_SHARED" :  ADD_NOTE_IN_POST,
			payload: {
				note: note,
				docId: id
			}
		})	

		dispatch(successMessage("Note added. Nice!"))

		if (!owner) {
			db.collection('users').doc(userUid)
			.update({
				[`stats.` + year + `.` + month + `.InsightAdded.` + room]: firebase.firestore.FieldValue.increment(1) // warroom add + 1
			})					
		}

	} catch (e) {
		dispatch(errorMessage(`error in adding note: ${e.message}`))
	}
}

const deleteNoteInPost = (props) => async (dispatch) => {
	const { id, note, owner } = props;

	const userUid = userUidMaster(owner);

	try {
		await db.collection('users').doc(userUid)
			.collection("posts").doc(id)
			.update({
				notes: firebase.firestore.FieldValue.arrayRemove(note)
			})

		dispatch({
			type: owner ? "DELETE_NOTE_IN_POST_SHARED" :  DELETE_NOTE_IN_POST,
			payload: {
				note: note,
				docId: id
			}
		})	

		dispatch(successMessage("Note deleted successfully."))

	} catch (e) {
		dispatch(errorMessage(`error in deleting room: ${e.message}`))
	}
}

const editNoteInPost = (props) => async (dispatch) => {
	const { id, oldnote, owner, newnote } = props;

	const userUid = userUidMaster(owner);

	const docRef = db.collection('users').doc(userUid).collection("posts").doc(id)

	try {
		await docRef.update({		// handle later
			notes: firebase.firestore.FieldValue.arrayRemove(oldnote)
		})	
		await docRef.update({
			notes: firebase.firestore.FieldValue.arrayUnion(newnote)
		})	
		dispatch({
			type: owner ? "EDIT_NOTE_IN_POST_SHARED" :  EDIT_NOTE_IN_POST,
			payload: {
				oldnote: oldnote,
				newnote: newnote,
				docId: id
			}
		})
		dispatch(successMessage("Note edited successfully."))
	} catch (e) {
		dispatch(errorMessage(`failed to edit note: ${e.message}`))
	}
}


// ===========================================================================
//                            LIST OF POSTSSS
// ===========================================================================

/* CHANGES FOR GET ALL POSTS
1)  all posts are from getAllPostsSnapshot // except seed posts during onboarding
*/

// use in App:  it handles getAllPosts and auto update when adding post from elsewhere
const getAllPostsSnapshot = () => (dispatch) => {
	const userUid = userUidMaster();
	// trigger loading UI
	dispatch({ type: GET_POSTS_PENDING })

    let postsHolder = [];
    let labelsHolder = new Map();
    let triggerGetAllPosts = false;

	db.collection('users').doc(userUid)
	.collection("posts")
	.orderBy("serverTimestamp", "desc")
	.onSnapshot(async (querySnapshot) => {	
        // below WORKS for detecting change;   but it also shows everything the first time, hence our conditions
        if (querySnapshot.docChanges().length === 1)  {		// if adding (haven't handled remove)
        	querySnapshot.docChanges().forEach((change) => {
        		let post = change.doc;

        		if (change.type === "added") {
 				// NEW: this includes if user has only ONE POST
					dispatch({
						type: CREATE_POST,   // HANDLE OWNER & CREATE_SHARED_POST LATER
						payload: postStructure({
							post: post
						})
					})				       			
        		}

        	  	if (change.type === "removed") {
        	  		dispatch({
        	  			type: DELETE_POST,  // handle "DELETE_POST_SHARED" later
    					payload: post.id // not docId
        	  		})
        	  	} 
        		// if (change.type === "modified") {}
        	})

        } else if ((querySnapshot.docChanges().length === 0)){ // TEST to fix signup error??
         	triggerGetAllPosts = false;
	         dispatch({	// NEW SEPT 2021: in case user has 0 posts
			 	type: "STOP_LOADING",
			 })        	   

	    // INITIAL FETCHING: GET ALL POSTS    
	    } else if (querySnapshot.docChanges().length === querySnapshot.size) { 
        	querySnapshot.docChanges().forEach((change) => {
        		let post = change.doc;
        		if (change.type === "added") {
        			getDataFromEachPost({
        				post: post, 
        				postsHolder: postsHolder, 
        				labelsHolder: labelsHolder
        			})    
        		} 
        	})

        	if (querySnapshot.docChanges()[0]?.type === "added") triggerGetAllPosts = true;	// handle edge case to prevent this when user deletes 3 out of 6 total posts    

        // Group Delete or somehow has multiple new ones	
	    } else {		
        	querySnapshot.docChanges().forEach((change) => {
        		let post = change.doc;
        		if (change.type === "added") { // if somehow has multiple added // if group delete/remove, no need to do anything
        			dispatch({
						type: CREATE_POST,   
						payload: postStructure({
							post: post
						})
				})}	
        	})
        	triggerGetAllPosts = false;
        }

        if (triggerGetAllPosts === true) {	// additional logic so get_all_posts happen first time only	     
	        dispatch({
				type: GET_ALL_POSTS,
				payload: {
					posts: postsHolder,
					labels: labelsHolder
				}	
			})        	
			triggerGetAllPosts = false;
        }
        // postsHolder.length = 0; // pending: reset postsHolder
    })   
}

// used in onboarding only due to delay (and also Navbar): as a BACKUP
const getAllPosts = () => (dispatch) => {
	const userUid = userUidMaster();
	// trigger loading UI
	dispatch({ type: GET_POSTS_PENDING })

	let postsHolder = [];
	let labelsHolder = new Map();

	db
	.collection('users').doc(userUid)
	.collection("posts")
	.orderBy("serverTimestamp", "desc")
	.get()
	.then(function(posts) {	
		posts.forEach((post) => getDataFromEachPost({
			post: post,
			postsHolder: postsHolder,
			labelsHolder: labelsHolder			
		}))

		dispatch({
			type: GET_ALL_POSTS,
			payload: {
				posts: postsHolder,
				labels: labelsHolder
			}	
		})
	})	
}

const loadingWhileGetAllPosts = () => dispatch => dispatch({ type: GET_POSTS_PENDING })

// get posts for a room IF PUBLIC, called from getRoomDetailsPublic function, not from component
const getPostsPublic = (room, owner) => async (dispatch) => {
	// function getDataFromEachPublicPost (post) {
	// 	postsHolder.push(postStructure({ 
	// 		post: post,
	// 		owner: owner 
	// 	}))		
	// }
 	
 	dispatch({ type: GET_POSTS_PENDING })

	const postsHolder = [];	 	
 	let labelsHolder = new Map();

 	try {
		const posts = await db.collection('users').doc(owner)
			.collection("posts")
			.where("room", "==", room)
			.orderBy("serverTimestamp", "desc")
			.get() 		

		await posts.forEach((post) => getDataFromEachPost({
			post: post,
			postsHolder: postsHolder,
			labelsHolder: labelsHolder,		
			owner: owner	
		}))

		dispatch({
			type: GET_POSTS_PUBLIC,
			payload: {
				posts: postsHolder,
				labels: labelsHolder // populate labelsInPublicRoom state used for filtering
			}		
		})

 	} catch (e) {
 		dispatch(errorMessage(`error in getting post: ${e.message}`))
 	}
}

// get posts for a room IF SHARED, called from getRoomDetailsShared function, not from component
const getPostsShared = (room, owner) => async (dispatch) => {
 	dispatch({ type: GET_POSTS_PENDING })

	const postsHolder = [];	 	
 	let labelsHolder = new Map();

 	try {
 		const posts = await db.collection('users').doc(owner)
			.collection("posts")
			.where("room", "==", room)
			.orderBy("serverTimestamp", "desc")
			.get()

		await posts.forEach((post) => getDataFromEachPost({
			post: post,
			postsHolder: postsHolder,
			labelsHolder: labelsHolder,		
			owner: owner	
		}))

		dispatch({
			type: "GET_POSTS_SHARED",
			payload: {
				posts: postsHolder,
				labels: labelsHolder  // populate labelsInSharedRoom state used for filtering
			}				
		})			
 	} catch (e) {
 		dispatch(errorMessage(`error in getting posts: ${e.message}`))
 	}
}


// ===========================================================================
//                  Post Details for PostDetailsPage
// ===========================================================================

// get details of one post for PostDetailsPage (used for posts in Shared Room too)
const getPostDetails = ({ id, sharedRoomId, owner }) => async (dispatch) => {	// latter two arguments used for shared room
	const userUid = userUidMaster(owner);
	const userEmail = userEmailMaster(); // for verifying if posts are in shared rooms; not using "owner" argument because we want to get the user's email for cross checking sharing status

	if (!userUid) return null  // HANDLE THIS LATER

	 dispatch({ type: GET_POSTS_PENDING })

	if (owner) { // if shared room, check if user is shared to
		try {
			const room = await db.collection("sharedrooms")
				.doc(sharedRoomId)
				.get()				

			if (!room.exists) {
				return dispatch(errorMessage("Oops. This post no longer exists."))	
			}
			if (room.exists && !room.data().sharedWith.includes(userEmail)) {
				return dispatch(errorMessage("Oops. You do not have access to this post"))
			}
		} catch (e) {
			return dispatch(errorMessage(`error in getting post: ${e.message}`))
		}			
	}

	try {
		const post = await db.collection('users').doc(userUid)
			.collection("posts").doc(id)
			.get()

		if (!post.exists) return dispatch(errorMessage("You do not have access to this post."))	

		dispatch({
			type: GET_POST_DETAILS,
			payload: postDetailsStructure({ post: post }) // maybe pass smaller data instead?
		})			
	} catch (e) {
		dispatch(errorMessage(`error in getting post: ${e.message}`))
	}	
}

// get details for PublicPostDetailsPage
const getPostDetailsPublic = ({ id, owner }) => async (dispatch) => {
	dispatch({ type: GET_POSTS_PENDING })

	if (!owner) return dispatch(errorMessage("this post is not accessible directly."))

	try {
		const post = await db.collection('users').doc(owner)
			.collection("posts").doc(id)
			.get()

		if (!post.exists) return dispatch(errorMessage("You do not have access to this post."))		

		dispatch({
			type: GET_POST_DETAILS,
			payload: postDetailsStructure({ post: post }) 
		})
			
	} catch (e) {
		dispatch(errorMessage(`error in getting post: ${e.message}`))
	}	
}

// for PostDetailsPage when component unmounts
const resetPostDetails = () => (dispatch) => {
	dispatch({
		type: GET_POST_DETAILS,
		payload: postDetailsStructure({ post: null }) 
	})	
}



// ===========================================================================
//                     SPECIAL OPERATIONS
// ===========================================================================

// this is the one that causes signup "no document to update" error
const updateLoginTimestamp = () => async (dispatch) => {
	const userUid = userUidMaster();
		// what time is it now
	const today = new Date()	
	const todayTimestamp = firebase.firestore.Timestamp.fromMillis(today);	
	const docRef = db.collection('users').doc(userUid);

	const userDoc = await docRef.get();
	if (!userDoc.exists) return 	// if during signup user doc is not created yet, don't run this

	docRef.update({ lastDeleteTimestamp: todayTimestamp })	
}

const searchPosts = (searchTerm) => (dispatch) => {
	 dispatch({
	 	type: SEARCH_POSTS,
	 	payload: searchTerm
	 })
}

const searchError = () => dispatch => dispatch(errorMessage("Please enter at least two characters to search"))

// when something is dragged
const setDraggedPost = (post) => (dispatch) => {
	dispatch({
		type: "SET_DRAGGED_POST",
		payload: post
	})
}

// when a dragged post moves to a receiving post (update UI only)
const receivingDraggedPost = (receivingPost, draggedPost, owner) => (dispatch) => {
	dispatch({
		type: "RECEIVING_DRAGGED_POST",	// change later
		payload: {
			receivingPost,
			draggedPost,
			owner
		}
	})	
}

// update firebase when drop is confirmed
// for rearranging in the same room only
// REFACTOR THIS
const dropPostConfirm = (allPosts, draggedPost, owner) => async (dispatch) => {
	const userUid = userUidMaster(owner);

	dispatch({
		type: "SET_DRAGGED_POST",
		payload: null
	})

	const postsInRoom = allPosts.filter(post => post.room === draggedPost.room)	// only posts in this room; if move room, change this
	const index = postsInRoom.findIndex(post => post.docId === draggedPost.docId);	// new location

	if (postsInRoom.length === 1) return // handle edge case if only 1 post

	const beforePost = postsInRoom[index - 1]
	const afterPost = postsInRoom[index + 1]

	let newTimestamp;

	if (index === postsInRoom.length - 1) { 	// if at the end
		newTimestamp = Number(beforePost.serverTimestamp) - 100
	} else if (index === 0) { 	// if at the start
		newTimestamp = Number(afterPost.serverTimestamp) + 100
	} else {	// normail, take average between post in front and after
		newTimestamp = Math.floor((Number(afterPost.serverTimestamp) + Number(beforePost.serverTimestamp)) / 2)
	}

	const serverTimestamp = firebase.firestore.Timestamp.fromMillis(newTimestamp);

	try {
	    db.collection('users').doc(userUid)
	    .collection("posts")
	    .doc(draggedPost.docId)
	    .update({
	  		serverTimestamp: serverTimestamp
	  	})	   		
	} catch (e) {
		return dispatch(errorMessage(`error in moving post: ${e.message}`))
	}
}

// to side Menu (rooms, desk, sharedroom, collapsed rooms), and to warroomsectionnew
const dropPostToRoomConfirm = (props) => async (dispatch) => {
	const {
		draggedPost, roomDestination,
		owner
	} = props;

	const userUid = userUidMaster(owner);
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

	dispatch({
		type: "SET_DRAGGED_POST",
		payload: null
	})

	try {
		if (owner) {	// from own to shared room, towards sidemenu shared room
			return dispatch(movePostToSharedRoom({
				docId: draggedPost.docId, 
				post: draggedPost, 
				room: roomDestination, 
				owner: owner
			}))				
		}

	    await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(draggedPost.docId)
		    .update({
		  		serverTimestamp: serverTimestamp,
		  		room: roomDestination
		  	})	   

		dispatch({
			type: UPDATE_POST, 	
			payload: {
				post: draggedPost,
				new: {
			  		room: roomDestination,
			  		serverTimestamp: new Date()				
				}		
			}
		})

		dispatch(successMessage("post moved successfully."))

		dispatch(updateRoomCountFirebaseAndReorderRooms(draggedPost.room, -1)) // we need this because this is moving. Cloud only update on craete and delete
		dispatch(updateRoomCountFirebaseAndReorderRooms(roomDestination, 1))  

	} catch (e) {
		return dispatch(errorMessage(`error in moving post: ${e.message}`))
	}
}

// pin or unpin a post
const updatePinPost = (props) => async (dispatch) => {
	const { post, pin, owner } = props;

	const userUid = userUidMaster(owner);

	try {
		await db.collection('users').doc(userUid)
		    .collection("posts")
		    .doc(post.docId)
		    .update({
		  		pin: pin
		  	})
 	
		 dispatch({
	     	type: owner ? "PIN_POST_SHARED" : "PIN_POST",  
	     	payload: {
	     		docId: post.docId,
	     		pin: pin
	     	}
	     })    
	} catch (e) {
		dispatch(errorMessage(`failed to pin post: ${e.message}`))
	}
}

// create label used in PostBottom, EditPostModal
const createLabel = (props) => async (dispatch) => {
	const { label, post, owner, isAtPostDetailsPage} = props; // or just id instead of post
	const userUid = userUidMaster(owner);

	// check duplicate??
	try {
		await db.collection('users').doc(userUid)
			.collection("posts").doc(post.docId)
			.update({
				labels: firebase.firestore.FieldValue.arrayUnion(label)
			})

		dispatch({
			type: owner ? "CREATE_LABEL_SHARED" :  "CREATE_LABEL", 	
			payload: {
				docId: post.docId,
				label: label
			}
		})    

		if (isAtPostDetailsPage) dispatch({
			type: "CREATE_LABEL_POST_DETAILS", 
			payload: {
				label: label
			}
		})   

	} catch (e) {
		dispatch(errorMessage(`failed to create label: ${e.message}`))
	}
}

// remove label used in Labels
const removeLabel = (props) => async (dispatch) => {
	const { label, post, owner, isAtPostDetailsPage } = props; 
	const userUid = userUidMaster(owner); 

	try {
		await db.collection('users').doc(userUid)
			.collection("posts").doc(post.docId)
			.update({
				labels: firebase.firestore.FieldValue.arrayRemove(label)
			})

		dispatch({
			type: owner ? "REMOVE_LABEL_SHARED" : "REMOVE_LABEL",  
			payload: {
				docId: post.docId,
				label: label
			}
		})    

		if (isAtPostDetailsPage) dispatch({
			type: "REMOVE_LABEL_POST_DETAILS",  // this works in shared room postdetails too
			payload: {
				label: label
			}
		})   

	} catch (e) {
		dispatch(errorMessage(`failed to remove label: ${e.message}`))
	}
}

const labelHasDuplicate = label => dispatch => dispatch(errorMessage(`you have already added this label: ${label}`))

const minimizePost = (props) => async (dispatch) => {
	const { post, owner } = props; 
	const userUid = userUidMaster(owner); 	

	const minimizeBoolean = !post.minimize;

	try {
		await db.collection('users').doc(userUid)
			.collection("posts").doc(post.docId)
			.update({
				minimize: minimizeBoolean
			})		

		dispatch({
			type: owner ? "MINIMIZE_POST_SHARED" : "MINIMIZE_POST",  
			payload: {
				docId: post.docId,
				minimize: minimizeBoolean
			}
		})    	

	} catch (e) {
		dispatch(errorMessage(`failed to update post: ${e.message}`))
	}
}

// select post for group delete/move - this is used for both select and UNselect
const selectPostForGroupDeleteOrMove = (props) => async (dispatch) => {
	const { post } = props; 

	dispatch({
		type: "SELECT_POST_FOR_GROUP_DELETE_OR_MOVE",  // handle owner later
		payload: {
			post: post
		}
	})    	
}

// clear means unselect
const clearPostsInGroup = (props) => (dispatch) => {
	dispatch({
	 	type: "CLEAR_POSTS_IN_GROUP", 
	})    	
}

const deletePostsInGroup  = (props) => async (dispatch) => {
	const { selectedPosts, isAtArchive } = props; 
	const userUid = userUidMaster(); 

	try {

		const batch = db.batch();

		await selectedPosts.forEach(postId => {
			let postRef = db.collection('users').doc(userUid)
				.collection("posts").doc(postId)

            batch.delete(postRef);
		})

		batch.commit()	

		if (isAtArchive) dispatch({ type: "DELETE_POSTS_IN_ARCHIVE"  }) 
		else dispatch({ type: "DELETE_POSTS_IN_GROUP" })     // handle owner later	

		dispatch(successMessage("Posts deleted successfully."))		


	} catch (e) {
		dispatch(errorMessage(`failed to delete in group`))
	}
}

// use in GroupChangeRoomModal.js
const changeRoomForPostsInGroup = (props) => async (dispatch) => {
	const { selectedPosts, selectedwarroom, currentRoom } = props;
	// can only move to your own rooms
	// no duplicate check
	const userUid = userUidMaster();
	const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
	const batch = db.batch();
	const roomRef = db.collection('users').doc(userUid).collection("warrooms").doc(selectedwarroom)

	try {
		selectedPosts.forEach((postId) => {
			let postRef = db.collection('users').doc(userUid)
				.collection("posts").doc(postId)

            batch.update(postRef, {
		  		room: selectedwarroom,
		  		// serverTimestamp: serverTimestamp            	
            });
		})

		batch.update(roomRef, {
			createdAt: serverTimestamp			
		})		

		await batch.commit()	

		await dispatch({
		 	type: "MOVE_POSTS_IN_GROUP", 
		 	payload: {
		 		room: selectedwarroom
		 	}
		})    	

		dispatch(successMessage("posts moved successfully."))
		dispatch(updateRoomCountFirebaseAndReorderRooms(currentRoom, selectedPosts.length*-1)) 
		dispatch(updateRoomCountFirebaseAndReorderRooms(selectedwarroom, selectedPosts.length))  

	} catch (e) {
		dispatch(errorMessage(`failed to move posts: ${e.message}`))
	}
}


const contentActions = {
    createPost, getPostDetails, resetPostDetails, editLinkPost, editTextPost, editEmailPost, deletePost, 
    updateLoginTimestamp, createNoteInPost, deleteNoteInPost, editNoteInPost,
    getPostsPublic, getPostDetailsPublic, 
    searchPosts, searchError,
    loadingWhileGetAllPosts, 
    getPostsShared, 
    setDraggedPost, receivingDraggedPost, dropPostConfirm, dropPostToRoomConfirm,
    movePostToSharedRoom, changeRoomForPost,
    getAllPostsSnapshot, getAllPosts,
    updatePinPost, createLabel, removeLabel, labelHasDuplicate, minimizePost,
    selectPostForGroupDeleteOrMove, deletePostsInGroup, clearPostsInGroup, changeRoomForPostsInGroup
}

export default contentActions;

/*
// const BRAINDUMPPERIOD = 604800000;  // in MILISECONDS, 7 days. Any post in braindump older than this will be decluttered
// const DELETEPERIOD = 1814400000; // 3 weeks (1 week brain dump, and 2 weeks in archive)


// from Warroom Details Page
// const editRoomTitleFromRoom = (room, e, newroomtitle) => (dispatch) => {
// 	const userUid = userUidMaster();

// 	e.preventDefault()

//     db.collection('users').doc(userUid)
//     .collection("warrooms")
//     .doc(room.roomId)
//     .update({
//   		title: newroomtitle,
//   	})	    	
// 	.then(dispatch({
// 		type: EDIT_ROOM_TITLE,
// 		payload: {
// 			roomId: room.roomId,
// 			newroomtitle: newroomtitle
// 		}
// 	}))

// 	.then(() => {
// 		if (room.publicId) {
// 			db.collection('publicrooms').doc(room.publicId)
// 			.update({
// 				roomTitle: newroomtitle
// 			})	
// 		}
// 		// new
// 		if (room.sharedWith) {	// if there is sharedWith, means there is a sharedroom doc
// 			db.collection('sharedrooms')
// 			.where("originalRoomId", "==", room.roomId)
// 			.get()
// 			.then((rooms) => {
// 				rooms.forEach((sharedroom) => {
// 					db.collection('sharedrooms').doc(sharedroom.id)	// refer to sharedroom id
// 					.update({
// 						roomTitle: newroomtitle
// 					})
// 				})			
// 			})
// 		}		
// 	})
// 	.catch((e) => dispatch(errorMessage(`error in editing room: ${e.message}`)))
// }



// No longer used at <App>, also removed from Onboarding.  
const getAllPosts = () => (dispatch) => {
	const userUid = userUidMaster();
	// trigger loading UI
	dispatch({ type: GET_POSTS_PENDING })

	let postsHolder = [];
	// let timeNow = new Date();

	function getDataFromEachPost (post) {
		let serverTimestamp = post.data().serverTimestamp.toDate(); 

		// if (post.data().room === "braindump" && (timeNow - serverTimestamp) >= DELETEPERIOD ) {  // don't get posts about to be decluttered
		// 	return
		// } else {
		postsHolder.push({
			desc: post.data().desc,
			title: post.data().title,
			room: post.data().room,
			thumbnail: post.data().thumbnail,
			url: post.data().url,
			docId: post.id,
			notes: post.data().notes || [],	
			createdAt: moment(serverTimestamp).format('Do MMMM YYYY'),
			serverTimestamp: serverTimestamp,	// for drag and drop
		})			
	}

	db
	.collection('users').doc(userUid)
	.collection("posts")
	.orderBy("serverTimestamp", "desc")
	.get()
	.then(function(posts) {	
		posts.forEach(getDataFromEachPost)
		dispatch({
			type: GET_ALL_POSTS,
			payload: postsHolder
		})
	})	
}

// [REMOVED] for daily check to delete if posts in Brain Dump needs to be deleted
const unclutter = () => (dispatch) => {
	const userUid = 
	localStorage.getItem('user') 
	? JSON.parse(localStorage.getItem('user')).uid 
	: firebase.auth().currentUser
	? firebase.auth().currentUser.uid
	: null
	
	const docRef = db.collection('users').doc(userUid)

	docRef.get()
	.then(function(doc) {
		// when was last delete
		const lastDeleteTimestamp = doc.data().lastDeleteTimestamp
		// what time is it now
		const today = new Date()	
		const todayTimestamp = firebase.firestore.Timestamp.fromMillis(today);

		// if there was unclutter in past 1 day, then NO MORE unclutter
		if (todayTimestamp - lastDeleteTimestamp  < 86400) {  
			// NOTE: firestore servertimestamp is in SECONDS,  but JS date object is in MILLISECONDS
			console.log("no more uncluttering today")
		} else {
			// otherwise, unclutter
			const deleteDate = today - DELETEPERIOD 
			// const deleteDate = today - BRAINDUMPPERIOD  
			// changed to DELETEPERIOD ( 3 weeks )

			const deleteTimestamp = firebase.firestore.Timestamp.fromMillis(deleteDate);

			db
			.collection('users').doc(userUid)
			.collection("posts")
			.where("room", "==", "braindump")
			.where("serverTimestamp", "<=", deleteTimestamp)
			.get()
			.then(function(querySnapshot) {
					if (querySnapshot.size > 0) {
						var batch = db.batch();
						querySnapshot.forEach(function(doc) {
				            // For each doc, add a delete operation to the batch
				            batch.delete(doc.ref);
				        });

				        batch.commit()
						.then(dispatch({	// tell user how many deleted (SKIP THIS?)
					      	type: SUCCESS_MESSAGE,  
							payload: {
								message: `${querySnapshot.size} posts deleted from Archive`,
								type: "success"
							}
						}))    	
					} else {
						return;
					}
				})
			.then(docRef.update({
				lastDeleteTimestamp: todayTimestamp
			}))
			.catch(function(error) {
				dispatch({
			      	type: ERROR_MESSAGE,
					payload: {
						message: error.message,
						type: "danger"
					}
				})
			})	
		}
	})
	.catch(function(error) {
		dispatch({
	      	type: ERROR_MESSAGE,
			payload: {
				message: error.message,
				type: "danger"
			}
		})
	})
}
*/
