import { 
	ADD_NOTE_IN_POST, DELETE_NOTE_IN_POST, EDIT_NOTE_IN_POST,
	timestampToShowInDesk
} from 'constants/constants';


// use in GLOBAL SEARCH / FILTER BY PIN / LABELS
export const filteredPosts = (state, searchTerm) => { 
	// filter for PINNED posts
	if (searchTerm.substring(0,7) === "pinned:") {
		const pinFilterLogic = post => post.pin

		return [...state.posts].filter(pinFilterLogic)
	}

	// filter for LABELS
	if (searchTerm.substring(0,6) === "label:") {
		const labelFilterLogic = (post) => {
			if (!post.labels || post.labels.length === 0) return false

			const labelsArray = post.labels // is an array
			const modifiedSearchTerm = searchTerm.substring(6) // after "label:"

			let temp = false;

			labelsArray.forEach((label) => {
				// note: can't use indexOf, since it needs to be exact
				if (label.toLowerCase() === modifiedSearchTerm.toLowerCase()) {
					temp = true;
				}
			})

			return temp;
		}

		return [...state.posts].filter(labelFilterLogic)
	}

	// normal search logic across post, notes, notes in posts:
	const filterLogic = (post) => {
		const desc = post.desc || ""
		const title = post.title || ""  // note post has title as undefined
		const notes = post.notes.toString()  // post.notes is an array of string
		const url = post.url?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "") || ""			
		const labels = post.labels?.toString() || ""

		// so that if falsy, no need to evalute
		if (desc && desc.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return true;
		if (title && title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return true;
		if (url && url.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return true;
		if (notes && notes.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return true;
		if (labels && labels.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) return true;

		return false 

		// return desc.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
		// title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
		// notes.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
		// url.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
	}

	return [...state.posts].filter(filterLogic)
}


export const createPost = (state, newpost) => {
	// add only if pending id is not there (complication due to onSnapshot)
	// const currentState = [...state.posts].filter(post => post.docId !== "pendingId")
	const currentState = [...state.posts].filter(post => post.docId !== `pendingId+${newpost.url}`)
	// below is to avoid double - original, and from snapshot
	if (currentState.findIndex(post => post.docId === newpost.docId) === -1) {	// if new
		return [newpost, ...currentState]
	} else {	// already exist
		return currentState
	}
}
 
// use this instead of filter, to be faster so don't have to loop through everything
export const deletePost = (state, docId) => {
	let currentState = [...state.posts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState.splice(i, 1);
			break; 
		}
	}

	return currentState;
}
export const deletePostFiltered = (state, docId) => {
	let currentState = [...state.filteredPosts];
	if (currentState.length === 0) {
		return currentState
	}

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState.splice(i, 1);
			break;
		}
	}

	return currentState;
}

// now:  only for rearranging room
// originally: for doing room count update without pulling from firebase AND put updated room in front
export const updateRoomCountRedux = (state, roomId, increment) => {
	const currentRoomsState = [...state.rooms];
	if (currentRoomsState.length === 0) return currentRoomsState; // in case somehow after signup has no rooms


	const roomsArrayWithoutRoom = currentRoomsState.filter((roomObject) => roomObject.roomId !== roomId);

	const roomInEdit = currentRoomsState.find((roomObject) => roomObject.roomId === roomId);

	if (increment === 1) {
		// roomInEdit.count = roomInEdit.count + increment;

		return [roomInEdit, ...roomsArrayWithoutRoom];

	} else {	// only update room order if adding post, not deleting
		currentRoomsState.forEach(item => {
			if (item.roomId === roomId) {

				// item.count = item.count + increment;
			} else {
				return;
			}
		})
		return currentRoomsState
	}
}	

// for update posts in redux without pulling all posts from firebase
export const updatePost = (state, payload) => {
	let currentState = [...state.posts];
	// remove old post
	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === payload.post.docId) {
			currentState.splice(i, 1);
			break;
		}
	}
	// merge the new and old post, then put it at the front of posts array
	return [{...payload.post, ...payload.new}, ...currentState]
}
export const updatePostShared = (state, payload) => {
	// remove old post
	const StateWithoutPost = [...state.sharedPosts].filter(post => post.docId !== payload.post.docId)

	// merge the new and old post, then put it at the front of posts array
	return [{...payload.post, ...payload.new}, ...StateWithoutPost]
}	
export const updatePostFiltered = (state, payload) => {
	let currentState = [...state.filteredPosts];
	if (currentState.length === 0) {
		return currentState
	}
	// remove old post
	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === payload.post.docId) {
			currentState.splice(i, 1);
			break;
		}
	}

	return [{...payload.post, ...payload.new}, ...currentState]
}


// update postDetails for CRUD notes in post for PostDetailsPage
export const noteInPostUpdatePostDetails = (state, payload, type) => { 
	const currentState = {...state.postDetails};
	let newNotesArray;

	if (type === ADD_NOTE_IN_POST) {
		newNotesArray = [payload.note, ...state.postDetails.notes]
	} else if (type === DELETE_NOTE_IN_POST) {
		newNotesArray = [...state.postDetails.notes].filter(each => each !== payload.note)
	} else if (type === EDIT_NOTE_IN_POST) {
		const cleanedArray = [...state.postDetails.notes].filter(each => each !== payload.oldnote)
		newNotesArray = [payload.newnote, ...cleanedArray]
	}

	const toMerge = { notes: newNotesArray }

	return {...currentState, ...toMerge}
}
export const noteInPostUpdatePostDetailsShared = (state, payload, type) => { 
	const currentState = {...state.postDetails};
	let newNotesArray;

	if (type === "ADD_NOTE_IN_POST_SHARED") {
		newNotesArray = [payload.note, ...state.postDetails.notes]
	} else if (type === "DELETE_NOTE_IN_POST_SHARED") {
		newNotesArray = [...state.postDetails.notes].filter(each => each !== payload.note)
	} else if (type === "EDIT_NOTE_IN_POST_SHARED") {
		const cleanedArray = [...state.postDetails.notes].filter(each => each !== payload.oldnote)
		newNotesArray = [payload.newnote, ...cleanedArray]
	}

	const toMerge = { notes: newNotesArray }

	return {...currentState, ...toMerge}
}	

// update Posts state for CRUD notes in post for PostDetailsPage
export const noteInPostsUpdatePosts = (state, payload, type) => {
	const StateWithoutPost = [...state.posts].filter(post => post.docId !== payload.docId)
	const currentPost = [...state.posts].filter(post => post.docId === payload.docId)

	let newNotesArray;

	if (type === ADD_NOTE_IN_POST) {
		newNotesArray = [payload.note, ...currentPost[0].notes];  // need [0] because currentPost is in [ {} ]
	} else if (type === DELETE_NOTE_IN_POST) {
		newNotesArray = [...currentPost[0].notes].filter(each => each !== payload.note);
	} else if (type === EDIT_NOTE_IN_POST) {
		const cleanedArray = [...currentPost[0].notes].filter(each => each !== payload.oldnote)
		newNotesArray = [payload.newnote, ...cleanedArray]		
	}

	return [{...currentPost[0], ...{ notes: newNotesArray }}, ...StateWithoutPost]
}
export const noteInPostsUpdateSharedPosts = (state, payload, type) => {
	if (state.sharedPosts.length < 1) return []
	// above is exception case if go directly to postdetailspage, there is no sharedPostsstates
	// because SharedPosts are not fetched by App, only fetched in WarroomPage
	const StateWithoutPost = [...state.sharedPosts].filter(post => post.docId !== payload.docId)
	// const currentPost = [...state.sharedPosts].filter(post => post.docId === payload.docId)
	const currentPost = [...state.sharedPosts].find(post => post.docId === payload.docId)

	let newNotesArray;

	if (type === "ADD_NOTE_IN_POST_SHARED") {
		newNotesArray = [payload.note, ...currentPost.notes];  // need [0] because currentPost is in [ {} ]
	} else if (type === "DELETE_NOTE_IN_POST_SHARED") {
		newNotesArray = [...currentPost.notes].filter(each => each !== payload.note);
	} else if (type === "EDIT_NOTE_IN_POST_SHARED") {
		const cleanedArray = [...currentPost.notes].filter(each => each !== payload.oldnote)
		newNotesArray = [payload.newnote, ...cleanedArray]		
	}

	return [{...currentPost, ...{ notes: newNotesArray }}, ...StateWithoutPost]
}

//  to replace newly created post over placeholder post
export const getNewLinkPost = (state, payload) => {
	// remove the placeholder post
	let currentState = [...state.posts];
	// remove old post
	//  change from "pendingId" to `pendingId+${cleanUrl}` to support multiple loading
	for (let i = 0; i < currentState.length; i++) {
		// if (currentState[i].docId === "pendingId") {
		if (currentState[i].docId === `pendingId+${payload.url}`) {
			currentState.splice(i, 1);
			break;
		}
	}
	// below is to avoid double - original, and from snapshot
	if (currentState.findIndex(post => post.docId === payload.docId) === -1) {	// if new
		return [payload, ...currentState]
	} else {	// already exist
		return currentState
	}
}

// update roomDetails for CRUD friends invited to a room
export const addRemoveFriendToSharedRoom = (state, payload, type) => { 
	const currentState = {...state.roomDetails};
	let newSharedWithArray;

	if (type === "ADD_FRIEND_TO_SHARED_ROOM") {
		newSharedWithArray = [...state.roomDetails.sharedWith, payload]
	} 
	else if (type === "REMOVE_FRIEND_TO_SHARED_ROOM") {
		newSharedWithArray = [...state.roomDetails.sharedWith].filter(each => each !== payload)
	}

	const toMerge = { sharedWith: newSharedWithArray }
	return {...currentState, ...toMerge}
}

// new posts state after dragging for rearrange
export const draggedPost = (state, payload) => {
	// move draggedPost in front of receivingPost
	const {receivingPost, draggedPost, owner} = payload;
	const posts = owner ? [...state.sharedPosts] : [...state.posts];

	const stateWithoutDraggedPost = posts.filter(post => post.docId !== draggedPost.docId);
	// see if loop is faster
	const indexReceiving = stateWithoutDraggedPost.findIndex(post => post.docId === receivingPost.docId)
	const indexDragged = posts.findIndex(post => post.docId === draggedPost.docId)

	if (indexReceiving < indexDragged) {	// move left or up
		stateWithoutDraggedPost.splice(indexReceiving, 0, draggedPost)
	} else {	// move right or down
		stateWithoutDraggedPost.splice(indexReceiving + 1, 0, draggedPost)
	}
	
	return stateWithoutDraggedPost;
}

export const pinPosts = (state, payload) => {
	const { docId, pin } = payload;
	let currentState = [...state.posts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState[i].pin = pin
			break;
		}
	}
	return currentState;
}
export const pinPostsShared = (state, payload) => {
	const { docId, pin } = payload;
	let currentState = [...state.sharedPosts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState[i].pin = pin
			break;
		}
	}
	return currentState;
}
export const pinPostsFiltered = (state, payload) => {
	const { docId } = payload;
	let currentState = [...state.filteredPosts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState.splice(i, 1);  // remove frim filtered
			break;
		}
	}
	return currentState;
}	

/* ******* LABELS ****** */
export const labelPosts = (state, payload, type) => {
	const { docId, label } = payload;
	let currentState = [...state.posts];

	if (type === "CREATE_LABEL") {
		for (let i = 0; i < currentState.length; i++) {
			if (currentState[i].docId === docId) {
				currentState[i].labels.push(label)
				break;
			}
		}			
	}  else if (type === "REMOVE_LABEL") {
		for (let i = 0; i < currentState.length; i++) {
			if (currentState[i].docId === docId) {
				const index = currentState[i].labels.indexOf(label)
				if (index > -1) {
				  currentState[i].labels.splice(index, 1);
				}
				break;
			}
		}
	}

	return currentState;
}
export const labelPostsShared = (state, payload, type) => {
	const { docId, label } = payload;
	let currentState = [...state.sharedPosts];

	if (type === "CREATE_LABEL_SHARED") {
		for (let i = 0; i < currentState.length; i++) {
			if (currentState[i].docId === docId) {
				currentState[i].labels.push(label)
				break;
			}
		}			
	}  else if (type === "REMOVE_LABEL_SHARED") {
		for (let i = 0; i < currentState.length; i++) {
			if (currentState[i].docId === docId) {
				const index = currentState[i].labels.indexOf(label)
				if (index > -1) {
				  currentState[i].labels.splice(index, 1);
				}
				break;
			}
		}
	}

	return currentState;
}	
export const updateLabels = (state, payload, type) => {
	const { label } = payload;
	const whichLabelsStateToUse = (type === "CREATE_LABEL" || type === "REMOVE_LABEL") ? state.labels : state.labelsInSharedRoom
	let currentState = new Map(whichLabelsStateToUse);

	if (type === "CREATE_LABEL" || type === "CREATE_LABEL_SHARED") {

		if (currentState.has(label)) { // add 1
			currentState.set(label, currentState.get(label) + 1)
		} else { // add  new key: value
			currentState.set(label, 1)
		}

	}  else if (type === "REMOVE_LABEL" || type === "REMOVE_LABEL_SHARED") {

		if (currentState.has(label)) { 
			if (currentState.get(label) === 1) currentState.delete(label)
			else currentState.set(label, currentState.get(label) - 1)	
		} 

	}

	return currentState;
}
export const updateLabelsAfterDeletePost = (state, payload, type) => {
	const { labels } = payload;
	let currentState = new Map(state.labels);

	if (labels.length === 0) return currentState;

	labels.forEach((label) => {
		if (currentState.has(label)) { 
			if (currentState.get(label) === 1) currentState.delete(label)
			else currentState.set(label, currentState.get(label) - 1)	
		} 		
	})

	return currentState;
}
export const labelAtPostDetails = (state, payload, type) => {
	const { label } = payload;
	let currentState = {...state.postDetails};

	let newLabelArray;

	if (type === "CREATE_LABEL_POST_DETAILS") {
		newLabelArray = [...currentState.labels, label];	
	}  else if (type === "REMOVE_LABEL_POST_DETAILS") {
		newLabelArray = currentState.labels.filter(a => a !== label);
	}

	return {...state.postDetails, ...{labels: newLabelArray} };
}


export const minimizePosts = (state, payload) => {
	const { docId, minimize } = payload;
	let currentState = [...state.posts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState[i].minimize = minimize;
			break;
		}
	}			

	return currentState;
}
export const minimizePostsShared = (state, payload) => {
	const { docId, minimize } = payload;
	let currentState = [...state.sharedPosts];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].docId === docId) {
			currentState[i].minimize = minimize;
			break;
		}
	}			

	return currentState;
}	

export const pinRooms = (state, payload) => {
	const { roomId, pin } = payload;
	let currentState = [...state.rooms];

	for (let i = 0; i < currentState.length; i++) {
		if (currentState[i].roomId === roomId) {
			currentState[i].pin = pin
			break;
		}
	}
	return currentState;
}	

// for adding/removing post to selectedPosts for group delete or move
export const selectedPosts = (state, payload) => {
	const { post } = payload;
	let currentState = [...state.selectedPosts];
	let indexOfPost = currentState.indexOf(post.docId); // is it there?

	if (indexOfPost === -1) { // if not there, add
		currentState.push(post.docId);
	} else { // if there, remove
		currentState.splice(indexOfPost, 1)
	}

	return currentState;
}

export const deletePostsInGroup = (state, whichStateToUpdate) => {
	let selectedPosts = [...state.selectedPosts];
	let currentState = (whichStateToUpdate === "posts") ? [...state.posts] : [...state.filteredPosts];


	for (let i = currentState.length - 1; i >=0 ; i--) { // use reverse for splice so it won't mess the order
		if (selectedPosts.length === 0) break;

		let docId = currentState[i].docId;

		if (selectedPosts.includes(docId)) {
			currentState.splice(i, 1);
			selectedPosts.splice(selectedPosts.indexOf(docId), 1);
		} 
	}
	return currentState;
}	

// delete all posts in Archive
export const deletePostsInArchive = (state) => {
	const filterLogic = post => {	// what to keep
		if (post.room !== "braindump") return true
		if (post.pin) return true	
		if (post.serverTimestamp > timestampToShowInDesk) return true

		return false
	} 
	return [...state.posts].filter(filterLogic) 
	// return [...state.posts].filter(post => !(post.room === "braindump" && post.serverTimestamp < timestampToShowInDesk && !post.pin)) 
}	

export const movePostsInGroup = (state, { room }) => {
	let selectedPosts = [...state.selectedPosts];
	let currentState = [...state.posts];

	for (let i = 0; i < currentState.length; i++) {
		if (selectedPosts.length === 0) break;

		let docId = currentState[i].docId;

		if (selectedPosts.includes(docId)) {
			currentState[i].room = room;

			selectedPosts.splice(selectedPosts.indexOf(docId), 1);
		}
	}

	return currentState;
}	


/*  ~~~~~~  ADMIN HELPERS  ~~~~~~    */
export const updateUserRecommendations = (state, payload) => {
	const {recommendations, uid} = payload;
	const currentState = [...state.subscribedUsers]

	const index = currentState.findIndex(user => user.uid === uid)
	currentState[index].rec1 = recommendations[0];
	currentState[index].rec2 = recommendations[1];
	currentState[index].rec3 = recommendations[2];

	return currentState;
}

export const sortUsersByJoin = (state, direction) => {
	const newState = [...state.subscribedUsers]

	if (direction === "up") {
		newState.sort((a,b) => a.joinTimestamp - b.joinTimestamp)
		// newState.sort(function(a,b) {
		// 	return a.joinTimestamp - b.joinTimestamp
		// })			
	} else {
		newState.sort((a,b) => b.joinTimestamp - a.joinTimestamp)			
	}
	return newState;
}

export const sortUsersByActive = (state, direction) => {
	const newState = [...state.subscribedUsers]

	if (direction === "up") {
		newState.sort((a,b) => a.lastDeleteTimestamp - b.lastDeleteTimestamp)
		// newState.sort(function(a,b) {
		// 	return a.lastDeleteTimestamp - b.lastDeleteTimestamp
		// })			
	} else {
		newState.sort((a,b) => b.lastDeleteTimestamp - a.lastDeleteTimestamp)				
	}
	return newState;
}

export const sortUsersByNumberOfPosts = (state, payload) => {
	const { period, direction} = payload;

	const newState = [...state.subscribedUsers]

	if (period === "year" && direction === "up") {
		newState.sort((a,b) => a.postAddedThisYear - b.postAddedThisYear)
		// newState.sort(function(a,b) {
		// 	return a.postAddedThisYear - b.postAddedThisYear
		// })			
	} else if (period === "year" && direction === "down") {
		newState.sort((a,b) => b.postAddedThisYear - a.postAddedThisYear)	
	} else if (period === "month" && direction === "up") {
		newState.sort((a,b) => a.postAddedThisMonth - b.postAddedThisMonth)	
	} else if (period === "month" && direction === "down") {
		newState.sort((a,b) => b.postAddedThisMonth - a.postAddedThisMonth)					
	}

	return newState;
}

export const updateMonthlyRecommendation = (state, payload) => {
	const {recommendationObj, index} = payload;
	const {url, title, desc, image} = recommendationObj;
	const currentState = [...state.monthlyRecommendations]

	currentState[index].url = url;
	currentState[index].title = title;
	currentState[index].desc = desc;
	currentState[index].image = image;

	return currentState;
}


