import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { sharedRoomUrl } from "utils/transformIdAndUrl";
import { useDropForDnD } from "hooks/useDropForDnD";		
import "../sidebar.css";

const EachRoom = ({ room, sharedIcon }) => {

	// DND
	const dropForDnD = useDropForDnD(); 
	const {
		isRoomReceiving, 
		onDragOver, onDragLeave, onDrop
	} = dropForDnD;

	return (
		<Link className="dont-link-me" to={sharedRoomUrl(room)} key={room.sharedRoomId}>
			<div 
				className="sidebar-dropdown-item"

				onDragOver={(e) => onDragOver(e)} 
			    onDragLeave={(e) => onDragLeave(e)}						
				onDrop={(e) => onDrop({
					e: e,
					roomDestination: room.originalRoomId, 
					owner: room.owner
				})} 

				{...isRoomReceiving && { style: { backgroundColor: "var(--main)", opacity: "0.7" }}}
			>
				<div className="sidebar-dropdown-item-text">{room.roomTitle}</div>	
				<img 
					src={sharedIcon}
					style={{width: "16px"}}
					alt="shared by"
					className="sidebar-dropdown-item-count" 
					data-tip data-for={room.sharedRoomId}
				/> 
				<ReactTooltip id={room.sharedRoomId} place="bottom" effect="float">
	          		shared by: {room.ownerName}
	        	</ReactTooltip>  		

			</div>		
		</Link>   
	)	
}

const SharedRoomsAtSideBar = () => {
	const sharedRooms = useSelector(state => state.contentReducer.sharedRooms);
	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const [roomsSectionCollapsed, setRoomsSectionCollapsed] = useState(false);

	const sharedIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/shared.svg` : `${process.env.PUBLIC_URL}/images/icons/shared-dark.svg`;
	const sharedMenuIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/sharedfolder.svg` : `${process.env.PUBLIC_URL}/images/icons/sharedfolder-dark.svg`;

	const draggedPost = useSelector(state => state.contentReducer.draggedPost);

	const sharedRoomsOnMenu = sharedRooms.map((room) => (
		<EachRoom 
			room={room} 
			sharedIcon={sharedIcon}			
			// posts={posts}
			key={room.sharedRoomId}
		/>        
	))

	return (
        <div className="rooms-sidebar-section">

        	<div className="rooms-sidebar-header">
        		<h6 onClick={() => setRoomsSectionCollapsed(!roomsSectionCollapsed)}>
        			<span><img src={sharedMenuIcon} alt="rooms" style={{ width: "20px", marginRight: "10px", marginTop: "-4px" }} /></span>
        			Shared With Me
        		</h6>
        	</div>


            {!roomsSectionCollapsed &&
				<div
					{...draggedPost && !draggedPost.owner && {style: { 
						backgroundColor: "rgba(250,128,114,0.3)",
						fontWeight: "bold"
					}}}
				>
	            	{sharedRoomsOnMenu}
            	</div>
            }              
        </div>
	)
}

export default SharedRoomsAtSideBar;