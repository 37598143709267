import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { Link } from 'react-router-dom';
import PlusButton from "components/ButtonsAndIcons/PlusButton/PlusButton";
import ReactTooltip from "react-tooltip";
import { warroomUrl } from "utils/transformIdAndUrl";
import { useDropForDnD } from "hooks/useDropForDnD";	
import "../sidebar.css";


const EachRoom = (props) => {
	const { room, posts, title } = props;
	
	// DND
	const dropForDnD = useDropForDnD(); 
	const {
		isRoomReceiving, 
		onDragOver, onDragLeave, onDrop
	} = dropForDnD;

	// test showing room images
	const postsInRoom = posts.filter(post => post.room === room.roomId);
// 	const roomImages = () => {
// 		let roomImageArray = [];
// 
// 		for (let i=0; i<postsInRoom.length; i++) {
// 			if (roomImageArray.length === 3) break
// 			if (postsInRoom[i].thumbnail) roomImageArray.push(postsInRoom[i].thumbnail)
// 		}
// 
// 		return roomImageArray;
// 	}

	return (
		<Link className="dont-link-me" to={warroomUrl(room)} key={room.roomId}>
			<div 
				className="sidebar-dropdown-item"
				onDragOver={(e) => onDragOver(e)} 
			    onDragLeave={(e) => onDragLeave(e)}						
				onDrop={(e) => onDrop({
					e: e,
					roomDestination: room.roomId, 
				})} 
				{...isRoomReceiving && { style: { backgroundColor: "var(--main)", opacity: "0.7" }}}
			>

				{/*roomImages()[0] 
					?
					<img 
						src={roomImages()[0]} 
						className="sidebar-room-preview-image" 
						alt="room-preview"
						onError={(e)=>{e.target.onerror = null; e.target.src="/images/fallback/o.svg"}} 
					/>									
					:
					<img 
						src={`${process.env.PUBLIC_URL}/images/fallback/${room.title[0]}.svg`} 
						className="sidebar-room-preview-image" 
						alt="room-preview"
					/>								
					// <div style={{ backgroundColor: "darkgrey"}} className="sidebar-room-preview-image" >
					// </div>
				*/}

				{/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>  this div is if we have roomImages*/}
					<div className="sidebar-dropdown-item-text">{title}</div>	
					<div className="sidebar-dropdown-item-count">{postsInRoom.length}</div>
				{/*  </div> */}
			</div>		
		</Link>    
	)	
}

const RoomsAtSideBar = () => {

	const dispatch = useDispatch();
	const posts = useSelector(state => state.contentReducer.posts);
	const rooms = useSelector(state => state.contentReducer.rooms);
	const sharedRooms = useSelector(state => state.contentReducer.sharedRooms);

	const [roomsSectionCollapsed, setRoomsSectionCollapsed] = useState(false);
	const [CreateWarroomFromNavBar, setCreateWarroomFromNavBar] = useState(false);
	const [submitRoomField, setSubmitRoomField] = useState("");

	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const plusIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/navbar/plus.svg` : `${process.env.PUBLIC_URL}/images/navbar/plus-dark.svg`;
	const minusIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/navbar/minus.svg` : `${process.env.PUBLIC_URL}/images/navbar/minus-dark.svg`;

	const draggedPost = useSelector(state => state.contentReducer.draggedPost);

 	// showing pinned rooms first
 	const roomsWithPin = rooms.filter(room => room.pin === true)
	const roomsWithoutPin = rooms.filter(room => room.pin !== true)
	const combinedRooms = [...roomsWithPin, ...roomsWithoutPin];	

	const warroomsOnMenu = combinedRooms.filter(each => each.roomId !== "braindump").map((room) => (
		<EachRoom 
			room={room} 
			title={room.title}
			posts={posts}
			key={room.roomId}
		/>        
	))

	const roomMenuIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/icons/folder.svg` : `${process.env.PUBLIC_URL}/images/icons/folder-dark.svg`;

	return (
        <div className="rooms-sidebar-section">

        	<div className="rooms-sidebar-header">
        		<h6 onClick={() => setRoomsSectionCollapsed(!roomsSectionCollapsed)}>
        			<span><img src={roomMenuIcon} alt="rooms" style={{ width: "20px", marginRight: "10px", marginTop: "-4px" }} /></span>
        			Rooms
        		</h6>

        		{!roomsSectionCollapsed &&
        			<>
        				<button 
        					className="sidebar-toggle-icon-button" 
        					data-tip data-for="sidebar-create-room-toggle"
        					style={{ marginTop: "-5px"}} 
        				>
							<img 
								style={{ height: "20px", marginLeft: "0px"}} 
								src={CreateWarroomFromNavBar ? minusIcon : plusIcon} 
								alt={CreateWarroomFromNavBar ? 'collapse' : 'expand'} 
								onClick={(e) => {
									e.preventDefault();
									setCreateWarroomFromNavBar(!CreateWarroomFromNavBar);
								}}
								// data-tip data-for="sidebar-create-room-toggle"
							/>
							</button>
						<ReactTooltip id="sidebar-create-room-toggle" place="bottom" effect="float">
			          		Create Room
			        	</ReactTooltip>  						
					</>
        		}

        	</div>



            {!roomsSectionCollapsed &&
	            <>  		
					{CreateWarroomFromNavBar &&
						<div className="sidebar-create-room-input">
							<form onSubmit={(e) => {
									dispatch(allActions.roomActions.createRoom({
										rooms: rooms, 
										e: e, 
										submitRoomField: submitRoomField,
										sharedRooms: sharedRooms, // to send to extension
									}))
									setSubmitRoomField("")

									setCreateWarroomFromNavBar(!CreateWarroomFromNavBar);
								}}>
								<input 
									type="text" 
									placeholder="Room Title" 
									maxLength = "40"
									id="navbar-roomtitle" 
									value={submitRoomField}
									onChange={(e) => setSubmitRoomField(e.target.value)} 
									style={{ marginRight: "5px"}}
								/>
								<PlusButton />
							</form>	
						</div>
					}

					{rooms.length > 0 &&
						<div
 							{...draggedPost && !draggedPost.owner && {style: {  // dont show if dragging from sharedRoom
								backgroundColor: "rgba(250,128,114,0.3)",
								fontWeight: "bold"
 							}}}
						>
							{warroomsOnMenu}
						</div>
					}							
							
	            </>
            }              
        </div>
	)
}

export default RoomsAtSideBar;

	// for DROP to SideBar
	/*
	DONE:
	add onDragOver and onDrop to receiving room in sidemenu
	update Redux
	ondragenter or over UI on side menu room
	when there is a dragged post, make rooms more obvious in UI	
	maybe try clearing dragged post for normal dragging
	handle drop to desk
	!! hack for bug when dragging short posts:   min-height: 300px to post-dragging, 
	good masonry dnd reference: pinterest, G keep, and https://thisisvini.com/responsive-mosaic-with-dnd-reactjs
	handle drop to shared room
	moving to shared room, will lose notes!!	
	don't show drag to menu when in shared room
	handle drop to WarroomSectionNew
	handle drop when sidemenu collapsed	
	refactor with custom hook: useDropForDnD
	update room count firebase + re-order
	bug when dragging over pinned post: fixed with adding for setIsPostReceiving(false) in onDragLeave at PostDnDWrapper 

	PENDING:
	challenge (ask CH): how to let user know they can drag? maybe when hover over a post, show a handle that allow dragging?
	need to re-order room position ?? 
	*/