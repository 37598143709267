import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import allActions from "actions";
import {
  Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import "../navbar.css";


const UserMenuDropdown = ({ user, toggle  }) => {

	const dispatch = useDispatch();
	const colorTheme = useSelector(state => state.userReducer.colorTheme);

	const profileIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/profile${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='profile' />; 
	const guideIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/guide${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='guide' />;
	const helpIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/help${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='help' />;
	const contactIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/contact${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='contact' />;
	const logoutIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/logout${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='logout' />;
	const extensionIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/navbar/extension${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='extension' />;
	const archiveIcon = <img className="dropdown-menu-icon" src={`${process.env.PUBLIC_URL}/images/icons/archive${(colorTheme === "dark") ? "-dark" : ""}.svg`} alt='archive' />;


	return (
      	<Nav navbar>
            <UncontrolledDropdown nav inNavbar>
	              <DropdownToggle nav>
	               	 	<img src={`${process.env.PUBLIC_URL}/images/navbar/user-icon.svg`} alt='user-icon' />
	              </DropdownToggle>

	              <DropdownMenu className="navbar-dropdown-menu" right>
	              	{user.displayName
	              			?
			                <DropdownItem header style={{ font: "inherit" }} className="navbar-dropdown-item">
			                	<span style={{ fontSize:"14px", marginRight: "5px"}}>👋</span> {user.displayName}
			                </DropdownItem>	
			                :
			                <DropdownItem header style={{ font: "inherit" }} className="navbar-dropdown-item">
			                	Welcome to Osoji
			                </DropdownItem>								                				              			
	              	}

		              <DropdownItem divider />
		                
		              <Link className="dont-link-me" to="/profile" onClick={toggle}>
			                <DropdownItem className="navbar-dropdown-item">
												{profileIcon} Profile    	                  
			                </DropdownItem>
			            </Link> 			

		              <Link className="dont-link-me" to="/archive" onClick={toggle}>
			                <DropdownItem className="navbar-dropdown-item">
												{archiveIcon} Archive    	                  
			                </DropdownItem>
			            </Link> 			
			            	            
			            <a className="dont-link-me" href="https://www.notion.so/Osoji-User-Guide-c107875c42de4e50aacc9058536cf71a" onClick={toggle} target="_blank" rel="noreferrer">      
			                <DropdownItem className="navbar-dropdown-item">
												{guideIcon} Guide    	                  
			                </DropdownItem>	
			            </a> 

			            <Link className="dont-link-me" to="/extensions" onClick={toggle}>      
			                <DropdownItem className="navbar-dropdown-item">
												{extensionIcon} Extensions   	                  
			                </DropdownItem>	
			            </Link> 		

			            <Link className="dont-link-me" to="/faq" onClick={toggle}>      
			                <DropdownItem className="navbar-dropdown-item">
												{helpIcon} FAQ    	                  
			                </DropdownItem>	
			            </Link> 	
	            
			            <Link className="dont-link-me" to="/contact" onClick={toggle}>      
			                <DropdownItem className="navbar-dropdown-item">
												{contactIcon} Contact / Help   	                  
			                </DropdownItem>	
			            </Link> 	

			            <div onClick={() => {
			            	toggle()
			            	dispatch(allActions.userActions.userLogout())
			            }}>      					                
			                <DropdownItem className="navbar-dropdown-item">
				          			{logoutIcon} Logout     	                  
			                </DropdownItem>
			            </div>     
	              </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>
	)
}

export default UserMenuDropdown;