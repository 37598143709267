import ReactTypingEffect from 'react-typing-effect';
import './usecases.css';

const UseCases = () => {
	
	const useCasesList = [
		{
			title: "Personal Projects",
			items: [
				{
					title: "Grow Side Project 10x",
					link: `${process.env.PUBLIC_URL}/public/grow-my-side-project-10x-LXuw870e6IQUhitwxse2`,
					image: `${process.env.PUBLIC_URL}/images/landing/rocket.png`,
					alt: "side-project"
				},
				{
					title: "Learn Investing and Grow Portfolio",
					link: `${process.env.PUBLIC_URL}/public/learn-investing-and-grow-portfolio-7naDnw0DTgRaAqobmjgJ`,
					image: `${process.env.PUBLIC_URL}/images/landing/stock.png`,
					alt: "invest"					
				},
				{
					title: "Hustle and Build Side Income",
					link: `${process.env.PUBLIC_URL}/public/hustle-and-build-wealth-fuZoq3836OWzA0eT1JgW`,
					image: `${process.env.PUBLIC_URL}/images/landing/hustle.png`,
					alt: "side-income"					
				},
			]
		},
		{
			title: "Daily Resources",
			items: [
				{
					title: "Buying a House",
					link: `${process.env.PUBLIC_URL}/public/buying-a-house-7vc7UOHxDbUKuMp7eqY1`,
					image: `${process.env.PUBLIC_URL}/images/landing/house.png`,
					alt: "buy-house"
				},
				{
					title: "Parenting Tips",
					link: `${process.env.PUBLIC_URL}/public/parenting-gzoqK89cfndGJyOZLFr2`,
					image: `${process.env.PUBLIC_URL}/images/landing/parenting.png`,
					alt: "parenting-tip"					
				},
				{
					title: "Cooking Recipes",
					link: `${process.env.PUBLIC_URL}/public/recipes-TzpnIM8NFI0QjZgNg7mB`,
					image: `${process.env.PUBLIC_URL}/images/landing/cooking.png`,
					alt: "recipes"					
				},
			]
		},	
		{
			title: "Professional Growth",
			items: [
				{
					title: "Transition to Product Management",
					link: `${process.env.PUBLIC_URL}/public/transition-to-product-management-IoLAOMeFyLyEH4RBLN2a`,
					image: `${process.env.PUBLIC_URL}/images/landing/product-management.png`,
					alt: "product-management"
				},
				{
					title: "Pick up Data Science and ML",
					link: `${process.env.PUBLIC_URL}/public/data-science-and-ml-mUTPUW033nHFtGKtb5eA`,
					image: `${process.env.PUBLIC_URL}/images/landing/solution-architect.png`,
					alt: "data-science"					
				},
				{
					title: "Startup Learnings",
					link: `${process.env.PUBLIC_URL}/public/startup-learnings-MXINsMOfbBDjXMkELzcO`,
					image: `${process.env.PUBLIC_URL}/images/landing/startup.png`,
					alt: "startup-learnings"					
				},
			]
		},					
	]


	return (
		<section className="usecase-container">
			<h2 className="landing-section-header">How People Use Osoji</h2>

			<ReactTypingEffect
				className="landing-typing"
				text={["Learn How to Invest", "Pick up Programming", "Parenting Tips", "Buying a House"]}
				speed="100"
				eraseSpeed="40"
				eraseDelay="1000"
				typingDelay="100"
			/>		

			<br />

			<div className="usecase-list-group">
				{useCasesList.map((list,i) => (
					<div className="usecase-list" key={i}>
						<h5>{list.title}</h5>
						<div className="usecase-card-group">
							{list.items.map((card,j) => (
								<a href={card.link} className="dont-link-me" key={j}>
									<div className="usecase-card">
										<img 
											loading="lazy" 
											style={{ width: "50px"}} 
											className="usecase-card-image" 
											src={card.image} 
											alt={card.alt} 
										/>
										<p>{card.title}</p>
									</div>
								</a>									
							))}
						</div>
					</div>
				))}	
			</div>

		</section>
	)
}

export default UseCases;