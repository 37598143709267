export const postRoute = ({ post, isShared, isPrivate, owner, currentRoom }) => {
	if (isShared) return `/post/shared-${owner}---${currentRoom}-${post.docId}`		// shared
	else if (isPrivate) return `/post/${post.docId}` 								// normal
	else return `/publicpost/${post.owner}-${post.docId}`	// publich, note that owner and post.owner is different. Latter is only for publich post
}

// used in WarroomSectionNew, Navbar
export const warroomUrl = (room) => {
	// turn title into all small and replace all space and non-alphanumeric characters into - 
	const newRoomTitle = room.title.toLowerCase().replace(/\W+/g, "-");

	return `/room/${newRoomTitle}-${room.roomId}`
	//  /warroom/osoji-marketing-929732937293
}

// used in Navbar
export const sharedRoomUrl = (room) => {
	// turn title into all small and replace all space and non-alphanumeric characters into - 
	const newRoomTitle = room.roomTitle.toLowerCase().replace(/\W+/g, "-");

	return `/room/shared-${newRoomTitle}-${room.sharedRoomId}` 
}

// used in TwitterPost
export const tweetId = (url) => {
	// turn https://twitter.com/austin_rief/status/1343661593696022530?s=21 into 1343661593696022530
	// also handle https://twitter.com/dougpolkvids/status/1351358307269689345%3Fs%3D25
	const indexOfStatus = url.indexOf("status/");
	const indexOfParam = url.indexOf("?");
	const indexOfPercentage = url.indexOf("%");	// this happens when share in twitter app instead of native

	if (indexOfPercentage > -1) return url.slice(indexOfStatus + 7, indexOfPercentage)
	else if (indexOfParam === -1) return url.slice(indexOfStatus + 7, url.length)
	else return url.slice(indexOfStatus + 7, indexOfParam)
}

// use in WarroomDetailsPage, PublicWarroomDetailsPage, PostDetailsPage
export const transformedId = (id) => {	
	//  turn osoji-marketing-929732937293 into just id
	// shared-${newRoomTitle}-${room.sharedRoomId}`   works for Shared room too (but is sharedRoomID, not originalRoomId)
	const lastIndexOfDash = id.lastIndexOf("-")
	return id.slice(lastIndexOfDash + 1, id.length)
}

// use in WarroomDetailsPage
export const publicWarroomUrl = (roomDetails) => {			
	// turn id into url that includes title
	// turn title into all small and replace all space and non-alphanumeric characters into - 
	const newRoomTitle = roomDetails.title.toLowerCase().replace(/\W+/g, "-");

	return `/public/${newRoomTitle}-${roomDetails.publicId}`
	//  /publicwarroom/osoji-marketing-929732937293
}	

// used in PostDetailsPage
export const ownerId = (id, isShared) => {		// for Posts in Shared Room only
	// `/post/shared-${owner}---${currentRoom}-${post.docId}`			OR  ${post.docId}
	if (!id || !isShared) return null	
	
	const firstIndexOfDash = id.indexOf("-")
	const lastIndexOfDash = id.lastIndexOf("---")  

	return id.slice(firstIndexOfDash + 1, lastIndexOfDash);					
}
// used in PostDetailsPage
export const sharedRoomId = (id, isShared) => {		// for Posts in Shared Room only
	// `/post/shared-${owner}---${currentRoom}-${post.docId}`			OR  ${post.docId}
	if (!id || !isShared) return null	

	const firstIndexOfDash = id.indexOf("---")
	const lastIndexOfDash = id.lastIndexOf("-")  // does this work???? change later
	
	return id.slice(firstIndexOfDash + 3, lastIndexOfDash);					
}

// used in PublicPostDetailsPage
//id is in ${post.owner}-${post.docId}`
export const postId = (id) => {
	//  turn `${post.owner}-${post.docId}` into just id
	const lastIndexOfDash = id.lastIndexOf("-")
	return id.slice(lastIndexOfDash + 1, id.length)
}	
export const postOwner = (id) => {
	//  turn `${post.owner}-${post.docId}` into just owner
	const lastIndexOfDash = id.lastIndexOf("-")
	return id.slice(0, lastIndexOfDash)
}		


