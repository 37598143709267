import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "actions";
import { Link } from 'react-router-dom';
import PlusButton from "components/ButtonsAndIcons/PlusButton/PlusButton";
import {
	UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { 
	warroomUrl, sharedRoomUrl 
} from "utils/transformIdAndUrl";
import "../navbar.css";


const RoomsMenuDropdown = ({ toggle }) => {

	const dispatch = useDispatch();
	const rooms = useSelector(state => state.contentReducer.rooms);
	const sharedRooms = useSelector(state => state.contentReducer.sharedRooms);

	const [CreateWarroomFromNavBar, setCreateWarroomFromNavBar] = useState(false);
	const [submitRoomField, setSubmitRoomField] = useState("");

	const colorTheme = useSelector(state => state.userReducer.colorTheme);
	const plusIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/navbar/plus.svg` : `${process.env.PUBLIC_URL}/images/navbar/plus-dark.svg`;
	const minusIcon = (colorTheme === "light") ? `${process.env.PUBLIC_URL}/images/navbar/minus.svg` : `${process.env.PUBLIC_URL}/images/navbar/minus-dark.svg`;

 	// showing pinned rooms first
 	const roomsWithPin = rooms.filter(room => room.pin === true)
	const roomsWithoutPin = rooms.filter(room => room.pin !== true)
	const combinedRooms = [...roomsWithPin, ...roomsWithoutPin];	

	const warroomsOnMenu = combinedRooms.filter(each => each.roomId !== "braindump").map((room) => (
		<Link className="dont-link-me" to={warroomUrl(room)} onClick={toggle} key={room.roomId}>
			<DropdownItem className="navbar-dropdown-item">{room.title}</DropdownItem>	
		</Link>          
	))
	const sharedRoomsOnMenu = sharedRooms.map((room) => (
		<Link className="dont-link-me" to={sharedRoomUrl(room)} onClick={toggle} key={room.sharedRoomId}>
			<DropdownItem className="navbar-dropdown-item">{room.roomTitle}</DropdownItem>	
		</Link>          
	))	 

	return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret className="dont-link-me nav-rooms-menu-header" >
                Rooms
            </DropdownToggle>					              
            <DropdownMenu className="navbar-dropdown-menu">  		
                <div className="menu-create-room" onClick={() => setCreateWarroomFromNavBar(!CreateWarroomFromNavBar)}>
                	<span>
						<img 
							style={{ height: "20px", marginBottom: "2px", marginLeft: "0px", marginRight: "5px"}} 
							src={CreateWarroomFromNavBar ? minusIcon : plusIcon} 
							alt={CreateWarroomFromNavBar ? 'collapse' : 'expand'} 
						/>
                	</span>
                	Create Room 
                </div>											

				{CreateWarroomFromNavBar &&
					<div className="menu-create-room-input">
						<form onSubmit={(e) => {
								dispatch(allActions.roomActions.createRoom({
									rooms: rooms, 
									e: e, 
									submitRoomField: submitRoomField,
									sharedRooms: sharedRooms, // to send to extension
								}))
								setSubmitRoomField("")
							}}>
							<input 
								type="text" 
								placeholder="Room Title" 
								maxLength = "40"
								id="navbar-roomtitle" 
								value={submitRoomField}
								onChange={(e) => setSubmitRoomField(e.target.value)} 
							/>
							<PlusButton />
						</form>	
					</div>
				}

				{rooms.length > 0 &&
					<>
		                <DropdownItem divider className="warroom-menu-divider" />
						{warroomsOnMenu}
					</>
				}
				{sharedRooms.length > 0 &&
					<>
						<DropdownItem divider className="warroom-menu-divider" />

						{/*<p className="menu-shared-by-others">Shared With Me</p>*/}
						<DropdownItem header>Shared With Me</DropdownItem>

						{sharedRoomsOnMenu}
					</>	
				}										
            </DropdownMenu>
        </UncontrolledDropdown>
	)
}

export default RoomsMenuDropdown;